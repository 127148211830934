import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getAllVerifyResponse} from "../store/logIn";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {deleteWorker, getWorkers, messageReset} from "../store/workers";
import Button from "@mui/material/Button";
import {format} from "date-fns";
import Header from "../blocks/header";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import Box from "@mui/material/Box";
import Dashboard from "../blocks/dashboard";
import Copyright from "../blocks/copyright";
import {toast} from "react-toastify";
import ReactPaginate from "react-paginate";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {useTranslation} from "react-i18next";
import DeleteWorker from "../blocks/deleteWorker";
import axios from "axios";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


function Workers(props) {

    const is_main = JSON.parse(localStorage.getItem('is_main'));
    const navigate = useNavigate()
    const params = useParams()
    const [regionId, setRegionId] = useState(false)
    useEffect(()=>{
        setRegionId(params.id)
    }, [params.id])
    const toBack = () => {
        navigate(-1);
    }
    const {t, i18n} = useTranslation()
    const [workers, setWorkers] = useState([])
    const [open, setOpen] = useState(false)
    const [openItem, setOpenItem] = useState(false)

    useEffect(() => {
        props.getWorkers()
    }, [])

    useEffect(() => {
        setWorkers(props.workers)
    }, [props.workers])

    function toAdd() {
        navigate('/add-worker/')
    }

    function toEdit(data) {
        navigate('/edit-worker/' + data.id)
    }

    function toDelete(item){
        setOpenItem(item)
        setOpen(true)
    }
    function saveFile() {
        if (regionId) {
            axios({
                url: 'https://ijtimoiybolim.uzkoj.uz/api/get-worker-excel/'+regionId, //your url
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${Date.now()}.xlsx`,); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            });
        }else{
            axios({
                url: 'https://ijtimoiybolim.uzkoj.uz/api/get-worker-excel/'+'all', //your url
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${Date.now()}.xlsx`,); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            });
        }
    }

    // Pagination

    const [itemsPerPage, setItemsPerPage] = useState(10)

    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = workers.filter(item=>regionId ? item.user.id == regionId : item).slice(itemOffset, endOffset);
    const pageCount = Math.ceil(workers.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % workers.length;
        setItemOffset(newOffset);
    };

    if(pageCount !== 0 && currentItems.length === 0 && workers.filter(item=>regionId ? item.user.id == regionId : item).length !== 0){
        let li_lenght = document.querySelectorAll('.pagination ul li').length
        document.querySelectorAll('.pagination ul li')[li_lenght-2].previousSibling.className = 'selected'
        const data = {"selected": pageCount-1}
        handlePageClick(data)
    }

    useEffect(()=>{
        if(props.message === 'deleted'){
            toast.success(t('delete_success'), {theme:'colored'})
            setOpenItem(false)
            props.messageReset()
        }else if (props.message === 'error') {
            toast.error(t('save_error'), {theme: 'colored'})
            props.messageReset()
        }else if (props.message === true) {
            toast.success(t('save_success'), {theme: 'colored'})
            props.messageReset()
        }
    }, [props.message])


    return <div>
        <div className={'page'}>
            {open ? <DeleteWorker setOpenItem={setOpenItem} openItem={openItem} open={open} setOpen={setOpen}/> : ''}
            <Header/>
            <Dashboard/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}>
                <Toolbar/>
                <div className={'main'}>
                        <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={toBack}>
                            {t('back')}
                        </Button>
                    <div className={'worker-page'}>
                        <div className={'header-page'}>
                            <h2 className={'page-title'}>{t('list_employees')}</h2>
                            {!is_main ?
                                <Button variant="contained" title={t('add')} endIcon={<AddIcon/>} onClick={toAdd}>
                                    {t('add')}
                                </Button>
                                : ''}
                        </div>
                        {workers.length ? <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                {is_main ? <th scope="col">{t('title_company')}</th> : ''}
                                <th scope="col">{t('fullname')}</th>
                                <th scope="col">{t('address')}</th>
                                <th scope="col">{t('birth_date')}</th>
                                <th scope="col">{t('pass_series_number')}</th>
                                <th scope="col">{t('group')}</th>
                                <th scope="col">{t('phone_number')}</th>
                                {!is_main ? <th scope="col"></th> : ''}
                            </tr>
                            </thead>
                            <tbody>
                            {currentItems.length ? currentItems.map((item, index) => <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                {is_main ?<td>{item.user.user_name ? item.user.user_name : 'ADMIN'}</td> : ''}
                                    <td>{item.fullname}</td>
                                    <td>{item.address}</td>
                                    <td>{format(new Date(item.birth_date), 'dd/MM/yyyy')}</td>
                                    <td>{item.passport_series} {item.passport_number}</td>
                                    <td>{item.group===4 ? t('no_group') : item.group}</td>
                                    <td>{item.phone_number}</td>
                                    {!is_main ?
                                        <td>
                                            <Button variant="contained" title={"O'zgartirish"} color={"info"} endIcon={<EditIcon/>}
                                                    onClick={() => toEdit(item)}/>
                                            <Button variant="contained" title={"O'chirish"} color={"error"} endIcon={<DeleteIcon/>}
                                                    onClick={()=>{toDelete(item)}}
                                            />
                                        </td>
                                        : ''}
                                </tr>)
                                :
                                ''
                            }
                            </tbody>
                        </table> : ''}
                        {workers.length && is_main ? <Button onClick={saveFile} variant="contained" title={t('download')} color={"primary"}>
                            {t('download')}
                        </Button> : '' }
                        {pageCount > 1 ? <div className={'pagination'}>
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel={<KeyboardArrowRightIcon/>}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel={<KeyboardArrowLeftIcon/>}
                                renderOnZeroPageCount={null}
                            />
                        </div> : ''}
                    </div>
                </div>
                <Copyright/>
            </Box>
        </div>
    </div>
}

export default connect(props => props.worker, {getWorkers, deleteWorker, getAllVerifyResponse, messageReset})(Workers)