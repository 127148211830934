import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'members',
    initialState: {members: [], message: false, member_single: false},
    reducers:{
        getAllMember: (state,action)=>{
            state.members = action.payload
        },
        getOneMember: (state,action)=>{
            state.member_single = action.payload
        },
        saveMember: (state,action)=>{
            state.members.unshift(action.payload)
            state.message = true
        },
        editMember: (state,action)=>{
            state.member_single = action.payload
            state.members = state.members.map(item=>item.id === action.payload.id ? action.payload : item)
            state.message = true
        },
        deleteToMember: (state,action)=>{
            state.members = state.members.filter(item=>item.id !== action.payload.id)
            state.message = 'deleted'
        },
        messageReset: (state,action)=>{
            state.message = false
        },
        messageFail: (state,action)=>{
            state.message = 'error'
        }
    }
})

export const getMembers = () => apiCall({
    url: 'members/',
    method: 'GET',
    onSuccess: slice.actions.getAllMember.type
})

export const memberSave = (data) => apiCall({
    url: 'members/',
    method: 'POST',
    onSuccess: slice.actions.saveMember.type,
    onFail: slice.actions.messageFail.type,
    data,
})

export const getMember = (id) => apiCall({
    url: 'members/'+id,
    method: 'GET',
    onSuccess: slice.actions.getOneMember.type,
})

export const editSaveMember = (data) => apiCall({
    url: 'members/'+data.id+'/',
    method: 'PUT',
    onSuccess: slice.actions.editMember.type,
    onFail: slice.actions.messageFail.type,
    data,
})

export const deleteMember = (data) => apiCall({
    url: 'members/'+data.id+'/',
    method: 'DELETE',
    onSuccess: slice.actions.deleteToMember.type,
    onFail: slice.actions.messageFail.type,
})

export default slice.reducer
export const {messageReset, getAllMember} = slice.actions
