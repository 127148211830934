import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import Header from "../blocks/header";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {connect} from "react-redux";
import {messageReset, memberSave} from "../store/members";
import {getAllVerifyResponse} from "../store/logIn";
import Dashboard from "../blocks/dashboard";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import Copyright from "../blocks/copyright";
import {useTranslation} from "react-i18next";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {Link, useNavigate} from "react-router-dom";

function AddIcon() {
    return null;
}

function AddMember(props) {

    const {t, i18n} = useTranslation()
    const [hasError, setHasError] = useState('');
    const [fullname, setFullname] = useState('');
    const [address, setAddress] = useState('');
    const [family_position, setFamilyPosition] = useState('');
    const [passportSeries, setPassportSeries] = useState('');
    const [passportNumber, setPassportNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [group, setGroup] = useState('');
    const [date, setDate] = useState(new Date("2000-01-01"));

    const navigate = useNavigate()
    const toBack = () => {
        navigate(-1);
    }
    function changeDate(newValue) {
        setDate(newValue)
    }

    function saveFormData(e){
        e.preventDefault()
        if(!group){
            setHasError(true)
        }else{
            setHasError(false)
        }
        const inpDate = e.target[2].value
        const [day, month, year] = inpDate.split('/');

        const result = [year, month, day].join('-');
        const data = {
            "fullname": fullname,
            "birth_date": result,
            "group": parseInt(group),
            "address": address,
            "family_position": family_position,
            "passport_series": passportSeries.toUpperCase(),
            "passport_number": passportNumber,
            "phone_number": phoneNumber,
        }
        props.memberSave(data)

    }

    useEffect(()=>{
        if(props.message===true){
            toast.success(t('save_success'), {theme:'colored'})
            setPhoneNumber('')
            setFullname('')
            setAddress('')
            setFamilyPosition('')
            setPassportNumber('')
            setPassportSeries('')
            setGroup('')
            setDate(new Date("2000-01-01"))
            props.messageReset()
        }else if(props.message === 'error'){
            toast.error(t('save_error'), {theme:'colored'})
            props.messageReset()
        }
    }, [props.message])

    return <div>
        <div className={'page'}>
            <Header/>
            <Dashboard/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}>
                <Toolbar/>
                <div className={'main'}>
                        <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={toBack}>
                            {t('back')}
                        </Button>
                    <h2 className={'page-title text-center'}>{t('add')}</h2>
                    <form className={'add-form'} onSubmit={saveFormData}>
                        <div>
                            <p><span>{t('fullname')}</span><TextField value={fullname} onChange={(e)=>setFullname(e.target.value)} id="outlined-basic" label={t('fullname')} variant="outlined" required/></p>
                            <p><span>{t('birth_date')}:</span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        minDate={new Date("1940-01-01")}
                                        maxDate={new Date("2020-01-01")}
                                        label={t('birth_date')}
                                        inputFormat="DD/MM/YYYY"
                                        value={date}
                                        onChange={changeDate}
                                        renderInput={(params) => <TextField required {...params} />}
                                    />
                                </LocalizationProvider>
                            </p>
                        </div>
                        <div>
                            <p><span>{t('group')}:</span>
                                <Box sx={{ minWidth: 200 }}>
                                    <FormControl fullWidth>
                                        <InputLabel error={hasError} required id="demo-simple-select-label">{t('group')}</InputLabel>
                                        <Select
                                            error={hasError}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={group}
                                            label={t('group')}
                                            onChange={(e) => {
                                                setGroup(e.target.value)
                                            }}
                                        >
                                            <MenuItem value={4}>{t('no_group')}</MenuItem>
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={2}>2</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box></p>
                            <p><span>{t('address')}:</span><TextField value={address} onChange={(e)=>setAddress(e.target.value)} required id="outlined-basic" label={t('address')} variant="outlined"/></p>
                        </div>
                        <div>
                            <p><span>{t('family_position')}:</span><TextField value={family_position} onChange={(e)=>setFamilyPosition(e.target.value)} required id="outlined-basic" label={t('family_position')} variant="outlined"/></p>
                            <p><span>{t('pass_series')}:</span><TextField value={passportSeries} onChange={(e)=>setPassportSeries(e.target.value)} required id="outlined-basic" label="AA" variant="outlined" inputProps={{ maxLength: 2, style:{textTransform: 'uppercase'} }}/></p>
                        </div>
                        <div>
                            <p><span>{t('pass_number')}:</span><TextField value={passportNumber} onChange={(e)=>setPassportNumber(e.target.value)} required id="outlined-basic" label="7654321" variant="outlined" inputProps={{ maxLength: 7 }}/></p>
                            <p>
                                <span>{t('phone_number')}:</span>
                                <TextField value={phoneNumber} onChange={(e)=>setPhoneNumber(e.target.value)} required id="outlined-basic" label="+998909009090" variant="outlined" inputProps={{ maxLength: 13 }}/>
                            </p>
                        </div>
                        <Button type={'submit'} variant="contained" endIcon={<AddIcon />} fullWidth style={{height: '45px'}}>
                            {t('save')}
                        </Button>
                    </form>
                </div>
                <Copyright/>
            </Box>
        </div>
    </div>
}

export default connect(props => props.members, {memberSave, getAllVerifyResponse, messageReset})(AddMember)