import {useEffect, useState} from "react";
import Dialog from '@mui/material/Dialog';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import * as React from "react";

function CancelModalStat(props) {
    const {t, i18n} = useTranslation()

    const [open, setOpen] = useState(false);
    const [comment, setComment] = useState('');

    const handleClose = () => {
        setOpen(false);
        props.setOpen(false)
    };

    function toCanceled(e) {
        e.preventDefault()
        let new_data = props.data
        new_data.comment = comment
        props.editSaveStats(new_data)
        props.setOpen(false)
    }

    useEffect(() => {
        if (props.open) {
            setOpen(props.open)
        }
    }, [props])

    return <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div className={'delete-modal'}>
                <div className="icon">
                    <HighlightOffIcon color={'error'}/>
                </div>
                <h2>{t('to_canceled_comment_title')}</h2>
                <form onSubmit={toCanceled}>
                    <TextField
                        label={t('to_canceled_comment')}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        required
                        type={"text"}
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                    />
                    <div className="buttons">
                        <Button variant="contained" className={'cancel-btn'} onClick={handleClose}>
                            {t('cancel')}
                        </Button>
                        <Button variant="contained" className={'delete-btn'} type={'submit'}>
                            {t('to_canceled')}
                        </Button>
                    </div>
                </form>
            </div>
        </Dialog>
    </div>
}

export default CancelModalStat