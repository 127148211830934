import {connect} from "react-redux";
import {editSaveMember, getMember, messageReset, memberSave} from "../store/members";
import {getAllVerifyResponse} from "../store/logIn";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import TextField from "@mui/material/TextField";
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {toast} from "react-toastify";
import Header from "../blocks/header";
import Dashboard from "../blocks/dashboard";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import Copyright from "../blocks/copyright";
import {useTranslation} from "react-i18next";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


function EditMember(props) {

    const [hasError, setHasError] = useState('');
    const [fullname, setFullname] = useState('');
    const [address, setAddress] = useState('');
    const [family_position, setFamilyPosition] = useState('');
    const [passportSeries, setPassportSeries] = useState('');
    const [passportNumber, setPassportNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [group, setGroup] = useState('');
    const [date, setDate] = useState(new Date("2000-01-01"));
    const navigate = useNavigate()
    const params = useParams();
    const {t, i18n} = useTranslation();
    const toBack = () => {
        navigate(-1);
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token !== 'undefined') {
            const data = {
                "token": JSON.parse(token)
            }
            props.getAllVerifyResponse(data)
        }
        props.getMember(params.id)
    }, [])

    useEffect(() => {
        if (props.verify === false) {
            navigate("/login/");
        }
        if (props.member_single) {
            setPhoneNumber(props.member_single.phone_number)
            setFullname(props.member_single.fullname)
            setAddress(props.member_single.address)
            setFamilyPosition(props.member_single.family_position)
            setPassportNumber(props.member_single.passport_number)
            setPassportSeries(props.member_single.passport_series)
            setGroup(props.member_single.group)
            setDate(new Date(props.member_single.birth_date))
        }
    }, [props])

    function changeDate(newValue) {
        setDate(newValue)
    }

    function saveFormData(e) {
        e.preventDefault()
        if (!group) {
            setHasError(true)
        } else {
            setHasError(false)
        }
        const inpDate = e.target[2].value
        const [day, month, year] = inpDate.split('/');

        const result = [year, month, day].join('-');
        const data = {
            "id": params.id,
            "fullname": fullname,
            "family_position": family_position,
            "birth_date": result,
            "group": parseInt(group),
            "address": address,
            "passport_series": passportSeries.toUpperCase(),
            "passport_number": passportNumber,
            "phone_number": phoneNumber,
        }
        props.editSaveMember(data)
    }

    useEffect(() => {
        if (props.message === true) {
            navigate('/members/')
        } else if (props.message === 'error') {
            toast.error(t('save_error'), {theme: 'colored'})
            props.messageReset()
        }
    }, [props.message])


    return <div>
        <div className={'page'}>
            <Header/>
            <Dashboard/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}>
                <Toolbar/>
                <div className={'main'}>
                        <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={toBack}>
                            {t('back')}
                        </Button>
                    <h2 className={'page-title text-center'}>{t('change_info')}</h2>
                    <form className={'add-form'} onSubmit={saveFormData}>
                        <div>
                            <p><span>{t('fullname')}:</span><TextField value={fullname}
                                                                       onChange={(e) => setFullname(e.target.value)}
                                                                       id="outlined-basic" label={t('fullname')} variant="outlined"
                                                                       required/></p>
                            <p><span>{t('birth_date')}:</span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        minDate={new Date("1940-01-01")}
                                        maxDate={new Date("2020-01-01")}
                                        label={t('birth_date')}
                                        inputFormat="DD/MM/YYYY"
                                        value={date}
                                        onChange={changeDate}
                                        renderInput={(params) => <TextField required {...params} />}
                                    />
                                </LocalizationProvider>
                            </p>
                        </div>
                        <div>
                            <p><span>{t('group')}:</span>
                                <Box sx={{minWidth: 200}}>
                                    <FormControl fullWidth>
                                        <InputLabel error={hasError} required id="demo-simple-select-label">{t('group')}</InputLabel>
                                        <Select
                                            error={hasError}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={group}
                                            label={t('group')}
                                            onChange={(e) => {
                                                setGroup(e.target.value)
                                            }}
                                        >
                                            <MenuItem value={4}>{t('no_group')}</MenuItem>
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={2}>2</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box></p>
                            <p><span>{t('address')}:</span><TextField value={address}
                                                                      onChange={(e) => setAddress(e.target.value)}
                                                                      required id="outlined-basic"
                                                                      label={t('address')} variant="outlined"/></p>
                        </div>
                        <div>
                            <p><span>{t('family_position')}:</span><TextField value={family_position}
                                                                      onChange={(e) => setFamilyPosition(e.target.value)}
                                                                      required id="outlined-basic"
                                                                      label={t('family_position')} variant="outlined"/></p>
                            <p><span>{t('pass_series')}:</span><TextField value={passportSeries}
                                                                          onChange={(e) => setPassportSeries(e.target.value)}
                                                                          required id="outlined-basic" label="AA"
                                                                          variant="outlined" inputProps={{
                                maxLength: 2,
                                style: {textTransform: 'uppercase'}
                            }}/></p>
                        </div>
                        <div>
                            <p><span>{t('pass_number')}:</span><TextField value={passportNumber}
                                                                          onChange={(e) => setPassportNumber(e.target.value)}
                                                                          required id="outlined-basic" label="7654321"
                                                                          variant="outlined" inputProps={{maxLength: 7}}/>
                            </p>
                            <p>
                                <span>{t('phone_number')}:</span>
                                <TextField value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required
                                           id="outlined-basic" label="+998909009090" variant="outlined"
                                           inputProps={{maxLength: 13}}/>
                            </p>
                        </div>
                        <Button type={'submit'} variant="contained" endIcon={<AddIcon/>} fullWidth
                                style={{height: '45px'}}>
                            {t('save')}
                        </Button>
                    </form>
                </div>
                <Copyright/>
            </Box>
        </div>
    </div>
}

export default connect(props => props.members, {
    memberSave,
    getAllVerifyResponse,
    messageReset,
    editSaveMember,
    getMember
})(EditMember)