import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from '@mui/icons-material/Notifications';
import ClearIcon from '@mui/icons-material/Clear';
import * as React from "react";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {editSaveNotifications, getNotifications} from "../store/notifications";
import {connect} from "react-redux";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const drawerWidth = 280;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


function AppBarBlock(props) {
    const [notifications, setNotifications] = useState([])
    const [notificationsActive, setNotificationsActive] = useState(false)
    const [changeSettings, setChangeSettings] = useState(false)
    const [changeColor, setChangeColor] = useState('normal')
    const [fontSize, setFontSize] = useState('font-1')
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()

    useEffect(()=>{
        props.getNotifications()
        const font = window.localStorage.getItem('FONT_SIZE');
        const color = window.localStorage.getItem('SITE_COLOR');
        if(font !== null) {
            setFontSize(JSON.parse(font))
        }
        if(color !== null) {
            setChangeColor(JSON.parse(color))
        }
    }, [])

    useEffect(() => {
        var root = document.getElementsByTagName('html')[0]
        if (changeColor === 'normal') {
            root.classList.remove('invert');
            root.classList.remove('grayscale');
        } else if (changeColor === 'grayscale') {
            root.classList.remove('normal');
            root.classList.remove('invert');
        } else {
            root.classList.remove('grayscale');
            root.classList.remove('normal');
        }
        window.localStorage.setItem('SITE_COLOR', JSON.stringify(changeColor));
        root.classList.add(changeColor)
    }, [changeColor]);


    useEffect(() => {
        var root = document.body
        if (fontSize === 'font-1') {
            root.classList.remove('font-2');
            root.classList.remove('font-3');
        } else if (fontSize === 'font-2') {
            root.classList.remove('font-1');
            root.classList.remove('font-3');
        } else {
            root.classList.remove('font-2');
            root.classList.remove('font-1');
        }

        window.localStorage.setItem('FONT_SIZE', JSON.stringify(fontSize));
        root.classList.add(fontSize)
    }, [fontSize]);

    useEffect(()=>{
        setNotifications(props.notifications)
    }, [props])

    function logOut() {
        localStorage.removeItem("token");
        navigate('/login/')
    }
    function changeLang(lang) {
        i18n.changeLanguage(lang)
    }

    return <AppBar position="absolute" open={props.open}>
        <Toolbar
            sx={{
                pr: '24px', // keep right padding when drawer closed
            }}
        >
            <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={props.toggleDrawer}
                sx={{
                    marginRight: '36px',
                    ...(props.open && {display: 'none'}),
                }}
            >
                <MenuIcon/>
            </IconButton>
            <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{flexGrow: 1}}
            >
                {t('dashboard')}
            </Typography>
            <span className={`${changeSettings ? 'active' : ''} change-settings`}>
                        <span onClick={() => {
                            setChangeSettings(!changeSettings)
                        }}><VisibilityIcon/>{t('special opportunities')}<ExpandMoreIcon/></span>
                        <div className={`${changeSettings ? 'active' : ''}`}>
                        <div className={'text-size'}>
                        <span>{t('text_size')}</span>
                        <ul>
                            <li onClick={() => {
                                setFontSize('font-1')
                            }}>A</li>
                            <li onClick={() => {
                                setFontSize('font-2')
                            }}>A</li>
                            <li onClick={() => {
                                setFontSize('font-3')
                            }}>A</li>
                        </ul>
                    </div>
                        <div className={'bg-color-changer'}>
                        <span>{t('website_color')}</span>
                        <ul>
                            <li onClick={() => {
                                setChangeColor('normal')
                            }}>A</li>
                            <li onClick={() => {
                                setChangeColor('grayscale')
                            }}>A</li>
                            <li onClick={() => {
                                setChangeColor('invert')
                            }}>A</li>
                        </ul>
                    </div>
                        </div>
                    </span>
            <ul className={'lang-menu page'}>
                <li className={`${i18n.language === 'oz' ? 'active' : ''}`}
                    onClick={() => changeLang('oz')}>O'zb
                </li>
                <li className={`${i18n.language === 'uz' ? 'active' : ''}`}
                    onClick={() => changeLang('uz')}>Ўзб
                </li>
                <li className={`${i18n.language === 'ru' ? 'active' : ''}`}
                    onClick={() => changeLang('ru')}>Рус
                </li>
            </ul>
            <IconButton color="inherit" className={'notification-btn'}>
                <Badge badgeContent={notifications.length ? notifications.length : '0'}
                       onClick={() => setNotificationsActive(prevState => !prevState)} color="secondary">
                    <NotificationsIcon/>
                </Badge>
                {notificationsActive ? <div className={'notifications-block'}>
                        {notifications.length ? notifications.map((item, index)=> <p key={index}>
                            {item.success_report ? t('success_report') : item.error_report ? t('error_report')
                                : item.report_time ? t('report_time') : item.report_send_time ? t('report_send_time') : t('report_notime')}
                            <ClearIcon onClick={()=>props.editSaveNotifications(item)}/></p>
                        ) : <p className={'no-info'}>{t('no_info')}</p>}
                </div> : ''}
            </IconButton>
            <IconButton color="inherit" onClick={logOut}>
                <Badge color="secondary" title={t('logout')}>
                    <LogoutIcon/>
                </Badge>
            </IconButton>
        </Toolbar>
    </AppBar>
}

export default connect(props => props.notifications, {
    getNotifications,
    editSaveNotifications,
})(AppBarBlock)