import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupsIcon from '@mui/icons-material/Groups';
import LayersIcon from '@mui/icons-material/Layers';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import EventIcon from '@mui/icons-material/Event';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getAllVerifyResponse, getUserInfo} from "../store/logIn";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";


function MainListItems(props){
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()
    const [is_main, setIsMain] = useState(false)

    useEffect(()=>{
        props.getUserInfo()
    }, [])

    useEffect(()=>{
        setIsMain(props.user.is_main)
    }, [props])

    const [isHome, setIsHome] = useState(false)
    const [documents, setDocuments] = useState(false)
    const [workers, setWorkers] = useState(false)
    const [stats, setStats] = useState(false)
    const [events, setEvents] = useState(false)
    const [members, setMembers] = useState(false)
    const [problems, setProblems] = useState(false)

    function changeValues(val){
        switch (val){
            case 1:
                navigate('/dashboard/')
                break;
            case 2:
                navigate('/documents/')
                break;
            case 3:
                navigate('/workers/')
                break;
            case 4:
                navigate('/stats/')
                break;
            case 5:
                navigate('/events/')
                break;
            case 6:
                navigate('/members/')
                break;
            case 7:
                navigate('/problems/')
                break;
            default:
                return false
        }
    }

    useEffect(()=>{
        if(window.location.pathname === '/dashboard/'){
            setIsHome(true)
            setDocuments(false)
            setWorkers(false)
            setStats(false)
            setEvents(false)
            setProblems(false)
            setMembers(false)
        }else if(window.location.pathname === '/documents/'){
            setDocuments(true)
            setIsHome(false)
            setWorkers(false)
            setStats(false)
            setMembers(false)
            setProblems(false)
            setEvents(false)
        }else if(window.location.pathname === '/workers/'){
            setDocuments(false)
            setIsHome(false)
            setStats(false)
            setEvents(false)
            setMembers(false)
            setProblems(false)
            setWorkers(true)
        }
        else if(window.location.pathname === '/stats/'){
            setDocuments(false)
            setIsHome(false)
            setWorkers(false)
            setEvents(false)
            setMembers(false)
            setProblems(false)
            setStats(true)
        }
        else if(window.location.pathname === '/events/'){
            setDocuments(false)
            setIsHome(false)
            setWorkers(false)
            setStats(false)
            setMembers(false)
            setProblems(false)
            setEvents(true)
        }
        else if(window.location.pathname === '/members/'){
            setDocuments(false)
            setIsHome(false)
            setWorkers(false)
            setStats(false)
            setEvents(false)
            setProblems(false)
            setMembers(true)
        }
        else if(window.location.pathname === '/problems/'){
            setDocuments(false)
            setIsHome(false)
            setWorkers(false)
            setStats(false)
            setEvents(false)
            setMembers(false)
            setProblems(true)
        }
    }, [])

    if(is_main){
        return <div className={'menu'}>
            <ListItemButton className={isHome ? 'active' : ''}  onClick={()=>changeValues(1)} >
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={t('home')}/>
            </ListItemButton>
        </div>
    }

    return <div className={'menu'}>
        <ListItemButton className={isHome ? 'active' : ''}  onClick={()=>changeValues(1)} >
            <ListItemIcon>
                <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={t('home')}/>
        </ListItemButton>
        <ListItemButton className={documents ? 'active' : ''}  onClick={()=>changeValues(2)} >
            <ListItemIcon>
                <LayersIcon />
            </ListItemIcon>
            <ListItemText primary={t('documents')}/>
        </ListItemButton>
        <ListItemButton className={problems ? 'active' : ''}  onClick={()=>changeValues(7)}>
            <ListItemIcon>
                <SyncProblemIcon />
            </ListItemIcon>
            <ListItemText primary={t('problems')}/>
        </ListItemButton>
        <ListItemButton className={workers ? 'active' : ''}  onClick={()=>changeValues(3)}>
            <ListItemIcon>
                <FolderSharedIcon />
            </ListItemIcon>
            <ListItemText primary={t('employees')}/>
        </ListItemButton>
        <ListItemButton className={members ? 'active' : ''}  onClick={()=>changeValues(6)}>
            <ListItemIcon>
                <GroupsIcon />
            </ListItemIcon>
            <ListItemText primary={t('members')}/>
        </ListItemButton>
        <ListItemButton className={stats ? 'active' : ''}  onClick={()=>changeValues(4)}>
            <ListItemIcon>
                <QueryStatsIcon />
            </ListItemIcon>
            <ListItemText primary={t('stats')}/>
        </ListItemButton>
        <ListItemButton className={events ? 'active' : ''}  onClick={()=>changeValues(5)}>
            <ListItemIcon>
                <EventIcon />
            </ListItemIcon>
            <ListItemText primary={t('events')}/>
        </ListItemButton>
    </div>


}

export default connect(props=>props.login, {getAllVerifyResponse, getUserInfo})(MainListItems)
