import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import Header from "../blocks/header";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {getAllVerifyResponse} from "../store/logIn";
import Dashboard from "../blocks/dashboard";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import Copyright from "../blocks/copyright";
import {useTranslation} from "react-i18next";
import {eventSave, messageReset} from "../store/events";
import {DatePicker} from "@mui/x-date-pickers";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function AddEvents(props) {

    const {t, i18n} = useTranslation()
    const [isSend, setIsSend] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [hasError1, setHasError1] = useState(false);
    const [report, setReport] = useState('');
    const [year, setYear] = useState(new Date("2022-01-01"));
    const [period, setPeriod] = useState('');
    const [ktut, setKtut] = useState('');
    const [mhobt, setMhobt] = useState('');
    const [xxtut, setXxtut] = useState('');
    const [address, setAddress] = useState('');


    const [f_theme_nights, setThemeNights] = useState(['', '', '']);
    const [l_theme_nights, setlThemeNights] = useState(['', '', '']);
    const [f_interviews, setInterviews] = useState(['', '', '']);
    const [l_interviews, setLInterviews] = useState(['', '', '']);
    const [f_meetings, setMeetings] = useState(['', '', '']);
    const [l_meetings, setLMeetings] = useState(['', '', '']);
    const [f_selections, setSelections] = useState(['', '', '']);
    const [l_selections, setLSelections] = useState(['', '', '']);
    const [f_trips, setTrips] = useState(['', '', '']);
    const [l_trips, setLTrips] = useState(['', '', '']);
    const [f_concerts, setConcerts] = useState(['', '', '']);
    const [l_concerts, setLConcerts] = useState(['', '', '']);
    const [f_other_concerts, setOtherConcerts] = useState(['', '', '']);
    const [l_other_concerts, setLOtherConcerts] = useState(['', '', '']);
    const [f_festive_events, setFestiveEvents] = useState(['', '', '']);
    const [l_festive_events, setLFestiveEvents] = useState(['', '', '']);
    const [f_competitions, setCompetitions] = useState(['', '', '']);
    const [l_competitions, setLCompetitions] = useState(['', '', '']);

    const [f_lectures, setFLectures] = useState(['', '', '']);
    const [l_lectures, setLLectures] = useState(['', '', '']);
    const [f_literary_evenings, setFLiteraryEvenings] = useState(['', '', '']);
    const [l_literary_evenings, setLLiteraryEvenings] = useState(['', '', '']);
    const [f_reading_conference, setFReadingConference] = useState(['', '', '']);
    const [l_reading_conference, setLReadingConference] = useState(['', '', '']);

    const [rec_centers, setRecCenters] = useState('');
    const [special_libs, setSpecialLibs] = useState('');
    const [libs_users, setLibsUsers] = useState('');
    const [all_circles, setAllCircles] = useState('');
    const [circles_parts, setCirclesParts] = useState('');
    const [art_circles, setArtCircles] = useState('');
    const [art_parts, setArtParts] = useState('');
    const [sport_circles, setSportCircles] = useState('');
    const [sport_parts, setSportParts] = useState('');
    const [other_circles, setotherCircles] = useState('');
    const [other_parts, setOtherParts] = useState('');
    const [uzkoj_plenum, setUzkojPlenum] = useState(['', '']);
    const [l_uzkoj_plenum, setLUzkojPlenum] = useState(['', '']);
    const [regional_meets, setRegionalMeets] = useState(['', '']);
    const [l_regional_meets, setLRegionalMeets] = useState(['', '']);
    const [society_meets, setSocietyMeets] = useState(['', '']);
    const [l_society_meets, setLSocietyMeets] = useState(['', '']);
    const [ntk_meets, setNtkMeets] = useState(['', '']);
    const [l_ntk_meets, setLNtkMeets] = useState(['', '']);
    const [ntk_s_meets, setNtkSMeets] = useState(['', '']);
    const [l_ntk_s_meets, setLNtkSMeets] = useState(['', '']);
    const [collect_fees, setCollectFees] = useState(['', '']);
    const [l_collect_fees, setLCollectFees] = useState(['', '']);
    const [seminars, setSeminars] = useState(['', '']);
    const [l_seminars, setLSeminars] = useState(['', '']);
    const [identification, setIdentification] = useState(['', '']);
    const [l_identification, setLIdentification] = useState(['', '']);
    const [involve, setInvolve] = useState(['', '']);
    const [l_involve, setLInvolve] = useState(['', '']);
    const [examination, setExamination] = useState(['', '']);
    const [l_examination, setLExamination] = useState(['', '']);
    const [referrals, setReferrals] = useState(['', '']);
    const [l_referrals, setLReferrals] = useState(['', '']);
    const [enterprises, setEnterprises] = useState(['', '']);
    const [l_enterprises, setLEnterprises] = useState(['', '']);
    const [o_enterprises, setOEnterprises] = useState(['', '']);
    const [lo_enterprises, setLOEnterprises] = useState(['', '']);
    const [provision, setProvision] = useState(['', '']);
    const [l_provision, setLProvision] = useState(['', '']);
    const [lvision, setLvision] = useState(['', '']);
    const [l_lvision, setLLvision] = useState(['', '']);
    const [vocational, setVocational] = useState(['', '']);
    const [l_vocational, setLVocational] = useState(['', '']);
    const [bliteracy, setBliteracy] = useState(['', '']);
    const [l_bliteracy, setLBliteracy] = useState(['', '']);
    const [cliteracy, setCliteracy] = useState(['', '']);
    const [l_cliteracy, setLCliteracy] = useState(['', '']);
    const [number_volunteers, setNumberVolunteers] = useState(['', '']);
    const [l_number_volunteers, setLNumberVolunteers] = useState(['', '']);
    const [fassistance, setFassistance] = useState(['', '']);
    const [l_fassistance, setLFassistance] = useState(['', '']);
    const [p_support, setPsupport] = useState(['', '']);
    const [l_p_support, setLPsupport] = useState(['', '']);
    const [l_assistance, setLassistance] = useState(['', '']);
    const [l_l_assistance, setLLassistance] = useState(['', '']);
    const [benefits, setBenefits] = useState(['', '']);
    const [l_benefits, setLBenefits] = useState(['', '']);
    const [uzkoj_mbk, setUzkojMBK] = useState(['', '']);
    const [l_uzkoj_mbk, setLUzkojMBK] = useState(['', '']);
    const [event_provisions, setEventProvisions] = useState([{title: '', provision1: '',
        provision2: '', l_provision1: '', l_provision2: ''}]);

    useEffect(()=>{
        if(event_provisions.length){
            var sum_provision1 = 0;
            var sum_provision2 = 0;
            var sum_l_provision1 = 0;
            var sum_l_provision2 = 0;
            for (let i= 0; i < event_provisions.length; i++) {
                sum_provision1 = sum_provision1 + Number(event_provisions[i].provision1)
                sum_provision2 = sum_provision2 + Number(event_provisions[i].provision2)
                sum_l_provision1 = sum_l_provision1 + Number(event_provisions[i].l_provision1)
                sum_l_provision2 = sum_l_provision2 + Number(event_provisions[i].l_provision2)
            }
            setProvision([sum_provision1, sum_provision2])
            setLProvision([sum_l_provision1, sum_l_provision2])
        }
    }, [event_provisions])

    function AddProvision(){
        var my_event_provisions = event_provisions
        var data = {title: '', provision1: '', provision2: '', l_provision1: '', l_provision2: ''}
        my_event_provisions.push(data)
        return setEventProvisions([...my_event_provisions])
    }

    function RemoveProvision(){
        var my_event_provisions = event_provisions
        my_event_provisions.pop()
        return setEventProvisions([...my_event_provisions])
    }

    function changeTitle(itemIndex, val) {
        setEventProvisions(prevState => prevState.map((item,index) => index === itemIndex ?
            {...item, title: val}
            : item))
    }

    function changeProvision1(itemIndex, val) {
        setEventProvisions(prevState => prevState.map((item,index) => index === itemIndex ?
            {...item, provision1: val}
            : item))
    }

    function changeProvision2(itemIndex, val) {
        setEventProvisions(prevState => prevState.map((item,index) => index === itemIndex ?
            {...item, provision2: val}
            : item))
    }

    function changeLProvision1(itemIndex, val) {
        setEventProvisions(prevState => prevState.map((item,index) => index === itemIndex ?
            {...item, l_provision1: val}
            : item))
    }

    function changeLProvision2(itemIndex, val) {
        setEventProvisions(prevState => prevState.map((item,index) => index === itemIndex ?
            {...item, l_provision2: val}
            : item))
    }


    function saveFormData(e) {
        e.preventDefault()
        if (!report) {
            setHasError(true)
        } else {
            setHasError(false)
        }
        if (!period) {
            setHasError1(true)
        } else {
            setHasError1(false)
        }
        const data = {
            "type": report,
            "year": e.target[2].value,
            "month": period,
            "ktut": ktut,
            "mhobt": mhobt,
            "xxtut": xxtut,
            "address": address,
            "is_send": isSend,
            "event_provisions": event_provisions,
            "f_theme_nights1": f_theme_nights[0],
            "f_theme_nights2": f_theme_nights[1],
            "f_theme_nights3": f_theme_nights[2],
            "l_theme_nights1": l_theme_nights[0],
            "l_theme_nights2": l_theme_nights[1],
            "l_theme_nights3": l_theme_nights[2],
            "f_interviews1": f_interviews[0],
            "f_interviews2": f_interviews[1],
            "f_interviews3": f_interviews[2],
            "l_interviews1": l_interviews[0],
            "l_interviews2": l_interviews[1],
            "l_interviews3": l_interviews[2],
            "f_meetings1": f_meetings[0],
            "f_meetings2": f_meetings[1],
            "f_meetings3": f_meetings[2],
            "l_meetings1": l_meetings[0],
            "l_meetings2": l_meetings[1],
            "l_meetings3": l_meetings[2],
            "f_selections1": f_selections[0],
            "f_selections2": f_selections[1],
            "f_selections3": f_selections[2],
            "l_selections1": l_selections[0],
            "l_selections2": l_selections[1],
            "l_selections3": l_selections[2],
            "f_trips1": f_trips[0],
            "f_trips2": f_trips[1],
            "f_trips3": f_trips[2],
            "l_trips1": l_trips[0],
            "l_trips2": l_trips[1],
            "l_trips3": l_trips[2],
            "f_concerts1": f_concerts[0],
            "f_concerts2": f_concerts[1],
            "f_concerts3": f_concerts[2],
            "l_concerts1": l_concerts[0],
            "l_concerts2": l_concerts[1],
            "l_concerts3": l_concerts[2],
            "f_other_concerts1": f_other_concerts[0],
            "f_other_concerts2": f_other_concerts[1],
            "f_other_concerts3": f_other_concerts[2],
            "l_other_concerts1": l_other_concerts[0],
            "l_other_concerts2": l_other_concerts[1],
            "l_other_concerts3": l_other_concerts[2],
            "f_festive_events1": f_festive_events[0],
            "f_festive_events2": f_festive_events[1],
            "f_festive_events3": f_festive_events[2],
            "l_festive_events1": l_festive_events[0],
            "l_festive_events2": l_festive_events[1],
            "l_festive_events3": l_festive_events[2],
            "f_competitions1": f_competitions[0],
            "f_competitions2": f_competitions[1],
            "f_competitions3": f_competitions[2],
            "l_competitions1": l_competitions[0],
            "l_competitions2": l_competitions[1],
            "l_competitions3": l_competitions[2],

            "f_lectures1": f_lectures[0],
            "f_lectures2": f_lectures[1],
            "f_lectures3": f_lectures[2],
            "l_lectures1": l_lectures[0],
            "l_lectures2": l_lectures[1],
            "l_lectures3": l_lectures[2],
            "f_literary_evenings1": f_literary_evenings[0],
            "f_literary_evenings2": f_literary_evenings[1],
            "f_literary_evenings3": f_literary_evenings[2],
            "l_literary_evenings1": l_literary_evenings[0],
            "l_literary_evenings2": l_literary_evenings[1],
            "l_literary_evenings3": l_literary_evenings[2],
            "f_reading_conference1": f_reading_conference[0],
            "f_reading_conference2": f_reading_conference[1],
            "f_reading_conference3": f_reading_conference[2],
            "l_reading_conference1": l_reading_conference[0],
            "l_reading_conference2": l_reading_conference[1],
            "l_reading_conference3": l_reading_conference[2],

            "rec_centers": rec_centers,
            "special_libs": special_libs,
            "libs_users": libs_users,
            "all_circles": all_circles,
            "circles_parts": circles_parts,
            "art_circles": art_circles,
            "art_parts": art_parts,
            "sport_circles": sport_circles,
            "sport_parts": sport_parts,
            "other_circles": other_circles,
            "other_parts": other_parts,
            "uzkoj_plenum1": uzkoj_plenum[0],
            "uzkoj_plenum2": uzkoj_plenum[1],
            "l_uzkoj_plenum1": l_uzkoj_plenum[0],
            "l_uzkoj_plenum2": l_uzkoj_plenum[1],
            "regional_meets1": regional_meets[0],
            "regional_meets2": regional_meets[1],
            "l_regional_meets1": l_regional_meets[0],
            "l_regional_meets2": l_regional_meets[1],
            "society_meets1": society_meets[0],
            "society_meets2": society_meets[1],
            "l_society_meets1": l_society_meets[0],
            "l_society_meets2": l_society_meets[1],
            "uzkoj_mbk1": uzkoj_mbk[0],
            "uzkoj_mbk2": uzkoj_mbk[1],
            "l_uzkoj_mbk1": l_uzkoj_mbk[0],
            "l_uzkoj_mbk2": l_uzkoj_mbk[1],
            "ntk_meets1": ntk_meets[0],
            "ntk_meets2": ntk_meets[1],
            "l_ntk_meets1": l_ntk_meets[0],
            "l_ntk_meets2": l_ntk_meets[1],
            "ntk_s_meets1": ntk_s_meets[0],
            "ntk_s_meets2": ntk_s_meets[1],
            "l_ntk_s_meets1": l_ntk_s_meets[0],
            "l_ntk_s_meets2": l_ntk_s_meets[1],
            "collect_fees1": collect_fees[0],
            "collect_fees2": collect_fees[1],
            "l_collect_fees1": l_collect_fees[0],
            "l_collect_fees2": l_collect_fees[1],
            "seminars1": seminars[0],
            "seminars2": seminars[1],
            "l_seminars1": l_seminars[0],
            "l_seminars2": l_seminars[1],
            "identification1": identification[0],
            "identification2": identification[1],
            "l_identification1": l_identification[0],
            "l_identification2": l_identification[1],
            "involve1": involve[0],
            "involve2": involve[1],
            "l_involve1": l_involve[0],
            "l_involve2": l_involve[1],
            "examination1": examination[0],
            "examination2": examination[1],
            "l_examination1": l_examination[0],
            "l_examination2": l_examination[1],
            "referrals1": referrals[0],
            "referrals2": referrals[1],
            "l_referrals1": l_referrals[0],
            "l_referrals2": l_referrals[1],
            "enterprises1": enterprises[0],
            "enterprises2": enterprises[1],
            "l_enterprises1": l_enterprises[0],
            "l_enterprises2": l_enterprises[1],
            "o_enterprises1": o_enterprises[0],
            "o_enterprises2": o_enterprises[1],
            "lo_enterprises1": lo_enterprises[0],
            "lo_enterprises2": lo_enterprises[1],
            "provision1": provision[0],
            "provision2": provision[1],
            "l_provision1": l_provision[0],
            "l_provision2": l_provision[1],
            "lvision1": lvision[0],
            "lvision2": lvision[1],
            "l_lvision1": l_lvision[0],
            "l_lvision2": l_lvision[1],
            "vocational1": vocational[0],
            "vocational2": vocational[1],
            "l_vocational1": l_vocational[0],
            "l_vocational2": l_vocational[1],
            "bliteracy1": bliteracy[0],
            "bliteracy2": bliteracy[1],
            "l_bliteracy1": l_bliteracy[0],
            "l_bliteracy2": l_bliteracy[1],
            "cliteracy1": cliteracy[0],
            "cliteracy2": cliteracy[1],
            "l_cliteracy1": l_cliteracy[0],
            "l_cliteracy2": l_cliteracy[1],
            "number_volunteers1": number_volunteers[0],
            "number_volunteers2": number_volunteers[1],
            "l_number_volunteers1": l_number_volunteers[0],
            "l_number_volunteers2": l_number_volunteers[1],
            "fassistance1": fassistance[0],
            "fassistance2": fassistance[1],
            "l_fassistance1": l_fassistance[0],
            "l_fassistance2": l_fassistance[1],
            "p_support1": p_support[0],
            "p_support2": p_support[1],
            "l_p_support1": l_p_support[0],
            "l_p_support2": l_p_support[1],
            "l_assistance1": l_assistance[0],
            "l_assistance2": l_assistance[1],
            "l_l_assistance1": l_l_assistance[0],
            "l_l_assistance2": l_l_assistance[1],
            "benefits1": benefits[0],
            "benefits2": benefits[1],
            "l_benefits1": l_benefits[0],
            "l_benefits2": l_benefits[1]
        }
        if (isSend) {
            data['status'] = 2
        }
        props.eventSave(data)
    }

    useEffect(()=>{
        if(art_circles && sport_circles && other_circles){
            let myInt = (Number(art_circles) + Number(sport_circles)) + Number(other_circles)
            setAllCircles(myInt)
        } else{
            setAllCircles('')
        }
    }, [art_circles, sport_circles, other_circles])

    useEffect(()=>{
        if(art_parts && sport_parts && other_parts){
            let myInt = (Number(art_parts) + Number(sport_parts)) + Number(other_parts)
            setCirclesParts(myInt)
        } else{
            setCirclesParts('')
        }
    }, [art_parts, sport_parts, other_parts])

    const navigate = useNavigate()
    const toBack = () => {
        navigate(-1);
    }

    useEffect(() => {
        if (props.message === true) {
            toast.success(t('save_success'), {theme: 'colored'})
            navigate('/events/')
        } else if (props.message === 'error') {
            toast.error(t('save_error'), {theme: 'colored'})
        }
    }, [props.message])

    return <div>
        <div className={'page addStat addEvent'}>
            <Header/>
            <Dashboard/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}>
                <Toolbar/>
                <div className={'main'}>
                        <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={toBack}>
                            {t('back')}
                        </Button>
                    <h2 className={'page-title text-center'}>{t('add_report')}</h2>
                    <form className={'add-form add-stat'} onSubmit={saveFormData}>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>1. {t('report_about')}</h5>
                        </div>
                        <div>
                            <p><span>{t('type')}:</span>
                                <Box sx={{minWidth: 200}}>
                                    <FormControl fullWidth>
                                        <InputLabel error={hasError} required
                                                    id="demo-simple-select-label">{t('type')}</InputLabel>
                                        <Select
                                            error={hasError}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={report}
                                            label={t('type')}
                                            onChange={(e) => {
                                                setReport(e.target.value)
                                            }}
                                        >
                                            <MenuItem value={1}>{t('type_report')}</MenuItem>
                                            <MenuItem value={2}>{t('type_reply_report')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box></p>
                            <p><span>{t('report_year')}:</span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        minDate={new Date("2022-01-01")}
                                        views={['year']}
                                        label={t('report_year')}
                                        value={year}
                                        onChange={(newValue) => {
                                            setYear(newValue);
                                        }}
                                        renderInput={(params) => <TextField required {...params} helperText={null}/>}
                                    />
                                </LocalizationProvider>
                            </p>
                            <p><span>{t('report_month')}:</span>
                                <Box sx={{minWidth: 200}}>
                                    <FormControl fullWidth>
                                        <InputLabel error={hasError1} required
                                                    id="demo-simple-select-label">{t('report_month')}</InputLabel>
                                        <Select
                                            error={hasError1}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={period}
                                            label={t('report_month')}
                                            onChange={(e) => {
                                                setPeriod(e.target.value)
                                            }}
                                        >
                                            <MenuItem value={1}>{t('quarter')}</MenuItem>
                                            <MenuItem value={2}>{t('semi_annual')}</MenuItem>
                                            <MenuItem value={3}>{t('nine_month')}</MenuItem>
                                            <MenuItem value={4}>{t('yearly')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box></p>
                        </div>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>2. {t('about_organization')}</h5>
                        </div>
                        <div>
                            <p>
                                <span>{t('address_organization')}:</span>
                                <TextField value={address} onChange={(e) => setAddress(e.target.value)} required
                                           id="outlined-basic" label={t('address_organization')} variant="outlined"/>
                            </p>
                        </div>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>2.1. {t('unique_numbers')}</h5>
                        </div>
                        <div>
                            <p>
                                <span>{t('ktut')}:</span>
                                <TextField value={ktut} onChange={(e) => setKtut(e.target.value)} required
                                           id="outlined-basic" label={t('ktut')} variant="outlined"/>
                            </p>
                            <p>
                                <span>{t('mhobt')}:</span>
                                <TextField value={mhobt} onChange={(e) => setMhobt(e.target.value)} required
                                           id="outlined-basic" label={t('mhobt')} variant="outlined"/>
                            </p>
                            <p>
                                <span>{t('xxtut')}:</span>
                                <TextField value={xxtut} onChange={(e) => setXxtut(e.target.value)} required
                                           id="outlined-basic" label={t('xxtut')} variant="outlined"/>
                            </p>
                        </div>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>3. {t('agg_data')}</h5>
                        </div>
                        <table className={'table table-bordered'}>
                            <thead>
                            <tr>
                                <th rowSpan={2}>№</th>
                                <th rowSpan={2}>{t('title_events')}</th>
                                <th>{t('in_quarter')}</th>
                                <th>{t('in_yearly')}</th>
                            </tr>
                            <tr>
                                <th className={'th-head'}>
                                    <th>{t('plan')}</th>
                                    <th>{t('practice')}</th>
                                    <th>{t('parts')}</th>
                                </th>
                                <th className={'th-head'}>
                                    <th>{t('plan')}</th>
                                    <th>{t('practice')}</th>
                                    <th>{t('parts')}</th>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>{t('theme_nights')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_theme_nights[0]}
                                               onChange={(e) => setThemeNights([e.target.value, f_theme_nights[1], f_theme_nights[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_theme_nights[1]}
                                               onChange={(e) => setThemeNights([f_theme_nights[0], e.target.value, f_theme_nights[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_theme_nights[2]}
                                               onChange={(e) => setThemeNights([f_theme_nights[0], f_theme_nights[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_theme_nights[0]}
                                               onChange={(e) => setlThemeNights([e.target.value, l_theme_nights[1], l_theme_nights[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_theme_nights[1]}
                                               onChange={(e) => setlThemeNights([l_theme_nights[0], e.target.value, l_theme_nights[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_theme_nights[2]}
                                               onChange={(e) => setlThemeNights([l_theme_nights[0], l_theme_nights[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>{t('interviews')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_interviews[0]}
                                               onChange={(e) => setInterviews([e.target.value, f_interviews[1], f_interviews[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_interviews[1]}
                                               onChange={(e) => setInterviews([f_interviews[0], e.target.value, f_interviews[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_interviews[2]}
                                               onChange={(e) => setInterviews([f_interviews[0], f_interviews[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_interviews[0]}
                                               onChange={(e) => setLInterviews([e.target.value, l_interviews[1], l_interviews[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_interviews[1]}
                                               onChange={(e) => setLInterviews([l_interviews[0], e.target.value, l_interviews[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_interviews[2]}
                                               onChange={(e) => setLInterviews([l_interviews[0], l_interviews[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>{t('meetings')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_meetings[0]}
                                               onChange={(e) => setMeetings([e.target.value, f_meetings[1], f_meetings[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_meetings[1]}
                                               onChange={(e) => setMeetings([f_meetings[0], e.target.value, f_meetings[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_meetings[2]}
                                               onChange={(e) => setMeetings([f_meetings[0], f_meetings[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_meetings[0]}
                                               onChange={(e) => setLMeetings([e.target.value, l_meetings[1], l_meetings[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_meetings[1]}
                                               onChange={(e) => setLMeetings([l_meetings[0], e.target.value, l_meetings[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_meetings[2]}
                                               onChange={(e) => setLMeetings([l_meetings[0], l_meetings[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>{t('selections')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_selections[0]}
                                               onChange={(e) => setSelections([e.target.value, f_selections[1], f_selections[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_selections[1]}
                                               onChange={(e) => setSelections([f_selections[0], e.target.value, f_selections[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_selections[2]}
                                               onChange={(e) => setSelections([f_selections[0], f_selections[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_selections[0]}
                                               onChange={(e) => setLSelections([e.target.value, l_selections[1], l_selections[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_selections[1]}
                                               onChange={(e) => setLSelections([l_selections[0], e.target.value, l_selections[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_selections[2]}
                                               onChange={(e) => setLSelections([l_selections[0], l_selections[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>{t('trips')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_trips[0]}
                                               onChange={(e) => setTrips([e.target.value, f_trips[1], f_trips[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_trips[1]}
                                               onChange={(e) => setTrips([f_trips[0], e.target.value, f_trips[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_trips[2]}
                                               onChange={(e) => setTrips([f_trips[0], f_trips[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_trips[0]}
                                               onChange={(e) => setLTrips([e.target.value, l_trips[1], l_trips[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_trips[1]}
                                               onChange={(e) => setLTrips([l_trips[0], e.target.value, l_trips[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_trips[2]}
                                               onChange={(e) => setLTrips([l_trips[0], l_trips[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>{t('concerts')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_concerts[0]}
                                               onChange={(e) => setConcerts([e.target.value, f_concerts[1], f_concerts[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_concerts[1]}
                                               onChange={(e) => setConcerts([f_concerts[0], e.target.value, f_concerts[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_concerts[2]}
                                               onChange={(e) => setConcerts([f_concerts[0], f_concerts[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_concerts[0]}
                                               onChange={(e) => setLConcerts([e.target.value, l_concerts[1], l_concerts[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_concerts[1]}
                                               onChange={(e) => setLConcerts([l_concerts[0], e.target.value, l_concerts[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_concerts[2]}
                                               onChange={(e) => setLConcerts([l_concerts[0], l_concerts[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>{t('other_concerts')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_other_concerts[0]}
                                               onChange={(e) => setOtherConcerts([e.target.value, f_other_concerts[1], f_other_concerts[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_other_concerts[1]}
                                               onChange={(e) => setOtherConcerts([f_other_concerts[0], e.target.value, f_other_concerts[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_other_concerts[2]}
                                               onChange={(e) => setOtherConcerts([f_other_concerts[0], f_other_concerts[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_other_concerts[0]}
                                               onChange={(e) => setLOtherConcerts([e.target.value, l_other_concerts[1], l_other_concerts[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_other_concerts[1]}
                                               onChange={(e) => setLOtherConcerts([l_other_concerts[0], e.target.value, l_other_concerts[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_other_concerts[3]}
                                               onChange={(e) => setLOtherConcerts([l_other_concerts[0], l_other_concerts[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>{t('festive_events')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_festive_events[0]}
                                               onChange={(e) => setFestiveEvents([e.target.value, f_festive_events[1], f_festive_events[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_festive_events[1]}
                                               onChange={(e) => setFestiveEvents([f_festive_events[0], e.target.value, f_festive_events[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_festive_events[2]}
                                               onChange={(e) => setFestiveEvents([f_festive_events[0], f_festive_events[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_festive_events[0]}
                                               onChange={(e) => setLFestiveEvents([e.target.value, l_festive_events[1], l_festive_events[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_festive_events[1]}
                                               onChange={(e) => setLFestiveEvents([l_festive_events[0], e.target.value, l_festive_events[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_festive_events[2]}
                                               onChange={(e) => setLFestiveEvents([l_festive_events[0], l_festive_events[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>{t('competitions')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_competitions[0]}
                                               onChange={(e) => setCompetitions([e.target.value, f_competitions[1], f_competitions[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_competitions[1]}
                                               onChange={(e) => setCompetitions([f_competitions[0], e.target.value, f_competitions[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_competitions[2]}
                                               onChange={(e) => setCompetitions([f_competitions[0], f_competitions[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_competitions[0]}
                                               onChange={(e) => setLCompetitions([e.target.value, l_competitions[1], l_competitions[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_competitions[1]}
                                               onChange={(e) => setLCompetitions([l_competitions[0], e.target.value, l_competitions[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_competitions[2]}
                                               onChange={(e) => setLCompetitions([l_competitions[0], l_competitions[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>{t('lectures')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_lectures[0]}
                                               onChange={(e) => setFLectures([e.target.value, f_lectures[1], f_lectures[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_lectures[1]}
                                               onChange={(e) => setFLectures([f_lectures[0], e.target.value, f_lectures[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_lectures[2]}
                                               onChange={(e) => setFLectures([f_lectures[0], f_lectures[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_lectures[0]}
                                               onChange={(e) => setLLectures([e.target.value, l_lectures[1], l_lectures[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_lectures[1]}
                                               onChange={(e) => setLLectures([l_lectures[0], e.target.value, l_lectures[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_lectures[2]}
                                               onChange={(e) => setLLectures([l_lectures[0], l_lectures[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>{t('literary_evenings')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_literary_evenings[0]}
                                               onChange={(e) => setFLiteraryEvenings([e.target.value, f_literary_evenings[1], f_literary_evenings[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_literary_evenings[1]}
                                               onChange={(e) => setFLiteraryEvenings([f_literary_evenings[0], e.target.value, f_literary_evenings[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_literary_evenings[2]}
                                               onChange={(e) => setFLiteraryEvenings([f_literary_evenings[0], f_literary_evenings[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_literary_evenings[0]}
                                               onChange={(e) => setLLiteraryEvenings([e.target.value, l_literary_evenings[1], l_literary_evenings[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_literary_evenings[1]}
                                               onChange={(e) => setLLiteraryEvenings([l_literary_evenings[0], e.target.value, l_literary_evenings[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_literary_evenings[2]}
                                               onChange={(e) => setLLiteraryEvenings([l_literary_evenings[0], l_literary_evenings[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>{t('reading_conference')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_reading_conference[0]}
                                               onChange={(e) => setFReadingConference([e.target.value, f_reading_conference[1], f_reading_conference[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_reading_conference[1]}
                                               onChange={(e) => setFReadingConference([f_reading_conference[0], e.target.value, f_reading_conference[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_reading_conference[2]}
                                               onChange={(e) => setFReadingConference([f_reading_conference[0], f_reading_conference[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_reading_conference[0]}
                                               onChange={(e) => setLReadingConference([e.target.value, l_reading_conference[1], l_reading_conference[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_reading_conference[1]}
                                               onChange={(e) => setLReadingConference([l_reading_conference[0], e.target.value, l_reading_conference[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_reading_conference[2]}
                                               onChange={(e) => setLReadingConference([l_reading_conference[0], l_reading_conference[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>4. {t('circles')}</h5>
                        </div>
                        <table className={'table table-bordered'}>
                            <thead>
                            <tr>
                                <th>№</th>
                                <th>{t('type_insts')}</th>
                                <th>{t('number')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>{t('rec_centers')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={rec_centers}
                                           onChange={(e) => setRecCenters(e.target.value)}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>{t('special_libs')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={special_libs}
                                           onChange={(e) => setSpecialLibs(e.target.value)}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <td>{t('libs_users')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={libs_users}
                                           onChange={(e) => setLibsUsers(e.target.value)}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>{t('all_circles')} ({t('all')})</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={all_circles}
                                           disabled
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <td>{t('circles_parts')} ({t('all')})</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={circles_parts}
                                           disabled
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <th>{t('including')}</th>
                                <td/>
                            </tr>
                            <tr>
                                <td>{t('A')}</td>
                                <td>{t('art_circles')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={art_circles}
                                           onChange={(e) => setArtCircles(e.target.value)}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <td>{t('circles_parts')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={art_parts}
                                           onChange={(e) => setArtParts(e.target.value)}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('B')}</td>
                                <td>{t('sport_circles')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={sport_circles}
                                           onChange={(e) => setSportCircles(e.target.value)}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <td>{t('circles_parts')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={sport_parts}
                                           onChange={(e) => setSportParts(e.target.value)}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('V')}</td>
                                <td>{t('other_circles')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={other_circles}
                                           onChange={(e) => setotherCircles(e.target.value)}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <td>{t('circles_parts')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={other_parts}
                                           onChange={(e) => setOtherParts(e.target.value)}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>5. {t('org_work')}</h5>
                        </div>
                        <table className={'table table-bordered org_work'}>
                            <thead>
                            <tr>
                                <th rowSpan={2}>№</th>
                                <th rowSpan={2}>{t('title_events')}</th>
                                <th>{t('in_quarter')}</th>
                                <th>{t('in_yearly')}</th>
                            </tr>
                            <tr>
                                <th className={'th-head-secondary'}>
                                    <th>{t('plan')}</th>
                                    <th>{t('practice')}</th>
                                </th>
                                <th className={'th-head-secondary'}>
                                    <th>{t('plan')}</th>
                                    <th>{t('practice')}</th>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>{t('uzkoj_plenum')}</td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={uzkoj_plenum[0]}
                                               onChange={(e) => setUzkojPlenum([e.target.value, uzkoj_plenum[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={uzkoj_plenum[1]}
                                               onChange={(e) => setUzkojPlenum([uzkoj_plenum[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_uzkoj_plenum[0]}
                                               onChange={(e) => setLUzkojPlenum([e.target.value, l_uzkoj_plenum[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_uzkoj_plenum[1]}
                                               onChange={(e) => setLUzkojPlenum([l_uzkoj_plenum[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>{t('regional_meets')}</td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={regional_meets[0]}
                                               onChange={(e) => setRegionalMeets([e.target.value, regional_meets[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={regional_meets[1]}
                                               onChange={(e) => setRegionalMeets([regional_meets[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_regional_meets[0]}
                                               onChange={(e) => setLRegionalMeets([e.target.value, l_regional_meets[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_regional_meets[1]}
                                               onChange={(e) => setLRegionalMeets([l_regional_meets[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>{t('society_meets')}</td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={society_meets[0]}
                                               onChange={(e) => setSocietyMeets([e.target.value, society_meets[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={society_meets[1]}
                                               onChange={(e) => setSocietyMeets([society_meets[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_society_meets[0]}
                                               onChange={(e) => setLSocietyMeets([e.target.value, l_society_meets[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_society_meets[1]}
                                               onChange={(e) => setLSocietyMeets([l_society_meets[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>{t('uzkoj_mbk')}</td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={uzkoj_mbk[0]}
                                               onChange={(e) => setUzkojMBK([e.target.value, uzkoj_mbk[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={uzkoj_mbk[1]}
                                               onChange={(e) => setUzkojMBK([uzkoj_mbk[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_uzkoj_mbk[0]}
                                               onChange={(e) => setLUzkojMBK([e.target.value, l_uzkoj_mbk[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_uzkoj_mbk[1]}
                                               onChange={(e) => setLUzkojMBK([l_uzkoj_mbk[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>{t('ntk_meets')}</td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={ntk_meets[0]}
                                               onChange={(e) => setNtkMeets([e.target.value, ntk_meets[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={ntk_meets[1]}
                                               onChange={(e) => setNtkMeets([ntk_meets[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_ntk_meets[0]}
                                               onChange={(e) => setLNtkMeets([e.target.value, l_ntk_meets[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_ntk_meets[1]}
                                               onChange={(e) => setLNtkMeets([l_ntk_meets[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>{t('ntk_s_meets')}</td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={ntk_s_meets[0]}
                                               onChange={(e) => setNtkSMeets([e.target.value, ntk_s_meets[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={ntk_s_meets[1]}
                                               onChange={(e) => setNtkSMeets([ntk_s_meets[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_ntk_s_meets[0]}
                                               onChange={(e) => setLNtkSMeets([e.target.value, l_ntk_s_meets[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_ntk_s_meets[1]}
                                               onChange={(e) => setLNtkSMeets([l_ntk_s_meets[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>{t('collect_fees')}</td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={collect_fees[0]}
                                               onChange={(e) => setCollectFees([e.target.value, collect_fees[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={collect_fees[1]}
                                               onChange={(e) => setCollectFees([collect_fees[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_collect_fees[0]}
                                               onChange={(e) => setLCollectFees([e.target.value, l_collect_fees[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_collect_fees[1]}
                                               onChange={(e) => setLCollectFees([l_collect_fees[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>{t('seminars')}</td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={seminars[0]}
                                               onChange={(e) => setSeminars([e.target.value, seminars[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={seminars[1]}
                                               onChange={(e) => setSeminars([seminars[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_seminars[0]}
                                               onChange={(e) => setLSeminars([e.target.value, l_seminars[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_seminars[1]}
                                               onChange={(e) => setLSeminars([l_seminars[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>6. {t('rehabilitation')}</h5>
                        </div>
                        <table className={'table table-bordered org_work'}>
                            <thead>
                            <tr>
                                <th rowSpan={2}>№</th>
                                <th rowSpan={2}>{t('title_events')}</th>
                                <th>{t('in_quarter')}</th>
                                <th>{t('in_yearly')}</th>
                            </tr>
                            <tr>
                                <th className={'th-head-secondary'}>
                                    <th>{t('plan')}</th>
                                    <th>{t('practice')}</th>
                                </th>
                                <th className={'th-head-secondary'}>
                                    <th>{t('plan')}</th>
                                    <th>{t('practice')}</th>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>{t('identification')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={identification[0]}
                                               onChange={(e) => setIdentification([e.target.value, identification[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={identification[1]}
                                               onChange={(e) => setIdentification([identification[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_identification[0]}
                                               onChange={(e) => setLIdentification([e.target.value, l_identification[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_identification[1]}
                                               onChange={(e) => setLIdentification([l_identification[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>{t('involve')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={involve[0]}
                                               onChange={(e) => setInvolve([e.target.value, involve[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={involve[1]}
                                               onChange={(e) => setInvolve([involve[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_involve[0]}
                                               onChange={(e) => setLInvolve([e.target.value, l_involve[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_involve[1]}
                                               onChange={(e) => setLInvolve([l_involve[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>{t('examination')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={examination[0]}
                                               onChange={(e) => setExamination([e.target.value, examination[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={examination[1]}
                                               onChange={(e) => setExamination([examination[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_examination[0]}
                                               onChange={(e) => setLExamination([e.target.value, l_examination[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_examination[1]}
                                               onChange={(e) => setLExamination([l_examination[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>{t('referrals')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={referrals[0]}
                                               onChange={(e) => setReferrals([e.target.value, referrals[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={referrals[1]}
                                               onChange={(e) => setReferrals([referrals[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_referrals[0]}
                                               onChange={(e) => setLReferrals([e.target.value, l_referrals[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_referrals[1]}
                                               onChange={(e) => setLReferrals([l_referrals[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>{t('enterprises')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={enterprises[0]}
                                               onChange={(e) => setEnterprises([e.target.value, enterprises[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={enterprises[1]}
                                               onChange={(e) => setEnterprises([enterprises[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_enterprises[0]}
                                               onChange={(e) => setLEnterprises([e.target.value, l_enterprises[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_enterprises[1]}
                                               onChange={(e) => setLEnterprises([l_enterprises[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>{t('o_enterprises')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={o_enterprises[0]}
                                               onChange={(e) => setOEnterprises([e.target.value, o_enterprises[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={o_enterprises[1]}
                                               onChange={(e) => setOEnterprises([o_enterprises[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={lo_enterprises[0]}
                                               onChange={(e) => setLOEnterprises([e.target.value, lo_enterprises[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={lo_enterprises[1]}
                                               onChange={(e) => setLOEnterprises([lo_enterprises[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>{t('provision')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               required min="0" value={provision[0]} disabled/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               required min="0" value={provision[1]} disabled/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               required min="0" disabled value={l_provision[0]}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               required min="0" value={l_provision[1]} disabled/>
                                    </td>
                                </td>
                            </tr>
                            {event_provisions.map((item, index)=>
                                <tr key={index}>
                                    <td></td>
                                    <td>
                                        <input type="text" placeholder={t('provision_title')} onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')} required value={item.title}
                                               onChange={(e) => changeTitle(index, e.target.value)}/>
                                    </td>
                                    <td className={'td-head-secondary'}>
                                        <td>
                                            <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                                   onInput={F => F.target.setCustomValidity('')}  required min="0" value={item.provision1}
                                                   onChange={(e) => changeProvision1(index, e.target.value)}
                                                   onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                        </td>
                                        <td>
                                            <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                                   onInput={F => F.target.setCustomValidity('')}  required min="0" value={item.provision2}
                                                   onChange={(e) => changeProvision2(index, e.target.value)}
                                                   onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                        </td>
                                    </td>
                                    <td className={'td-head-secondary'}>
                                        <td>
                                            <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                                   onInput={F => F.target.setCustomValidity('')} required min="0" value={item.l_provision1}
                                                   onChange={(e) => changeLProvision1(index, e.target.value)}
                                                   onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                        </td>
                                        <td>
                                            <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                                   onInput={F => F.target.setCustomValidity('')} required min="0" value={item.l_provision2}
                                                   onChange={(e) => changeLProvision2(index, e.target.value)}
                                                   onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                        </td>
                                    </td>
                                </tr>)}
                            </tbody>
                            <tbody className={'add-product-btn'}>
                            <tr>
                                <td colSpan={2}>
                                    <Button onClick={AddProvision} style={{width: '25%'}} variant="contained" fullWidth color={'primary'}
                                            endIcon={<AddIcon/>} title={t('add_prevision')}/>
                                    {event_provisions.length > 1 ?
                                        <Button onClick={RemoveProvision} style={{width: '25%'}} variant="contained" fullWidth color={'error'}
                                                endIcon={<RemoveIcon/>} title={t('delete')}/> : ''}

                                </td>
                            </tr>
                            </tbody>
                            <tbody>
                            <tr>
                                <td>8</td>
                                <td>{t('l_vision')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={lvision[0]}
                                               onChange={(e) => setLvision([e.target.value, lvision[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={lvision[1]}
                                               onChange={(e) => setLvision([lvision[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_lvision[0]}
                                               onChange={(e) => setLLvision([e.target.value, l_lvision[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_lvision[1]}
                                               onChange={(e) => setLLvision([l_lvision[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>{t('vocational')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={vocational[0]}
                                               onChange={(e) => setVocational([e.target.value, vocational[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={vocational[1]}
                                               onChange={(e) => setVocational([vocational[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_vocational[0]}
                                               onChange={(e) => setLVocational([e.target.value, l_vocational[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_vocational[1]}
                                               onChange={(e) => setLVocational([l_vocational[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>{t('b_literacy')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={bliteracy[0]}
                                               onChange={(e) => setBliteracy([e.target.value, bliteracy[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={bliteracy[1]}
                                               onChange={(e) => setBliteracy([bliteracy[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_bliteracy[0]}
                                               onChange={(e) => setLBliteracy([e.target.value, l_bliteracy[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_bliteracy[1]}
                                               onChange={(e) => setLBliteracy([l_bliteracy[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>{t('c_literacy')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={cliteracy[0]}
                                               onChange={(e) => setCliteracy([e.target.value, cliteracy[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={cliteracy[1]}
                                               onChange={(e) => setCliteracy([cliteracy[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_cliteracy[0]}
                                               onChange={(e) => setLCliteracy([e.target.value, l_cliteracy[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_cliteracy[1]}
                                               onChange={(e) => setLCliteracy([l_cliteracy[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>{t('number_volunteers')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={number_volunteers[0]}
                                               onChange={(e) => setNumberVolunteers([e.target.value, number_volunteers[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={number_volunteers[1]}
                                               onChange={(e) => setNumberVolunteers([number_volunteers[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_number_volunteers[0]}
                                               onChange={(e) => setLNumberVolunteers([e.target.value, l_number_volunteers[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_number_volunteers[1]}
                                               onChange={(e) => setLNumberVolunteers([l_number_volunteers[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>7. {t('measures')}</h5>
                        </div>
                        <table className={'table table-bordered org_work'}>
                            <thead>
                            <tr>
                                <th rowSpan={2}>№</th>
                                <th rowSpan={2}>{t('title_events')}</th>
                                <th>{t('in_quarter')}</th>
                                <th>{t('in_yearly')}</th>
                            </tr>
                            <tr>
                                <th className={'th-head-secondary'}>
                                    <th>{t('plan')}</th>
                                    <th>{t('practice')}</th>
                                </th>
                                <th className={'th-head-secondary'}>
                                    <th>{t('plan')}</th>
                                    <th>{t('practice')}</th>
                                </th>
                            </tr>
                            </thead>
                            <tbody className={'last-info-tbody'}>
                            <tr>
                                <td>1</td>
                                <td style={{whiteSpace: 'pre-wrap'}}>{t('f_assistance')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="text" value={fassistance[0]}
                                               onChange={(e) => setFassistance([e.target.value, fassistance[1]])}/>
                                    </td>
                                    <td>
                                        <input type="text" value={fassistance[1]}
                                               onChange={(e) => setFassistance([fassistance[0], e.target.value])}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="text" value={l_fassistance[0]}
                                               onChange={(e) => setLFassistance([e.target.value, l_fassistance[1]])}/>
                                    </td>
                                    <td>
                                        <input type="text" value={l_fassistance[1]}
                                               onChange={(e) => setLFassistance([l_fassistance[0], e.target.value])}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td style={{whiteSpace: 'pre-wrap'}}>{t('p_support')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="text" value={p_support[0]}
                                               onChange={(e) => setPsupport([e.target.value, p_support[1]])}/>
                                    </td>
                                    <td>
                                        <input type="text" value={p_support[1]}
                                               onChange={(e) => setPsupport([p_support[0], e.target.value])}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="text" value={l_p_support[0]}
                                               onChange={(e) => setLPsupport([e.target.value, l_p_support[1]])}/>
                                    </td>
                                    <td>
                                        <input type="text" value={l_p_support[1]}
                                               onChange={(e) => setLPsupport([l_p_support[0], e.target.value])}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td style={{whiteSpace: 'pre-wrap'}}>{t('l_assistance')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="text" value={l_assistance[0]}
                                               onChange={(e) => setLassistance([e.target.value, l_assistance[1]])}/>
                                    </td>
                                    <td>
                                        <input type="text" value={l_assistance[1]}
                                               onChange={(e) => setLassistance([l_assistance[0], e.target.value])}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="text" value={l_l_assistance[0]}
                                               onChange={(e) => setLLassistance([e.target.value, l_l_assistance[1]])}/>
                                    </td>
                                    <td>
                                        <input type="text" value={l_l_assistance[1]}
                                               onChange={(e) => setLLassistance([l_l_assistance[0], e.target.value])}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td style={{whiteSpace: 'pre-wrap'}}>{t('benefits')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="text" value={benefits[0]}
                                               onChange={(e) => setBenefits([e.target.value, benefits[1]])}/>
                                    </td>
                                    <td>
                                        <input type="text" value={benefits[1]}
                                               onChange={(e) => setBenefits([benefits[0], e.target.value])}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="text" value={l_benefits[0]}
                                               onChange={(e) => setLBenefits([e.target.value, l_benefits[1]])}/>
                                    </td>
                                    <td>
                                        <input type="text" value={l_benefits[1]}
                                               onChange={(e) => setLBenefits([l_benefits[0], e.target.value])}/>
                                    </td>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="button-group">
                            <Button type={'submit'} style={{width: '25%'}} variant="contained"
                                    fullWidth color={'info'} onClick={() => setIsSend(false)}>
                                {t('save')}
                            </Button>
                            <Button type={'submit'} style={{marginLeft: '20px', width: '25%'}} variant="contained"
                                    fullWidth color={'success'} onClick={() => setIsSend(true)}>
                                {t('send')}
                            </Button>
                        </div>
                    </form>
                </div>
                <Copyright/>
            </Box>
        </div>
    </div>
}

export default connect(props => props.events, {eventSave, getAllVerifyResponse, messageReset})(AddEvents)