import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getAllVerifyResponse} from "../store/logIn";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Button from "@mui/material/Button";
import Header from "../blocks/header";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import Box from "@mui/material/Box";
import Dashboard from "../blocks/dashboard";
import Copyright from "../blocks/copyright";
import {toast} from "react-toastify";
import TabPanel from '@mui/lab/TabPanel';
import {useTranslation} from "react-i18next";
import DeleteModal from "../blocks/deleteModal";
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import {FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Tab} from "@mui/material";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import {deleteStats, getStats, messageReset} from "../store/stats";
import {TabContext, TabList} from "@mui/lab";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const label = {inputProps: {'aria-label': 'Checkbox'}};

function Stats(props) {
    const is_main = JSON.parse(localStorage.getItem('is_main'));
    const navigate = useNavigate()
    const params = useParams()
    const [regionId, setRegionId] = useState(false)
    useEffect(()=>{
        setRegionId(params.id)
    }, [params.id])
    const toBack = () => {
        navigate(-1);
    }
    const {t, i18n} = useTranslation()
    const [stats, setStats] = useState([])
    const [statsObj, setStatsObj] = useState([])
    const [tab, setTab] = useState('one')
    const [filteredStats, setFilteredStats] = useState(false)
    const [open, setOpen] = useState(false)
    const [openItem, setOpenItem] = useState(false)
    const [year, setYear] = useState(new Date().getFullYear().toString())
    const [month, setMonth] = useState(1)
    const [search, setSearch] = useState('')
    const [fileIds, setFileIds] = useState([])

    useEffect(() => {
        props.getStats()
        if (!year || year == 1970){
            let date =  new Date().getFullYear().toString()
            setYear(date)
        }
    }, [])

    useEffect(()=>{
        setFileIds([])
    }, [tab])

    useEffect(() => {
        if (month === 0){
            setStats(props.stats.filter(item=>regionId ? item.user.id == regionId : item).filter(item => (item.year == year)))
        }else{
            setStats(props.stats.filter(item=>regionId ? item.user.id == regionId : item).filter(item => (item.year == year && item.month == month)))
        }
        setStatsObj(props.stats.filter(item=>regionId ? item.user.id == regionId : item))
    }, [props.stats])

    useEffect(() => {
        if (!year || year == 1970){
            let date =  new Date().getFullYear().toString()
            setYear(date)
        }
        if (month === 0){
            let mystats = props.stats.filter(item=>regionId ? item.user.id == regionId : item).filter(item => (item.year == year))
            setStats([...mystats])
        }else{
            let mystats = props.stats.filter(item=>regionId ? item.user.id == regionId : item).filter(item => (item.year == year && item.month == month))
            setStats([...mystats])
        }
    }, [filteredStats])


    function saveFile() {
        if (!year || year == 1970){
            let date =  new Date().getFullYear().toString()
            setYear(date)
        }
        if (!year){
            let date =  new Date().getFullYear().toString()
            setYear(date)
        }
        if (fileIds.length > 0 || tab !== 'one') {
            let report_id_list = fileIds
            let bodyFormData = new FormData();
            if (fileIds.length > 0){
                report_id_list.forEach((item) => {
                    bodyFormData.append("id_list", item);
                });
            }
            if (regionId){
                bodyFormData.append("region_id", regionId);
            }
            if (tab !== 'one'){
                function tabId(val){
                    switch (val){
                        case 'two':
                            return "2"
                        case 'three':
                            return "3"
                        case 'four':
                            return "4"
                        default:
                            return "0"
                    }
                }
                bodyFormData.append("table_id", tabId(tab))
                bodyFormData.append("month", month.toString())
                if (typeof year != "string" || year == 1970){
                    const mydate = new Date(year).getFullYear().toString()
                    bodyFormData.append("year", mydate)
                }else{
                    bodyFormData.append("year", year.toString())
                }
            }
            axios({
                url: 'https://ijtimoiybolim.uzkoj.uz/api/get-excel/', //your url
                method: 'POST',
                data: bodyFormData,
                responseType: 'blob', // important
            }).then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${Date.now()}.xlsx`,); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            });
        } else {
            axios({
                url: 'https://ijtimoiybolim.uzkoj.uz/api/get-excel/', //your url
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${Date.now()}.xlsx`,); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            });
        }
    }

    function ToFilter(e) {
        e.preventDefault()
        if (!e.target[0].value && !e.target[3].value && e.target[6].value==0) {
            setStats(statsObj)
        } else {
            let filterStats = false
            // if (e.target[0].value && filterStats) {
            //     filterStats = filterStats.filter(item => (item.user.user_name.toLowerCase().search(e.target[0].value) !== -1))
            //     setFilteredStats([...filterStats])
            // } else if (e.target[0].value) {
            //     filterStats = statsObj.filter(item => (item.user.user_name.toLowerCase().search(e.target[0].value) !== -1))
            //     setFilteredStats([...filterStats])
            // }
            if (e.target[0].value && filterStats) {
                filterStats = filterStats.filter(item => (item.year == e.target[0].value))
                setFilteredStats([...filterStats])
            } else if (e.target[0].value) {
                filterStats = statsObj.filter(item => (item.year == e.target[0].value))
                setFilteredStats([...filterStats])
            }
            if (e.target[3].value != 0 && filterStats) {
                filterStats = filterStats.filter(item => (item.month == e.target[3].value))
                setFilteredStats([...filterStats])
            } else if (e.target[3].value != 0) {
                filterStats = statsObj.filter(item => (item.month == e.target[3].value))
                setFilteredStats([...filterStats])
            }
        }
    }

    function toAdd() {
        navigate('/add-stats/')
    }

    function toEdit(data) {
        navigate('/edit-stats/' + data.id)
    }

    function toDelete(item) {
        setOpenItem(item)
        setOpen(true)
    }

    function toView(item) {
        navigate('/view-stats/' + item.id)
    }

    useEffect(() => {
        if (props.message === 'deleted') {
            toast.success(t('delete_success'), {theme: 'colored'})
            setOpenItem(false)
            props.messageReset()
        } else if (props.message === 'error') {
            toast.error(t('save_error'), {theme: 'colored'})
            props.messageReset()
        } else if (props.message === true) {
            toast.success(t('save_success'), {theme: 'colored'})
            props.messageReset()
        }
    }, [props.message])

    function monthSelect(val) {
        switch (val) {
            case 1:
                return t('quarter')
            case 2:
                return t('semi_annual')
            case 3:
                return t('nine_month')
            case 4:
                return t('yearly')
            default:
                break
        }
    }

    function statusSelect(val) {
        switch (val) {
            case 1:
                return t('no_send')
            case 2:
                return t('review')
            case 3:
                return t('canceled')
            case 4:
                return t('accepted')
            default:
                break
        }
    }


    function fileId(value) {
        var array = fileIds
        var index = array.indexOf(value);

        if (index === -1) {
            array.push(value);
            setFileIds([...array])
        } else {
            array.splice(index, 1);
            setFileIds([...array])
        }
    }

    return <div>
        <div className={'page'}>
            {open ? <DeleteModal setOpenItem={setOpenItem} openItem={openItem} open={open} setOpen={setOpen}/> : ''}
            <Header/>
            <Dashboard/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}>
                <Toolbar/>
                <div className={'main'}>
                        <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={toBack}>
                            {t('back')}
                        </Button>
                    <div className={'worker-page'}>
                        <div className={'header-page'}>
                            <h2 className={'page-title'}>{t('stats')}</h2>
                            {!is_main  ?
                                <Button variant="contained" title={t('add_report')} endIcon={<AddIcon/>}
                                        onClick={toAdd}>
                                    {t('add_report')}
                                </Button>
                                : ''}
                        </div>
                        <div className={'filter-block'}>
                            <form onSubmit={ToFilter}>
                                {/*<p className={!is_main ? 'd-none' : ''}><span>{t('search')}:</span>*/}
                                {/*    <FormControl variant="outlined">*/}
                                {/*        <InputLabel htmlFor="outlined-adornment-password">{t('search')}</InputLabel>*/}
                                {/*        <OutlinedInput*/}
                                {/*            id="outlined-adornment-password"*/}
                                {/*            type={'text'}*/}
                                {/*            endAdornment={*/}
                                {/*                <InputAdornment position="end">*/}
                                {/*                    <IconButton aria-label="toggle search" edge="end" value={search} onChange={(event) => {*/}
                                {/*                            setSearch(event.target.value)*/}
                                {/*                        }}>*/}
                                {/*                        <SearchIcon/>*/}
                                {/*                    </IconButton>*/}
                                {/*                </InputAdornment>*/}
                                {/*            }*/}
                                {/*            label={t('search')}*/}
                                {/*        />*/}
                                {/*    </FormControl>*/}
                                {/*</p>*/}
                                <p><span>{t('report_year')}:</span>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            minDate={new Date("2022-01-01")}
                                            views={['year']}
                                            label={t('report_year')}
                                            value={year}
                                            onChange={(newValue) => {
                                                setYear(new Date(newValue).getFullYear().toString());
                                            }}
                                            renderInput={(params) => <TextField {...params}/>}
                                        />
                                    </LocalizationProvider>
                                </p>
                                <p><span>{t('report_month')}:</span>
                                    <Box sx={{minWidth: 200}}>
                                        <FormControl fullWidth>
                                            <InputLabel
                                                id="demo-simple-select-label">{t('report_month')}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={month}
                                                label={t('report_month')}
                                                onChange={(e) => {
                                                    setMonth(e.target.value)
                                                }}
                                            >
                                                <MenuItem value={1}>{t('quarter')}</MenuItem>
                                                <MenuItem value={2}>{t('semi_annual')}</MenuItem>
                                                <MenuItem value={3}>{t('nine_month')}</MenuItem>
                                                <MenuItem value={4}>{t('yearly')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box></p>
                                <Button type={'submit'} className={'filter-btn'} variant="contained"
                                        title={t('filter')}
                                        color={"primary"}
                                >{t('filter')}</Button>
                            </form>
                        </div>
                        <TabContext value={tab}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={(e,val)=>setTab(val)} aria-label="lab API tabs example">
                                    <Tab value="one" label={t('no_send')} />
                                    <Tab value="two" label={t('review')} />
                                    <Tab value="three" label={t('canceled')} />
                                    <Tab value="four" label={t('accepted')} />
                                </TabList>
                            </Box>
                            <TabPanel value="one">
                                {stats.length && stats.filter(item => item.status === 1).length ? <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        {is_main ?
                                            <th scope="col">{t('title_company')}</th> : ''}
                                        <th scope="col">{t('type')}</th>
                                        <th scope="col">{t('report_year')}</th>
                                        <th scope="col">{t('report_month')}</th>
                                        <th scope="col">{t('status')}</th>
                                        <th scope="col"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {stats.filter(item => item.status === 1).map((item, index) => <tr key={index}>
                                        {is_main ? <th scope="row">
                                            <Checkbox {...label} onClick={() => fileId(item.id)}/>
                                        </th> : <th scope="row">{index + 1}</th>}

                                        {is_main ? <td>{item.user.user_name}</td> : ''}
                                        <td>{item.type === 1 ? t('type_report') : t('type_reply_report')}</td>
                                        <td>{item.year}</td>
                                        <td>{monthSelect(item.month)}</td>
                                        <td><span className={'status' + item.status}>{statusSelect(item.status)}</span></td>
                                        {!item.is_send ? <td>
                                            <Button variant="contained" title={"Ko'rish"} color={"primary"}
                                                    endIcon={<VisibilityIcon/>}
                                                    onClick={() => {
                                                        toView(item)
                                                    }}
                                            />
                                            <Button variant="contained" title={"O'zgartirish"} color={"info"}
                                                    endIcon={<EditIcon/>}
                                                    onClick={() => toEdit(item)}/>
                                            <Button variant="contained" title={"O'chirish"} color={"error"}
                                                    endIcon={<DeleteIcon/>}
                                                    onClick={() => {
                                                        toDelete(item)
                                                    }}
                                            />
                                        </td> : is_main === true && (item.status !== 3 && item.status !== 4 && item.status !== 1) ?
                                            <td>
                                                <Button variant="contained" title={"Ko'rish"} color={"primary"}
                                                        endIcon={<VisibilityIcon/>}
                                                        onClick={() => {
                                                            toView(item)
                                                        }}/>
                                                <Button variant="contained" title={"O'zgartirish"} color={"info"}
                                                        endIcon={<EditIcon/>}
                                                        onClick={() => toEdit(item)}/>
                                            </td> : <td>
                                                <Button variant="contained" title={"Ko'rish"} color={"primary"}
                                                        endIcon={<VisibilityIcon/>}
                                                        onClick={() => {
                                                            toView(item)
                                                        }}/>
                                            </td>}
                                    </tr>)}
                                    </tbody>
                                </table> : <div className={'no-infos'}>{t('no_info')}</div>}
                                {is_main && tab !=='one' ? <Button onClick={saveFile} variant="contained" title={t('download_reports')} color={"primary"}>
                                    {t('download_reports')}
                                </Button> : '' }
                            </TabPanel>
                            <TabPanel value="two">
                                {stats.length && stats.filter(item => item.status === 2).length ? <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        {is_main ?
                                            <th scope="col">{t('title_company')}</th> : ''}
                                        <th scope="col">{t('type')}</th>
                                        <th scope="col">{t('report_year')}</th>
                                        <th scope="col">{t('report_month')}</th>
                                        <th scope="col">{t('status')}</th>
                                        <th scope="col"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {stats.filter(item => item.status === 2).map((item, index) => <tr key={index}>
                                        {is_main ? <th scope="row">
                                            <Checkbox {...label} onClick={() => fileId(item.id)}/>
                                        </th> : <th scope="row">{index + 1}</th>}

                                        {is_main ? <td>{item.user.user_name}</td> : ''}
                                        <td>{item.type === 1 ? t('type_report') : t('type_reply_report')}</td>
                                        <td>{item.year}</td>
                                        <td>{monthSelect(item.month)}</td>
                                        <td><span className={'status' + item.status}>{statusSelect(item.status)}</span><br/>{item.true_time ? t('true_time') : t('notrue_time')}</td>
                                        {!item.is_send ? <td>
                                            <Button variant="contained" title={"Ko'rish"} color={"primary"}
                                                    endIcon={<VisibilityIcon/>}
                                                    onClick={() => {
                                                        toView(item)
                                                    }}
                                            />
                                            <Button variant="contained" title={"O'zgartirish"} color={"info"}
                                                    endIcon={<EditIcon/>}
                                                    onClick={() => toEdit(item)}/>
                                            <Button variant="contained" title={"O'chirish"} color={"error"}
                                                    endIcon={<DeleteIcon/>}
                                                    onClick={() => {
                                                        toDelete(item)
                                                    }}
                                            />
                                        </td> : is_main === true && (item.status !== 3 && item.status !== 4 && item.status !== 1) ?
                                            <td>
                                                <Button variant="contained" title={"Ko'rish"} color={"primary"}
                                                        endIcon={<VisibilityIcon/>}
                                                        onClick={() => {
                                                            toView(item)
                                                        }}/>
                                                <Button variant="contained" title={"O'zgartirish"} color={"info"}
                                                        endIcon={<EditIcon/>}
                                                        onClick={() => toEdit(item)}/>
                                            </td> : <td>
                                                <Button variant="contained" title={"Ko'rish"} color={"primary"}
                                                        endIcon={<VisibilityIcon/>}
                                                        onClick={() => {
                                                            toView(item)
                                                        }}/>
                                            </td>}
                                    </tr>)}
                                    </tbody>
                                </table> : <div className={'no-infos'}>{t('no_info')}</div>}
                                {is_main && tab !=='one' ? <Button onClick={saveFile} variant="contained" title={t('download_reports')} color={"primary"}>
                                    {t('download_reports')}
                                </Button> : '' }
                            </TabPanel>
                            <TabPanel value="three">
                                {stats.length && stats.filter(item => item.status === 3).length ? <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        {is_main ?
                                            <th scope="col">{t('title_company')}</th> : ''}
                                        <th scope="col">{t('type')}</th>
                                        <th scope="col">{t('report_year')}</th>
                                        <th scope="col">{t('report_month')}</th>
                                        <th scope="col">{t('status')}</th>
                                        <th scope="col"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {stats.filter(item => item.status === 3).map((item, index) => <tr key={index}>
                                        {is_main ? <th scope="row">
                                            <Checkbox {...label} onClick={() => fileId(item.id)}/>
                                        </th> : <th scope="row">{index + 1}</th>}

                                        {is_main ? <td>{item.user.user_name}</td> : ''}
                                        <td>{item.type === 1 ? t('type_report') : t('type_reply_report')}</td>
                                        <td>{item.year}</td>
                                        <td>{monthSelect(item.month)}</td>
                                        <td><span className={'status' + item.status}>{statusSelect(item.status)}</span><br/>{item.true_time ? t('true_time') : t('notrue_time')}</td>
                                        {!item.is_send ? <td>
                                            <Button variant="contained" title={"Ko'rish"} color={"primary"}
                                                    endIcon={<VisibilityIcon/>}
                                                    onClick={() => {
                                                        toView(item)
                                                    }}
                                            />
                                            <Button variant="contained" title={"O'zgartirish"} color={"info"}
                                                    endIcon={<EditIcon/>}
                                                    onClick={() => toEdit(item)}/>
                                            <Button variant="contained" title={"O'chirish"} color={"error"}
                                                    endIcon={<DeleteIcon/>}
                                                    onClick={() => {
                                                        toDelete(item)
                                                    }}
                                            />
                                        </td> : is_main === true && (item.status !== 3 && item.status !== 4 && item.status !== 1) ?
                                            <td>
                                                <Button variant="contained" title={"Ko'rish"} color={"primary"}
                                                        endIcon={<VisibilityIcon/>}
                                                        onClick={() => {
                                                            toView(item)
                                                        }}/>
                                                <Button variant="contained" title={"O'zgartirish"} color={"info"}
                                                        endIcon={<EditIcon/>}
                                                        onClick={() => toEdit(item)}/>
                                            </td> : <td>
                                                <Button variant="contained" title={"Ko'rish"} color={"primary"}
                                                        endIcon={<VisibilityIcon/>}
                                                        onClick={() => {
                                                            toView(item)
                                                        }}/>
                                            </td>}
                                    </tr>)}
                                    </tbody>
                                </table> : <div className={'no-infos'}>{t('no_info')}</div>}
                                {is_main && tab !=='one' ? <Button onClick={saveFile} variant="contained" title={t('download_reports')} color={"primary"}>
                                    {t('download_reports')}
                                </Button> : '' }
                            </TabPanel>
                            <TabPanel value="four">
                                {stats.length && stats.filter(item => item.status === 4).length ? <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        {is_main ?
                                            <th scope="col">{t('title_company')}</th> : ''}
                                        <th scope="col">{t('type')}</th>
                                        <th scope="col">{t('report_year')}</th>
                                        <th scope="col">{t('report_month')}</th>
                                        <th scope="col">{t('status')}</th>
                                        <th scope="col"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {stats.filter(item => item.status === 4).map((item, index) => <tr key={index}>
                                        {is_main ? <th scope="row">
                                            <Checkbox {...label} onClick={() => fileId(item.id)}/>
                                        </th> : <th scope="row">{index + 1}</th>}

                                        {is_main ? <td>{item.user.user_name}</td> : ''}
                                        <td>{item.type === 1 ? t('type_report') : t('type_reply_report')}</td>
                                        <td>{item.year}</td>
                                        <td>{monthSelect(item.month)}</td>
                                        <td><span className={'status' + item.status}>{statusSelect(item.status)}</span><br/>{item.true_time ? t('true_time') : t('notrue_time')}</td>
                                        {!item.is_send ? <td>
                                            <Button variant="contained" title={"Ko'rish"} color={"primary"}
                                                    endIcon={<VisibilityIcon/>}
                                                    onClick={() => {
                                                        toView(item)
                                                    }}
                                            />
                                            <Button variant="contained" title={"O'zgartirish"} color={"info"}
                                                    endIcon={<EditIcon/>}
                                                    onClick={() => toEdit(item)}/>
                                            <Button variant="contained" title={"O'chirish"} color={"error"}
                                                    endIcon={<DeleteIcon/>}
                                                    onClick={() => {
                                                        toDelete(item)
                                                    }}
                                            />
                                        </td> : is_main === true && (item.status !== 3 && item.status !== 4 && item.status !== 1) ?
                                            <td>
                                                <Button variant="contained" title={"Ko'rish"} color={"primary"}
                                                        endIcon={<VisibilityIcon/>}
                                                        onClick={() => {
                                                            toView(item)
                                                        }}/>
                                                <Button variant="contained" title={"O'zgartirish"} color={"info"}
                                                        endIcon={<EditIcon/>}
                                                        onClick={() => toEdit(item)}/>
                                            </td> : <td>
                                                <Button variant="contained" title={"Ko'rish"} color={"primary"}
                                                        endIcon={<VisibilityIcon/>}
                                                        onClick={() => {
                                                            toView(item)
                                                        }}/>
                                            </td>}
                                    </tr>)}
                                    </tbody>
                                </table> : <div className={'no-infos'}>{t('no_info')}</div>}
                                {is_main && tab !=='one' ? <Button onClick={saveFile} variant="contained" title={t('download_reports')} color={"primary"}>
                                    {t('download_reports')}
                                </Button> : '' }
                            </TabPanel>
                        </TabContext>


                    </div>
                </div>
                <Copyright/>
            </Box>
        </div>
    </div>
}

export default connect(props => props.stats, {
    getStats,
    deleteStats,
    getAllVerifyResponse,
    messageReset,
})(Stats)