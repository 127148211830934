import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useEffect, useState} from "react";
import {getAllResponse, getAllVerifyResponse} from "../store/logIn";
import {connect} from "react-redux";
import {toast, ToastContainer} from "react-toastify";
import Header from "../blocks/header";
import {useNavigate} from "react-router-dom";
import Copyright from "../blocks/copyright";
import {useTranslation} from "react-i18next";

const theme = createTheme();

function SignIn(props) {
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()

    useEffect(()=>{
        const token = localStorage.getItem('token');
        const login_str = JSON.parse(localStorage.getItem('login'));
        const password_str = JSON.parse(localStorage.getItem('password'));
        if (login_str && password_str) {
            setLogin(login_str);
            setPassword(password_str);
        }
    }, [])

    useEffect(() => {
        if (props.error) {
            toast.error('Xatolik!', {theme: 'colored'})
        } else if (props.login.access) {
            localStorage.setItem("token", JSON.stringify(props.login.access))
        }
        if (props.verify){
            navigate("/dashboard/");
        }
    }, [props])


    const [login, setLogin] = useState(undefined)
    const [password, setPassword] = useState(undefined)
    const [remember, setRemember] = useState(undefined)

    if (remember === 'remember') {
        localStorage.setItem("login", JSON.stringify(login));
        localStorage.setItem("password", JSON.stringify(password));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const login_username = data.get('username')
        const login_password = data.get('password')
        setLogin(login_username)
        setPassword(login_password)
        setRemember(data.get('is_remember'))
        const sendData = {
            "username": login_username,
            "password": login_password
        }
        props.getAllResponse(sendData)
    };

    function changeLang(lang) {
        i18n.changeLanguage(lang)
    }

    return (
        <ThemeProvider theme={theme}>
            <Header/>
            {props.verify===false ?
                <Container component="main" maxWidth="xs">
                    <CssBaseline/>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            {t('go_to_cabinet')}
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label={t('username')}
                                name="username"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={t('password')}
                                type="password"
                                id="password"
                            />
                            <FormControlLabel
                                control={<Checkbox name={'is_remember'} value="remember" color="primary"/>}
                                label={t('remember')}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                {t('login')}
                            </Button>
                        </Box>
                    </Box>
                    <div className={'login-page'}>
                        <ul className={'lang-menu'}>
                            <li className={`${i18n.language === 'oz' ? 'active' : ''}`}
                                onClick={() => changeLang('oz')}>O'zb
                            </li>
                            <li className={`${i18n.language === 'uz' ? 'active' : ''}`}
                                onClick={() => changeLang('uz')}>Ўзб
                            </li>
                            <li className={`${i18n.language === 'ru' ? 'active' : ''}`}
                                onClick={() => changeLang('ru')}>Рус
                            </li>
                        </ul>
                        <Copyright sx={{mt: 8, mb: 4}}/>
                    </div>
                </Container> : ''}
            <ToastContainer/>
        </ThemeProvider>
    );
}

export default connect(props => props.login, {getAllResponse, getAllVerifyResponse})(SignIn)