import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import Header from "../blocks/header";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {connect} from "react-redux";
import {messageReset} from "../store/workers";
import {getAllVerifyResponse} from "../store/logIn";
import Dashboard from "../blocks/dashboard";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import Copyright from "../blocks/copyright";
import {useTranslation} from "react-i18next";
import {editSaveStats, getStat} from "../store/stats";
import {DatePicker} from "@mui/x-date-pickers";
import {useNavigate, useParams} from "react-router-dom";
import CancelModalStat from "../blocks/cancelModalStat";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


function EditStat(props) {

    const is_main = JSON.parse(localStorage.getItem('is_main'));
    const {t, i18n} = useTranslation()
    const [isSend, setIsSend] = useState(false);
    const [is_status, setIsStatus] = useState(2);
    const [data, setData] = useState(false);
    const [comment, setComment] = useState('');
    const [open, setOpen] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [hasError1, setHasError1] = useState(false);
    const [report, setReport] = useState('');
    const [year, setYear] = useState(new Date("2023-01-01"));
    const [period, setPeriod] = useState('');
    const [ktut, setKtut] = useState('');
    const [mhobt, setMhobt] = useState('');
    const [xxtut, setXxtut] = useState('');
    const [address, setAddress] = useState('');

    const [begin_year, setBeginYear] = useState(['', '']);
    const [new_year, setNewYear] = useState(['', '']);
    const [dissimals, setDissimals] = useState(['', '']);
    const [end_reporting, setEndReporting] = useState(['', '']);
    const [group1all, setGroup1All] = useState(['', '']);
    const [group1men, setGroup1Men] = useState(['', '']);
    const [group1women, setGroup1Women] = useState(['', '']);
    const [group2all, setGroup2All] = useState(['', '']);
    const [group2men, setGroup2Men] = useState(['', '']);
    const [group2women, setGroup2Women] = useState(['', '']);
    const [group3all, setGroup3All] = useState(['', '']);
    const [group3men, setGroup3Men] = useState(['', '']);
    const [group3women, setGroup3Women] = useState(['', '']);
    const [persons_to16all, setPersonTo16All] = useState(['', '']);
    const [persons_to16men, setPersonTo16Men] = useState(['', '']);
    const [persons_to16women, setPersonTo16Women] = useState(['', '']);
    const [to18, setTo18] = useState(['', '']);
    const [from18to30, setFrom18To30] = useState(['', '']);
    const [from30to50, setFrom30To50] = useState(['', '']);
    const [from50to60, setFrom50To60] = useState(['', '']);
    const [from60to75, setFrom60To75] = useState(['', '']);

    const [from75, setFrom75] = useState(['', '']);
    const [uzbek, setUzbek] = useState(['', '']);
    const [other_nationalities, setOtherNation] = useState(['', '']);
    const [highly, setHighly] = useState(['', '']);
    const [secondary_special, setSecondarySpecial] = useState(['', '']);
    const [secondary, setSecondary] = useState(['', '']);
    const [first_education, setFirstEducation] = useState(['', '']);
    const [no_education, setNoEducation] = useState(['', '']);
    const [know_brail, setKnowBrail] = useState(['', '']);
    const [know_computer, setKnowComputer] = useState(['', '']);

    const [begin_year_social, setBeginYearSocial] = useState(['', '']);
    const [new_year_social, setNewYearSocial] = useState(['', '']);
    const [dissimals_social, setDissimalsSocial] = useState(['', '']);
    const [end_reporting_social, setEndReportingSocial] = useState(['', '']);
    const [group1_socialall, setGroup1SocialAll] = useState(['', '']);
    const [group1_socialmen, setGroup1SocialMen] = useState(['', '']);
    const [group1_socialwomen, setGroup1SocialWomen] = useState(['', '']);
    const [group2_socialall, setGroup2SocialAll] = useState(['', '']);
    const [group2_socialmen, setGroup2SocialMen] = useState(['', '']);
    const [group2_socialwomen, setGroup2SocialWomen] = useState(['', '']);
    const [group3_socialall, setGroup3SocialAll] = useState(['', '']);
    const [group3_socialmen, setGroup3SocialMen] = useState(['', '']);
    const [group3_socialwomen, setGroup3SocialWomen] = useState(['', '']);

    const [healthy_members, setHealthyMembers] = useState(['', '']);
    const [omember_disabilities, setOMemberDisabilities] = useState(['', '']);
    const [from18to30_social, setFrom18To30Social] = useState(['', '']);
    const [from30to50_social, setFrom30To50Social] = useState(['', '']);
    const [from50to60_social, setFrom50To60Social] = useState(['', '']);
    const [from60to75_social, setFrom60To75Social] = useState(['', '']);
    const [from75_social, setFrom75Social] = useState(['', '']);
    const [uzbek_social, setUzbekSocial] = useState(['', '']);
    const [other_nationalities_social, setOtherNationSocial] = useState(['', '']);
    const [highly_social, setHighlySocial] = useState(['', '']);
    const [secondary_special_social, setSecondarySpecialSocial] = useState(['', '']);
    const [secondary_social, setSecondarySocial] = useState(['', '']);
    const [first_education_social, setFirstEducationSocial] = useState(['', '']);
    const [no_education_social, setNoEducationSocial] = useState(['', '']);
    const [know_brail_social, setKnowBrailSocial] = useState(['', '']);
    const [know_computer_social, setKnowComputerSocial] = useState(['', '']);


    const [blind_workers, setBlindWorkers] = useState(['', '']);
    const [group1_workers, setGroup1Workers] = useState(['', '']);
    const [group2_workers, setGroup2Workers] = useState(['', '']);
    const [group3_workers, setGroup3Workers] = useState(['', '']);
    const [koj_workers, setKojWorkers] = useState(['', '']);
    const [koj_workers_social, setKojWorkersSocial] = useState(['', '']);
    const [koj_workers_nosocial, setKojWorkersNoSocial] = useState(['', '']);
    const [new_workers, setNewWorkers] = useState(['', '']);
    const [new_koj_workers, setNewKojWorkers] = useState(['', '']);
    const [new_koj_social_workers, setNewKojSocialWorkers] = useState(['', '']);
    const [new_koj_nosocial_workers, setNewKojNoSocialWorkers] = useState(['', '']);

    const [all_learners, setAllLearners] = useState('');
    const [learners_highly, setLearnersHighly] = useState('');
    const [learners_special_secondary, setSpecialSecondary] = useState('');
    const [special_educational, setSpecialEducational] = useState('');
    const [learners_state, setAllState] = useState('');
    const [learners_general, setAllGeneral] = useState('');
    const [learners_ind, setAllInd] = useState('');
    const [students_preschool, setStudentsPreschool] = useState('');

    const [to_holiday_social_all, setHolidSocialAll] = useState(['', '']);
    const [to_holiday_other_all, setHolidOtherAll] = useState(['', '']);

    const [sponsorships, setSponsors] = useState(['', '']);

    const [by_disability1_error, setByDis1Error] = useState([false, ''])
    const [by_disability2_error, setByDis2Error] = useState([false, ''])
    const [by_age1_error, setByAge1Error] = useState([false, ''])
    const [by_age2_error, setByAge2Error] = useState([false, ''])
    const [by_nationality1_error, setByNat1Error] = useState([false, ''])
    const [by_nationality2_error, setByNat2Error] = useState([false, ''])
    const [by_education1_error, setByEdu1Error] = useState([false, ''])
    const [by_education2_error, setByEdu2Error] = useState([false, ''])

    const [by_sdisability1_error, setBysDis1Error] = useState([false, ''])
    const [by_sdisability2_error, setBysDis2Error] = useState([false, ''])

    const [by_sage1_error, setBysAge1Error] = useState([false, ''])
    const [by_sage2_error, setBysAge2Error] = useState([false, ''])
    const [by_snationality1_error, setBysNat1Error] = useState([false, ''])
    const [by_snationality2_error, setBysNat2Error] = useState([false, ''])
    const [by_seducation1_error, setBysEdu1Error] = useState([false, ''])
    const [by_seducation2_error, setBysEdu2Error] = useState([false, ''])

    const [field_of_culture, setFieldCulture] = useState(['', ''])
    const [public_education, setPublicEducation] = useState(['', ''])
    const [higher_education, setHigherEducation] = useState(['', ''])
    const [special_education, setSpecialEducation] = useState(['', ''])
    const [other_areas, setOtherAreas] = useState(['', ''])
    const [community_system, setCommunitySystem] = useState(['', ''])
    const [other_disabilities, setOtherDisabilities] = useState(['', ''])
    const [healthy_emps, setHealthyEmps] = useState(['', ''])

    const [field_of_culture_new, setFieldCultureNew] = useState(['', ''])
    const [public_education_new, setPublicEducationNew] = useState(['', ''])
    const [higher_education_new, setHigherEducationNew] = useState(['', ''])
    const [special_education_new, setSpecialEducationNew] = useState(['', ''])
    const [other_areas_new, setOtherAreasNew] = useState(['', ''])

    const [uzkoj_yangyol, setUzKojYangyol] = useState(['', ''])
    const [uzkoj_kosonsoy, setUzKojKosonsoy] = useState(['', ''])
    const [uzkoj_other, setUzKojOther] = useState(['', ''])
    const [uzkoj_outside_centers, setUzKojOutsideCenters] = useState(['', ''])
    const [uzkoj_outside_home, setUzKojOutsideHome] = useState(['', ''])
    const [prophylactics_outside, setProphylacticsOutside] = useState(['', ''])
    const [children_camps, setChildrenCamps] = useState(['', ''])

    const [uzkoj_yangyol_other, setUzKojYangyolOther] = useState(['', ''])
    const [uzkoj_kosonsoy_other, setUzKojKosonsoyOther] = useState(['', ''])
    const [uzkoj_other_other, setUzKojOtherOther] = useState(['', ''])
    const [uzkoj_outside_centers_other, setUzKojOutsideCentersOther] = useState(['', ''])
    const [uzkoj_outside_home_other, setUzKojOutsideHomeOther] = useState(['', ''])
    const [prophylactics_outside_other, setProphylacticsOutsideOther] = useState(['', ''])
    const [children_camps_other, setChildrenCampsOther] = useState(['', ''])


    const [total_amount, setTotalAmount] = useState(['', ''])
    const [membership_fees, setMembershipFees] = useState(['', ''])
    const [amount_appropriations, setAmountAppropriations] = useState(['', ''])
    const [state_budget, setStateBudget] = useState(['', ''])
    const [sponsorship_donation, setSponsorshipDonation] = useState(['', ''])
    const [grant_funds, setGrantFunds] = useState(['', ''])
    const [physical_assistance, setPhysicalAssistance] = useState(['', ''])
    const [financial_assistance, setFinancialAssistance] = useState(['', ''])
    const [financial_support, setFinancialSupport] = useState(['', ''])
    const [inkind_help, setInkendHelp] = useState(['', ''])
    const [material_aid, setMaterialAid] = useState(['', ''])

    const params = useParams()

    useEffect(()=>{
        props.getStat(params.id)
    }, [])

    useEffect(()=>{
        setReport(props.stats_single.type)
        setYear(new Date(props.stats_single.year))
        setPeriod(props.stats_single.month)
        setIsSend(props.stats_single.is_send)
        setKtut(props.stats_single.ktut)
        setMhobt(props.stats_single.mhobt)
        setXxtut(props.stats_single.xxtut)
        setAddress(props.stats_single.address)
        setBeginYear([props.stats_single.f_begin_year,props.stats_single.l_begin_year])
        setNewYear([props.stats_single.f_new_year,props.stats_single.l_new_year])
        setDissimals([props.stats_single.f_dissimals,props.stats_single.l_dissimals])
        setEndReporting([props.stats_single.f_end_reporting,props.stats_single.l_end_reporting])
        setGroup1All([props.stats_single.group1all,props.stats_single.l_group1all])
        setGroup1Men([props.stats_single.f_group1men,props.stats_single.l_group1men])
        setGroup1Women([props.stats_single.f_group1women,props.stats_single.l_group1women])
        setGroup2All([props.stats_single.group2all,props.stats_single.l_group2all])
        setGroup2Men([props.stats_single.f_group2men,props.stats_single.l_group2men])
        setGroup2Women([props.stats_single.f_group2women,props.stats_single.l_group2women])
        setGroup3All([props.stats_single.group3all,props.stats_single.l_group3all])
        setGroup3Men([props.stats_single.f_group3men,props.stats_single.l_group3men])
        setGroup3Women([props.stats_single.f_group3women,props.stats_single.l_group3women])
        setPersonTo16All([props.stats_single.persons_to16all,props.stats_single.l_persons_to16all])
        setPersonTo16Men([props.stats_single.f_persons_to16men,props.stats_single.l_persons_to16men])
        setPersonTo16Women([props.stats_single.f_persons_to16women,props.stats_single.l_persons_to16women])
        setTo18([props.stats_single.f_to18,props.stats_single.l_to18])
        setFrom18To30([props.stats_single.f_from18to30,props.stats_single.l_from18to30])
        setFrom30To50([props.stats_single.f_from30to50,props.stats_single.l_from30to50])
        setFrom50To60([props.stats_single.f_from50to60,props.stats_single.l_from50to60])
        setFrom60To75([props.stats_single.f_from60to75,props.stats_single.l_from60to75])
        setFrom75([props.stats_single.f_from75,props.stats_single.l_from75])
        setUzbek([props.stats_single.f_uzbek,props.stats_single.l_uzbek])
        setOtherNation([props.stats_single.f_other_nationalities,props.stats_single.l_other_nationalities])
        setHighly([props.stats_single.f_highly,props.stats_single.l_highly])
        setSecondarySpecial([props.stats_single.f_secondary_special,props.stats_single.l_secondary_special])
        setSecondary([props.stats_single.f_secondary,props.stats_single.l_secondary])
        setFirstEducation([props.stats_single.f_first_education,props.stats_single.l_first_education])
        setNoEducation([props.stats_single.f_no_education,props.stats_single.l_no_education])
        setKnowBrail([props.stats_single.f_know_brail,props.stats_single.l_know_brail])
        setKnowComputer([props.stats_single.f_know_computer,props.stats_single.l_know_computer])
        setBeginYearSocial([props.stats_single.f_begin_year_social,props.stats_single.l_begin_year_social])
        setNewYearSocial([props.stats_single.f_new_year_social,props.stats_single.l_new_year_social])
        setDissimalsSocial([props.stats_single.f_dissimals_social,props.stats_single.l_dissimals_social])
        setEndReportingSocial([props.stats_single.f_end_reporting_social,props.stats_single.l_end_reporting_social])
        setGroup1SocialAll([props.stats_single.group1_socialall,props.stats_single.l_group1_socialall])
        setGroup1SocialMen([props.stats_single.f_group1_socialmen,props.stats_single.l_group1_socialmen])
        setGroup1SocialWomen([props.stats_single.f_group1_socialwomen,props.stats_single.l_group1_socialwomen])
        setGroup2SocialAll([props.stats_single.group2_socialall,props.stats_single.l_group2_socialall])
        setGroup2SocialMen([props.stats_single.f_group2_socialmen,props.stats_single.l_group2_socialmen])
        setGroup2SocialWomen([props.stats_single.f_group2_socialwomen,props.stats_single.l_group2_socialwomen])
        setGroup3SocialAll([props.stats_single.group3_socialall,props.stats_single.l_group3_socialall])
        setGroup3SocialMen([props.stats_single.f_group3_socialmen,props.stats_single.l_group3_socialmen])
        setGroup3SocialWomen([props.stats_single.f_group3_socialwomen,props.stats_single.l_group3_socialwomen])
        setHealthyMembers([props.stats_single.healthy_members,props.stats_single.l_healthy_members])
        setOMemberDisabilities([props.stats_single.omember_disabilities,props.stats_single.l_omember_disabilities])
        setFrom18To30Social([props.stats_single.f_from18to30_social,props.stats_single.l_from18to30_social])
        setFrom30To50Social([props.stats_single.f_from30to50_social,props.stats_single.l_from30to50_social])
        setFrom50To60Social([props.stats_single.f_from50to60_social,props.stats_single.l_from50to60_social])
        setFrom60To75Social([props.stats_single.f_from60to75_social,props.stats_single.l_from60to75_social])
        setFrom75Social([props.stats_single.f_from75_social,props.stats_single.l_from75_social])
        setUzbekSocial([props.stats_single.f_uzbek_social,props.stats_single.l_uzbek_social])
        setOtherNationSocial([props.stats_single.f_other_nationalities_social,props.stats_single.l_other_nationalities_social])
        setHighlySocial([props.stats_single.f_highly_social,props.stats_single.l_highly_social])
        setSecondarySpecialSocial([props.stats_single.f_secondary_special_social,props.stats_single.l_secondary_special_social])
        setSecondarySocial([props.stats_single.f_secondary_social,props.stats_single.l_secondary_social])
        setFirstEducationSocial([props.stats_single.f_first_education_social,props.stats_single.l_first_education_social])
        setNoEducationSocial([props.stats_single.f_no_education_social,props.stats_single.l_no_education_social])
        setKnowBrailSocial([props.stats_single.f_know_brail_social,props.stats_single.l_know_brail_social])
        setKnowComputerSocial([props.stats_single.f_know_computer_social,props.stats_single.l_know_computer_social])
        setBlindWorkers([props.stats_single.f_blind_workers,props.stats_single.l_blind_workers])
        setGroup1Workers([props.stats_single.f_group1_workers,props.stats_single.l_group1_workers])
        setGroup2Workers([props.stats_single.f_group2_workers,props.stats_single.l_group2_workers])
        setGroup3Workers([props.stats_single.f_group3_workers,props.stats_single.l_group3_workers])
        setKojWorkers([props.stats_single.f_koj_workers,props.stats_single.l_koj_workers])
        setKojWorkersSocial([props.stats_single.f_koj_workers_social,props.stats_single.l_koj_workers_social])
        setKojWorkersNoSocial([props.stats_single.f_koj_workers_nosocial,props.stats_single.l_koj_workers_nosocial])
        setNewWorkers([props.stats_single.f_new_workers,props.stats_single.l_new_workers])
        setNewKojWorkers([props.stats_single.f_new_koj_workers,props.stats_single.l_new_koj_workers])
        setNewKojSocialWorkers([props.stats_single.f_new_koj_social_workers,props.stats_single.l_new_koj_social_workers])
        setNewKojNoSocialWorkers([props.stats_single.f_new_koj_nosocial_workers,props.stats_single.l_new_koj_nosocial_workers])
        setAllLearners(props.stats_single.all_learners)
        setLearnersHighly(props.stats_single.learners_highly)
        setSpecialEducational(props.stats_single.special_educational)
        setSpecialSecondary(props.stats_single.learners_special_secondary)
        setAllState(props.stats_single.learners_state)
        setAllGeneral(props.stats_single.learners_general)
        setAllInd(props.stats_single.learners_ind)
        setStudentsPreschool(props.stats_single.students_preschool)

        setUzKojYangyol([props.stats_single.f_uzkoj_yangyol,props.stats_single.l_uzkoj_yangyol])
        setUzKojKosonsoy([props.stats_single.f_uzkoj_kosonsoy,props.stats_single.l_uzkoj_kosonsoy])
        setUzKojOther([props.stats_single.f_uzkoj_other,props.stats_single.l_uzkoj_other])
        setUzKojOutsideCenters([props.stats_single.f_uzkoj_outside_centers,props.stats_single.l_uzkoj_outside_centers])
        setUzKojOutsideHome([props.stats_single.f_uzkoj_outside_home,props.stats_single.l_uzkoj_outside_home])
        setProphylacticsOutside([props.stats_single.f_prophylactics_outside,props.stats_single.l_prophylactics_outside])
        setChildrenCamps([props.stats_single.f_children_camps,props.stats_single.l_children_camps])
        setHolidSocialAll([props.stats_single.f_to_holiday_social_all,props.stats_single.l_to_holiday_social_all])

        setUzKojYangyolOther([props.stats_single.f_uzkoj_yangyol_other,props.stats_single.l_uzkoj_yangyol_other])
        setUzKojKosonsoyOther([props.stats_single.f_uzkoj_kosonsoy_other,props.stats_single.l_uzkoj_kosonsoy_other])
        setUzKojOtherOther([props.stats_single.f_uzkoj_other_other,props.stats_single.l_uzkoj_other_other])
        setUzKojOutsideCentersOther([props.stats_single.f_uzkoj_outside_centers_other,props.stats_single.l_uzkoj_outside_centers_other])
        setUzKojOutsideHomeOther([props.stats_single.f_uzkoj_outside_home_other,props.stats_single.l_uzkoj_outside_home_other])
        setProphylacticsOutsideOther([props.stats_single.f_prophylactics_outside_other,props.stats_single.l_prophylactics_outside_other])
        setChildrenCampsOther([props.stats_single.f_children_camps_other,props.stats_single.l_children_camps_other])
        setHolidOtherAll([props.stats_single.f_to_holiday_other_all,props.stats_single.l_to_holiday_other_all])
        
        setFieldCulture([props.stats_single.f_field_of_culture,props.stats_single.l_field_of_culture])
        setPublicEducation([props.stats_single.f_public_education,props.stats_single.l_public_education])
        setHigherEducation([props.stats_single.f_higher_education,props.stats_single.l_higher_education])
        setSpecialEducation([props.stats_single.f_special_education,props.stats_single.l_special_education])
        setOtherAreas([props.stats_single.f_other_areas,props.stats_single.l_other_areas])
        setCommunitySystem([props.stats_single.f_community_system,props.stats_single.l_community_system])
        setOtherDisabilities([props.stats_single.f_other_disabilities,props.stats_single.l_other_disabilities])
        setHealthyEmps([props.stats_single.f_healthy_emps,props.stats_single.l_healthy_emps])

        setFieldCultureNew([props.stats_single.f_field_of_culture_new,props.stats_single.l_field_of_culture_new])
        setPublicEducationNew([props.stats_single.f_public_education_new,props.stats_single.l_public_education_new])
        setHigherEducationNew([props.stats_single.f_higher_education_new,props.stats_single.l_higher_education_new])
        setSpecialEducationNew([props.stats_single.f_special_education_new,props.stats_single.l_special_education_new])
        setOtherAreasNew([props.stats_single.f_other_areas_new,props.stats_single.l_other_areas_new])

        setTotalAmount([props.stats_single.f_total_amount,props.stats_single.l_total_amount])
        setAmountAppropriations([props.stats_single.f_amount_appropriations,props.stats_single.l_amount_appropriations])
        setStateBudget([props.stats_single.f_state_budget,props.stats_single.l_state_budget])
        setSponsorshipDonation([props.stats_single.f_sponsorship_donation,props.stats_single.l_sponsorship_donation])
        setGrantFunds([props.stats_single.f_grant_funds,props.stats_single.l_grant_funds])
        setPhysicalAssistance([props.stats_single.f_physical_assistance,props.stats_single.l_physical_assistance])
        setMembershipFees([props.stats_single.f_membership_fees,props.stats_single.l_membership_fees])
        setSponsors([props.stats_single.f_sponsorships,props.stats_single.l_sponsorships])
        setFinancialAssistance([props.stats_single.f_financial_assistance,props.stats_single.l_financial_assistance])
        setFinancialSupport([props.stats_single.f_financial_support,props.stats_single.l_financial_support])
        setInkendHelp([props.stats_single.f_inkind_help,props.stats_single.l_inkind_help])
        setMaterialAid([props.stats_single.f_material_aid,props.stats_single.l_material_aid])

    }, [props.stats_single])

    function saveFormData(e) {
        e.preventDefault()
        if (!report) {
            setHasError(true)
        } else {
            setHasError(false)
        }
        if (!period) {
            setHasError1(true)
        } else {
            setHasError1(false)
        }
        if (by_disability1_error[0] || by_disability2_error[0] || by_age1_error[0] || by_age2_error[0] ||
            by_nationality1_error[0] || by_nationality2_error[0] || by_education1_error[0] || by_education2_error[0] ||
            by_sdisability1_error[0] || by_sdisability2_error[0] || by_sage1_error[0] || by_sage2_error[0] ||
            by_snationality1_error[0] || by_snationality2_error[0] || by_seducation1_error[0] || by_seducation2_error[0]){
            toast.error(t('save_error'), {theme: 'colored'})
        }else{
            const data = {
                "id": params.id,
                "type": report,
                "year": e.target[2].value,
                "month": period,
                "ktut": ktut,
                "mhobt": mhobt,
                "xxtut": xxtut,
                "address": address,
                "is_send": isSend,
                "all_learners": all_learners,
                "learners_highly": learners_highly,
                "learners_special_secondary": learners_special_secondary,
                "special_educational": special_educational,
                "learners_state": learners_state,
                "learners_general": learners_general,
                "learners_ind": learners_ind,
                "students_preschool": students_preschool,
                "f_begin_year": begin_year[0],
                "f_new_year": new_year[0],
                "f_dissimals": dissimals[0],
                "f_end_reporting": end_reporting[0],
                "group1all": group1all[0],
                "f_group1men": group1men[0],
                "f_group1women": group1women[0],
                "group2all": group2all[0],
                "f_group2men": group2men[0],
                "f_group2women": group2women[0],
                "group3all": group3all[0],
                "f_group3men": group3men[0],
                "f_group3women": group3women[0],
                "persons_to16all": persons_to16all[0],
                "f_persons_to16men": persons_to16men[0],
                "f_persons_to16women": persons_to16women[0],
                "f_to18": to18[0],
                "f_from18to30": from18to30[0],
                "f_from30to50": from30to50[0],
                "f_from50to60": from50to60[0],
                "f_from60to75": from60to75[0],
                "f_from75": from75[0],
                "f_uzbek": uzbek[0],
                "f_other_nationalities": other_nationalities[0],
                "f_highly": highly[0],
                "f_secondary_special": secondary_special[0],
                "f_secondary": secondary[0],
                "f_first_education": first_education[0],
                "f_no_education": no_education[0],
                "f_know_brail": know_brail[0],
                "f_know_computer": know_computer[0],
                "f_begin_year_social": begin_year_social[0],
                "f_new_year_social": new_year_social[0],
                "f_dissimals_social": dissimals_social[0],
                "f_end_reporting_social": end_reporting_social[0],
                "group1_socialall": group1_socialall[0],
                "f_group1_socialmen": group1_socialmen[0],
                "f_group1_socialwomen": group1_socialwomen[0],
                "group2_socialall": group2_socialall[0],
                "f_group2_socialmen": group2_socialmen[0],
                "f_group2_socialwomen": group2_socialwomen[0],
                "group3_socialall": group3_socialall[0],
                "f_group3_socialmen": group3_socialmen[0],
                "f_group3_socialwomen": group3_socialwomen[0],
                "healthy_members": healthy_members[0],
                "omember_disabilities": omember_disabilities[0],
                "f_from18to30_social": from18to30_social[0],
                "f_from30to50_social": from30to50_social[0],
                "f_from50to60_social": from50to60_social[0],
                "f_from60to75_social": from60to75_social[0],
                "f_from75_social": from75_social[0],
                "f_uzbek_social": uzbek_social[0],
                "f_other_nationalities_social": other_nationalities_social[0],
                "f_highly_social": highly_social[0],
                "f_secondary_special_social": secondary_special_social[0],
                "f_secondary_social": secondary_social[0],
                "f_first_education_social": first_education_social[0],
                "f_no_education_social": no_education_social[0],
                "f_know_brail_social": know_brail_social[0],
                "f_know_computer_social": know_computer_social[0],
                "f_blind_workers": blind_workers[0],
                "f_group1_workers": group1_workers[0],
                "f_group2_workers": group2_workers[0],
                "f_group3_workers": group3_workers[0],
                "f_koj_workers": koj_workers[0],
                "f_koj_workers_social": koj_workers_social[0],
                "f_koj_workers_nosocial": koj_workers_nosocial[0],
                "f_new_workers": new_workers[0],
                "f_new_koj_workers": new_koj_workers[0],
                "f_new_koj_social_workers": new_koj_social_workers[0],
                "f_new_koj_nosocial_workers": new_koj_nosocial_workers[0],


                "f_uzkoj_yangyol": uzkoj_yangyol[0],
                "f_uzkoj_kosonsoy": uzkoj_kosonsoy[0],
                "f_uzkoj_other": uzkoj_other[0],
                "f_uzkoj_outside_centers": uzkoj_outside_centers[0],
                "f_uzkoj_outside_home": uzkoj_outside_home[0],
                "f_prophylactics_outside": prophylactics_outside[0],
                "f_children_camps": children_camps[0],
                "f_to_holiday_social_all": to_holiday_social_all[0],
                "f_uzkoj_yangyol_other": uzkoj_yangyol_other[0],
                "f_uzkoj_kosonsoy_other": uzkoj_kosonsoy_other[0],
                "f_uzkoj_other_other": uzkoj_other_other[0],
                "f_uzkoj_outside_centers_other": uzkoj_outside_centers_other[0],
                "f_uzkoj_outside_home_other": uzkoj_outside_home_other[0],
                "f_prophylactics_outside_other": prophylactics_outside_other[0],
                "f_children_camps_other": children_camps_other[0],
                "f_to_holiday_other_all": to_holiday_other_all[0],

                "f_field_of_culture": field_of_culture[0],
                "f_public_education": public_education[0],
                "f_higher_education": higher_education[0],
                "f_special_education": special_education[0],
                "f_other_areas": other_areas[0],
                "f_community_system": community_system[0],
                "f_other_disabilities": other_disabilities[0],
                "f_healthy_emps": healthy_emps[0],

                "f_field_of_culture_new": field_of_culture_new[0],
                "f_public_education_new": public_education_new[0],
                "f_higher_education_new": higher_education_new[0],
                "f_special_education_new": special_education_new[0],
                "f_other_areas_new": other_areas_new[0],

                "f_total_amount": total_amount[0],
                "f_amount_appropriations": amount_appropriations[0],
                "f_state_budget": state_budget[0],
                "f_sponsorship_donation": sponsorship_donation[0],
                "f_grant_funds": grant_funds[0],
                "f_physical_assistance": physical_assistance[0],
                "f_membership_fees": membership_fees[0],
                "f_sponsorships": sponsorships[0],
                "f_financial_assistance": financial_assistance[0],
                "f_financial_support": financial_support[0],
                "f_inkind_help": inkind_help[0],
                "f_material_aid": material_aid[0],


                "l_begin_year": begin_year[1],
                "l_new_year": new_year[1],
                "l_dissimals": dissimals[1],
                "l_end_reporting": end_reporting[1],
                "l_group1all": group1all[1],
                "l_group1men": group1men[1],
                "l_group1women": group1women[1],
                "l_group2all": group2all[1],
                "l_group2men": group2men[1],
                "l_group2women": group2women[1],
                "l_group3all": group3all[1],
                "l_group3men": group3men[1],
                "l_group3women": group3women[1],
                "l_persons_to16all": persons_to16all[1],
                "l_persons_to16men": persons_to16men[1],
                "l_persons_to16women": persons_to16women[1],
                "l_to18": to18[1],
                "l_from18to30": from18to30[1],
                "l_from30to50": from30to50[1],
                "l_from50to60": from50to60[1],
                "l_from60to75": from60to75[1],
                "l_from75": from75[1],
                "l_uzbek": uzbek[1],
                "l_other_nationalities": other_nationalities[1],
                "l_highly": highly[1],
                "l_secondary_special": secondary_special[1],
                "l_secondary": secondary[1],
                "l_first_education": first_education[1],
                "l_no_education": no_education[1],
                "l_know_brail": know_brail[1],
                "l_know_computer": know_computer[1],
                "l_begin_year_social": begin_year_social[1],
                "l_new_year_social": new_year_social[1],
                "l_dissimals_social": dissimals_social[1],
                "l_end_reporting_social": end_reporting_social[1],
                "l_group1_socialall": group1_socialall[1],
                "l_group1_socialmen": group1_socialmen[1],
                "l_group1_socialwomen": group1_socialwomen[1],
                "l_group2_socialall": group2_socialall[1],
                "l_group2_socialmen": group2_socialmen[1],
                "l_group2_socialwomen": group2_socialwomen[1],
                "l_group3_socialall": group3_socialall[1],
                "l_group3_socialmen": group3_socialmen[1],
                "l_group3_socialwomen": group3_socialwomen[1],
                "l_healthy_members": healthy_members[1],
                "l_omember_disabilities": omember_disabilities[1],
                "l_from18to30_social": from18to30_social[1],
                "l_from30to50_social": from30to50_social[1],
                "l_from50to60_social": from50to60_social[1],
                "l_from60to75_social": from60to75_social[1],
                "l_from75_social": from75_social[1],
                "l_uzbek_social": uzbek_social[1],
                "l_other_nationalities_social": other_nationalities_social[1],
                "l_highly_social": highly_social[1],
                "l_secondary_special_social": secondary_special_social[1],
                "l_secondary_social": secondary_social[1],
                "l_first_education_social": first_education_social[1],
                "l_no_education_social": no_education_social[1],
                "l_know_brail_social": know_brail_social[1],
                "l_know_computer_social": know_computer_social[1],
                "l_blind_workers": blind_workers[1],
                "l_group1_workers": group1_workers[1],
                "l_group2_workers": group2_workers[1],
                "l_group3_workers": group3_workers[1],
                "l_koj_workers": koj_workers[1],
                "l_koj_workers_social": koj_workers_social[1],
                "l_koj_workers_nosocial": koj_workers_nosocial[1],
                "l_new_workers": new_workers[1],
                "l_new_koj_workers": new_koj_workers[1],
                "l_new_koj_social_workers": new_koj_social_workers[1],
                "l_new_koj_nosocial_workers": new_koj_nosocial_workers[1],

                "l_uzkoj_yangyol": uzkoj_yangyol[1],
                "l_uzkoj_kosonsoy": uzkoj_kosonsoy[1],
                "l_uzkoj_other": uzkoj_other[1],
                "l_uzkoj_outside_centers": uzkoj_outside_centers[1],
                "l_uzkoj_outside_home": uzkoj_outside_home[1],
                "l_prophylactics_outside": prophylactics_outside[1],
                "l_children_camps": children_camps[1],
                "l_to_holiday_social_all": to_holiday_social_all[1],
                "l_uzkoj_yangyol_other": uzkoj_yangyol_other[1],
                "l_uzkoj_kosonsoy_other": uzkoj_kosonsoy_other[1],
                "l_uzkoj_other_other": uzkoj_other_other[1],
                "l_uzkoj_outside_centers_other": uzkoj_outside_centers_other[1],
                "l_uzkoj_outside_home_other": uzkoj_outside_home_other[1],
                "l_prophylactics_outside_other": prophylactics_outside_other[1],
                "l_children_camps_other": children_camps_other[1],
                "l_to_holiday_other_all": to_holiday_other_all[1],

                "l_field_of_culture": field_of_culture[1],
                "l_public_education": public_education[1],
                "l_higher_education": higher_education[1],
                "l_special_education": special_education[1],
                "l_other_areas": other_areas[1],
                "l_community_system": community_system[1],
                "l_other_disabilities": other_disabilities[1],
                "l_healthy_emps": healthy_emps[1],

                "l_field_of_culture_new": field_of_culture_new[1],
                "l_public_education_new": public_education_new[1],
                "l_higher_education_new": higher_education_new[1],
                "l_special_education_new": special_education_new[1],
                "l_other_areas_new": other_areas_new[1],

                "l_total_amount": total_amount[1],
                "l_amount_appropriations": amount_appropriations[1],
                "l_state_budget": state_budget[1],
                "l_sponsorship_donation": sponsorship_donation[1],
                "l_grant_funds": grant_funds[1],
                "l_physical_assistance": physical_assistance[1],
                "l_membership_fees": membership_fees[1],
                "l_sponsorships": sponsorships[1],
                "l_financial_assistance": financial_assistance[1],
                "l_financial_support": financial_support[1],
                "l_inkind_help": inkind_help[1],
                "l_material_aid": material_aid[1],
            }
            if (isSend && !is_main) {
                data['status'] = 2
            }else{
                data['status'] = is_status
            }
            if (is_main && !comment && is_status===3){
                setData(data)
            }else{
                props.editSaveStats(data)
            }
        }
    }

    useEffect(()=>{
        if(begin_year[0] && new_year[0] && dissimals[0]){
            let myInt = (Number(begin_year[0]) + Number(new_year[0])) - Number(dissimals[0])
            setEndReporting([myInt, end_reporting[1]])}
        else{
            setEndReporting(['', end_reporting[1]])
        }
    }, [begin_year[0], new_year[0], dissimals[0]])
    useEffect(()=>{
        if(begin_year[1] && new_year[1] && dissimals[1]){
            let myInt = (Number(begin_year[1]) + Number(new_year[1])) - Number(dissimals[1])
            setEndReporting([end_reporting[0], myInt])}
        else{
            setEndReporting([end_reporting[0], ''])
        }
    }, [begin_year[1], new_year[1], dissimals[1]])

    useEffect(()=>{
        if(field_of_culture[0] && public_education[0] && higher_education[0] && special_education[0] && other_areas[0]){
            let myInt = Number(field_of_culture[0]) + Number(public_education[0]) + Number(higher_education[0]) + Number(special_education[0]) + Number(other_areas[0])
            setKojWorkersNoSocial([myInt, koj_workers_nosocial[1]])}
        else{
            setKojWorkersNoSocial(['', koj_workers_nosocial[1]])
        }
    }, [field_of_culture[0], public_education[0], higher_education[0], special_education[0], other_areas[0]])

    useEffect(()=>{
        if(field_of_culture[1] && public_education[1] && higher_education[1] && special_education[1] && other_areas[1]){
            let myInt = Number(field_of_culture[1]) + Number(public_education[1]) + Number(higher_education[1]) + Number(special_education[1]) + Number(other_areas[1])
            setKojWorkersNoSocial([koj_workers_nosocial[0], myInt])}
        else{
            setKojWorkersNoSocial([koj_workers_nosocial[0], ''])
        }
    }, [field_of_culture[1], public_education[1], higher_education[1], special_education[1], other_areas[1]])

    useEffect(()=>{
        if(other_disabilities[0] && healthy_emps[0]){
            let myInt = Number(other_disabilities[0]) + Number(healthy_emps[0])
            setCommunitySystem([myInt, community_system[1]])}
        else{
            setCommunitySystem(['', community_system[1]])
        }
    }, [other_disabilities[0], healthy_emps[0]])

    useEffect(()=>{
        if(other_disabilities[1] && healthy_emps[1]){
            let myInt = Number(other_disabilities[1]) + Number(healthy_emps[1])
            setCommunitySystem([community_system[0], myInt])}
        else{
            setCommunitySystem([community_system[0], ''])
        }
    }, [other_disabilities[1], healthy_emps[1]])

    useEffect(()=>{
        if(field_of_culture_new[0] && public_education_new[0] && higher_education_new[0] && special_education_new[0] && other_areas_new[0]){
            let myInt = Number(field_of_culture_new[0]) + Number(public_education_new[0]) + Number(higher_education_new[0]) + Number(special_education_new[0]) + Number(other_areas_new[0])
            setNewKojNoSocialWorkers([myInt, new_koj_nosocial_workers[1]])}
        else{
            setNewKojNoSocialWorkers(['', new_koj_nosocial_workers[1]])
        }
    }, [field_of_culture_new[0], public_education_new[0], higher_education_new[0], special_education_new[0], other_areas_new[0]])

    useEffect(()=>{
        if(field_of_culture_new[1] && public_education_new[1] && higher_education_new[1] && special_education_new[1] && other_areas_new[1]){
            let myInt = Number(field_of_culture_new[1]) + Number(public_education_new[1]) + Number(higher_education_new[1]) + Number(special_education_new[1]) + Number(other_areas_new[1])
            setNewKojNoSocialWorkers([new_koj_nosocial_workers[0], myInt])}
        else{
            setNewKojNoSocialWorkers([new_koj_nosocial_workers[0], ''])
        }
    }, [field_of_culture_new[1], public_education_new[1], higher_education_new[1], special_education_new[1], other_areas_new[1]])

    useEffect(()=>{
        if(group1men[0] && group1women[0]){
            let myInt = Number(group1men[0]) + Number(group1women[0])
            setGroup1All([myInt, group1all[1]])
        }else{
            setGroup1All(['', group1all[1]])
        }
    }, [group1men[0], group1women[0]])
    useEffect(()=>{
        if(group1men[1] && group1women[1]){
            let myInt = Number(group1men[1]) + Number(group1women[1])
            setGroup1All([group1all[0], myInt])
        }else{
            setGroup1All([group1all[0], ''])
        }
    }, [group1men[1], group1women[1]])

    useEffect(()=>{
        if(group2men[0] && group2women[0]){
            let myInt = Number(group2men[0]) + Number(group2women[0])
            setGroup2All([myInt, group2all[1]])
        }else{
            setGroup2All(['', group2all[1]])
        }
    }, [group2men[0], group2women[0]])
    useEffect(()=>{
        if(group2men[1] && group2women[1]){
            let myInt = Number(group2men[1]) + Number(group2women[1])
            setGroup2All([group2all[0], myInt])
        }else{
            setGroup2All([group2all[0], ''])
        }
    }, [group2men[1], group2women[1]])

    useEffect(()=>{
        if(group3men[0] && group3women[0]){
            let myInt = Number(group3men[0]) + Number(group3women[0])
            setGroup3All([myInt, group3all[1]])
        }else{
            setGroup3All(['', group3all[1]])
        }
    }, [group3men[0], group3women[0]])
    useEffect(()=>{
        if(group3men[1] && group3women[1]){
            let myInt = Number(group3men[1]) + Number(group3women[1])
            setGroup3All([group3all[0], myInt])
        }else{
            setGroup3All([group3all[0], ''])
        }
    }, [group3men[1], group3women[1]])

    useEffect(()=>{
        if(persons_to16men[0] && persons_to16women[0]){
            let myInt = Number(persons_to16men[0]) + Number(persons_to16women[0])
            setPersonTo16All([myInt, persons_to16all[1]])
        }else{
            setPersonTo16All(['', persons_to16all[1]])
        }
    }, [persons_to16men[0], persons_to16women[0]])
    useEffect(()=>{
        if(persons_to16men[1] && persons_to16women[1]){
            let myInt = Number(persons_to16men[1]) + Number(persons_to16women[1])
            setPersonTo16All([persons_to16all[0], myInt])
        }else{
            setPersonTo16All([persons_to16all[0], ''])
        }
    }, [persons_to16men[1], persons_to16women[1]])

    useEffect(()=>{
        if(group1all[0] >= 0 && group2all[0] >= 0 && group3all[0] >= 0 && persons_to16all[0] >= 0){
            let myInt = Number(group1all[0]) + Number(group2all[0]) + Number(group3all[0]) + Number(persons_to16all[0])
            if (myInt != end_reporting[0]){
                setByDis1Error([true, myInt])
            }else {
                setByDis1Error([false, myInt])
            }
        }else{
            setByDis1Error([false, ''])
        }
    }, [group1all[0], group2all[0], group3all[0], persons_to16all[0], props.stats_single, end_reporting])
    useEffect(()=>{
        if(group1all[1] >= 0 && group2all[1] >= 0 && group3all[1] >= 0 && persons_to16all[1] >= 0){
            let myInt = Number(group1all[1]) + Number(group2all[1]) + Number(group3all[1]) + Number(persons_to16all[1])
            if (myInt != end_reporting[1]){
                setByDis2Error([true, myInt])
            }else {
                setByDis2Error([false, ''])
            }
        }
        else{
            setByDis2Error([false, ''])
        }
    }, [group1all[1], group2all[1], group3all[1], persons_to16all[1], props.stats_single, end_reporting])

    useEffect(()=>{
        if(to18[0] && from18to30[0] && from30to50[0] && from50to60[0] && from60to75[0] && from75[0]){
            let myInt = Number(to18[0]) + Number(from18to30[0]) + Number(from30to50[0]) + Number(from50to60[0]) + Number(from60to75[0]) + Number(from75[0])
            if (myInt != end_reporting[0]){
                setByAge1Error([true, myInt])
            }else {
                setByAge1Error([false, myInt])
            }
        }
        else{
            setByAge1Error([false, ''])
        }
    }, [to18[0], from18to30[0], from30to50[0], from50to60[0], from60to75[0], from75[0], props.stats_single, end_reporting])
    useEffect(()=>{
        if(to18[1] && from18to30[1] && from30to50[1] && from50to60[1] && from60to75[1] && from75[1]){
            let myInt = Number(to18[1]) + Number(from18to30[1]) + Number(from30to50[1]) + Number(from50to60[1]) + Number(from60to75[1]) + Number(from75[1])
            if (myInt != end_reporting[1]){
                setByAge2Error([true, myInt])
            }else {
                setByAge2Error([false, myInt])
            }
        }
        else{
            setByAge2Error([false, ''])
        }
    }, [to18[1], from18to30[1], from30to50[1], from50to60[1], from60to75[1], from75[1], props.stats_single, end_reporting])
    useEffect(()=>{
        if(uzbek[0] && other_nationalities[0]){
            let myInt = Number(uzbek[0]) + Number(other_nationalities[0])
            if (myInt != end_reporting[0]){
                setByNat1Error([true, myInt])
            }else {
                setByNat1Error([false, myInt])
            }
        }
        else{
            setByNat1Error([false, ''])
        }
    }, [uzbek[0], other_nationalities[0], props.stats_single, end_reporting])
    useEffect(()=>{
        if(uzbek[1] && other_nationalities[1]){
            let myInt = Number(uzbek[1]) + Number(other_nationalities[1])
            if (myInt != end_reporting[1]){
                setByNat2Error([true, myInt])
            }else {
                setByNat2Error([false, myInt])
            }
        }
        else{
            setByNat2Error([false, ''])
        }
    }, [uzbek[1], other_nationalities[1], props.stats_single, end_reporting])
    useEffect(()=>{
        if(highly[0] && secondary_special[0] && secondary[0] && first_education[0] && no_education[0]){
            let myInt = Number(highly[0]) + Number(secondary_special[0]) + Number(secondary[0]) + Number(first_education[0]) + Number(no_education[0])
            if (myInt != end_reporting[0]){
                setByEdu1Error([true, myInt])
            }else {
                setByEdu1Error([false, myInt])
            }
        }
        else{
            setByEdu1Error([false, ''])
        }
    }, [highly[0], secondary_special[0], secondary[0], first_education[0], no_education[0], props.stats_single, end_reporting])
    useEffect(()=>{
        if(highly[1] && secondary_special[1] && secondary[1] && first_education[1] && no_education[1]){
            let myInt = Number(highly[1]) + Number(secondary_special[1]) + Number(secondary[1]) + Number(first_education[1]) + Number(no_education[1])
            if (myInt != end_reporting[1]){
                setByEdu2Error([true, myInt])
            }else {
                setByEdu2Error([false, myInt])
            }
        }
        else{
            setByEdu2Error([false, ''])
        }
    }, [highly[1], secondary_special[1], secondary[1], first_education[1], no_education[1], props.stats_single, end_reporting])


    useEffect(()=>{
        if(begin_year_social[0] && new_year_social[0] && dissimals_social[0]){
            let myInt = (Number(begin_year_social[0]) + Number(new_year_social[0])) - Number(dissimals_social[0])
            setEndReportingSocial([myInt, end_reporting_social[1]])}
        else{
            setEndReportingSocial(['', end_reporting_social[1]])
        }
    }, [begin_year_social[0], new_year_social[0], dissimals_social[0]])
    useEffect(()=>{
        if(begin_year_social[1] && new_year_social[1] && dissimals_social[1]){
            let myInt = (Number(begin_year_social[1]) + Number(new_year_social[1])) - Number(dissimals_social[1])
            setEndReportingSocial([end_reporting_social[0], myInt])}
        else{
            setEndReportingSocial([end_reporting_social[0], ''])
        }
    }, [begin_year_social[1], new_year_social[1], dissimals_social[1]])

    useEffect(()=>{
        if(group1_socialmen[0] && group1_socialwomen[0]){
            let myInt = Number(group1_socialmen[0]) + Number(group1_socialwomen[0])
            setGroup1SocialAll([myInt, group1_socialall[1]])
        }else{
            setGroup1SocialAll(['', group1_socialall[1]])
        }
    }, [group1_socialmen[0], group1_socialwomen[0]])
    useEffect(()=>{
        if(group1_socialmen[1] && group1_socialwomen[1]){
            let myInt = Number(group1_socialmen[1]) + Number(group1_socialwomen[1])
            setGroup1SocialAll([group1_socialall[0], myInt])
        }else{
            setGroup1SocialAll([group1_socialall[0], ''])
        }
    }, [group1_socialmen[1], group1_socialwomen[1]])

    useEffect(()=>{
        if(group2_socialmen[0] && group2_socialwomen[0]){
            let myInt = Number(group2_socialmen[0]) + Number(group2_socialwomen[0])
            setGroup2SocialAll([myInt, group2_socialall[1]])
        }else{
            setGroup2SocialAll(['', group2_socialall[1]])
        }
    }, [group2_socialmen[0], group2_socialwomen[0]])
    useEffect(()=>{
        if(group2_socialmen[1] && group2_socialwomen[1]){
            let myInt = Number(group2_socialmen[1]) + Number(group2_socialwomen[1])
            setGroup2SocialAll([group2_socialall[0], myInt])
        }else{
            setGroup2SocialAll([group2_socialall[0], ''])
        }
    }, [group2_socialmen[1], group2_socialwomen[1]])

    useEffect(()=>{
        if(group3_socialmen[0] && group3_socialwomen[0]){
            let myInt = Number(group3_socialmen[0]) + Number(group3_socialwomen[0])
            setGroup3SocialAll([myInt, group3_socialall[1]])
        }else{
            setGroup3SocialAll(['', group3_socialall[1]])
        }
    }, [group3_socialmen[0], group3_socialwomen[0]])
    useEffect(()=>{
        if(group3_socialmen[1] && group3_socialwomen[1]){
            let myInt = Number(group3_socialmen[1]) + Number(group3_socialwomen[1])
            setGroup3SocialAll([group3_socialall[0], myInt])
        }else{
            setGroup3SocialAll([group3_socialall[0], ''])
        }
    }, [group3_socialmen[1], group3_socialwomen[1]])

    useEffect(()=>{
        if(group1_socialall[0] >= 0 && group2_socialall[0] >= 0  && group3_socialall[0]  >= 0 && healthy_members[0] >= 0 && omember_disabilities[0] >= 0){
            let myInt = Number(group1_socialall[0]) + Number(group2_socialall[0]) + Number(group3_socialall[0]) + Number(healthy_members[0]) + Number(omember_disabilities[0])
            if (myInt != end_reporting_social[0]){
                setBysDis1Error([true, myInt])
            }else {
                setBysDis1Error([false, myInt])
            }
        }else {
            setBysDis1Error([false, ''])
        }
    }, [group1_socialall[0], group2_socialall[0], group3_socialall[0], healthy_members[0], omember_disabilities[0]
        , props.stats_single, end_reporting_social])
    useEffect(()=>{
        if(group1_socialall[1] >= 0 && group2_socialall[1] >= 0 && group3_socialall[1] >= 0 && healthy_members[1] >= 0 && omember_disabilities[1] >= 0){
            let myInt = Number(group1_socialall[1]) + Number(group2_socialall[1]) + Number(group3_socialall[1]) + Number(healthy_members[1]) + Number(omember_disabilities[1])
            if (myInt != end_reporting_social[1]){
                setBysDis2Error([true, myInt])
            }else {
                setBysDis2Error([false, myInt])
            }
        }else {
            setBysDis2Error([false, ''])
        }
    }, [group1_socialall[1], group2_socialall[1], group3_socialall[1], healthy_members[1], omember_disabilities[1]
        , props.stats_single, end_reporting_social])

    useEffect(()=>{
        if(from18to30_social[0] && from30to50_social[0] && from50to60_social && from60to75_social[0] && from75_social[0]){
            let myInt =  + Number(from18to30_social[0]) + Number(from30to50_social[0]) + Number(from50to60_social[0]) + Number(from60to75_social[0]) + Number(from75_social[0])
            if (myInt != end_reporting_social[0]){
                setBysAge1Error([true, myInt])
            }else {
                setBysAge1Error([false, myInt])
            }
        }else {
            setBysAge1Error([false, ''])
        }
    }, [from18to30_social[0], from30to50_social[0], from50to60_social[0], from60to75_social[0], from75_social[0], props.stats_single, end_reporting_social])
    useEffect(()=>{
        if(from18to30_social[1] && from30to50_social[1] && from50to60_social[1] && from60to75_social[1] && from75_social[1]){
            let myInt = Number(from18to30_social[1]) + Number(from30to50_social[1]) + Number(from50to60_social[1]) + Number(from60to75_social[1]) + Number(from75_social[1])
            if (myInt != end_reporting_social[1]){
                setBysAge2Error([true, myInt])
            }else {
                setBysAge2Error([false, myInt])
            }
        }else {
            setBysAge2Error([false, ''])
        }
    }, [from18to30_social[1], from30to50_social[1], from50to60_social[1], from60to75_social[1], from75_social[1], props.stats_single, end_reporting_social])
    useEffect(()=>{
        if(uzbek_social[0] && other_nationalities_social[0]){
            let myInt = Number(uzbek_social[0]) + Number(other_nationalities_social[0])
            if (myInt != end_reporting_social[0]){
                setBysNat1Error([true, myInt])
            }else {
                setBysNat1Error([false, myInt])
            }
        }else {
            setBysNat1Error([false, ''])
        }
    }, [uzbek_social[0], other_nationalities_social[0], props.stats_single, end_reporting_social])
    useEffect(()=>{
        if(uzbek_social[1] && other_nationalities_social[1]){
            let myInt = Number(uzbek_social[1]) + Number(other_nationalities_social[1])
            if (myInt != end_reporting_social[1]){
                setBysNat2Error([true, myInt])
            }else {
                setBysNat2Error([false, myInt])
            }
        }else {
            setBysNat2Error([false, ''])
        }
    }, [uzbek_social[1], other_nationalities_social[1], props.stats_single, end_reporting_social])
    useEffect(()=>{
        if(highly_social[0] && secondary_special_social[0] && secondary_social[0] && first_education_social[0] && no_education_social[0]){
            let myInt = Number(highly_social[0]) + Number(secondary_special_social[0]) + Number(secondary_social[0]) + Number(first_education_social[0]) + Number(no_education_social[0])
            if (myInt != end_reporting_social[0]){
                setBysEdu1Error([true, myInt])
            }else {
                setBysEdu1Error([false, myInt])
            }
        }else {
            setBysEdu1Error([false, ''])
        }
    }, [highly_social[0], secondary_special_social[0], secondary_social[0], first_education_social[0], no_education_social[0], props.stats_single, end_reporting_social])
    useEffect(()=>{
        if(highly_social[1] && secondary_special_social[1] && secondary_social[1] && first_education_social[1] && no_education_social[1]){
            let myInt = Number(highly_social[1]) + Number(secondary_special_social[1]) + Number(secondary_social[1]) + Number(first_education_social[1]) + Number(no_education_social[1])
            if (myInt != end_reporting_social[1]){
                setBysEdu2Error([true, myInt])
            }else {
                setBysEdu2Error([false, myInt])
            }
        }else {
            setBysEdu2Error([false, ''])
        }
    }, [highly_social[1], secondary_special_social[1], secondary_social[1], first_education_social[1], no_education_social[1], props.stats_single, end_reporting_social])


    useEffect(()=>{
        if(group1_workers[0] && group2_workers[0] && group3_workers[0]){
            let myInt = (Number(group1_workers[0]) + Number(group2_workers[0])) + Number(group3_workers[0])
            setBlindWorkers([myInt, blind_workers[1]])}
        else{
            setBlindWorkers(['', blind_workers[1]])
        }
    }, [group1_workers[0], group2_workers[0], group3_workers[0]])
    useEffect(()=>{
        if(group1_workers[1] && group2_workers[1] && group3_workers[1]){
            let myInt = (Number(group1_workers[1]) + Number(group2_workers[1])) + Number(group3_workers[1])
            setBlindWorkers([blind_workers[0], myInt])}
        else{
            setBlindWorkers([blind_workers[0], ''])
        }
    }, [group1_workers[1], group2_workers[1], group3_workers[1]])

    useEffect(()=>{
        if(new_koj_workers[0] && new_koj_social_workers[0] && new_koj_nosocial_workers[0]){
            let myInt = (Number(new_koj_workers[0]) + Number(new_koj_social_workers[0])) + Number(new_koj_nosocial_workers[0])
            setNewWorkers([myInt, new_workers[1]])}
        else{
            setNewWorkers(['', new_workers[1]])
        }
    }, [new_koj_workers[0], new_koj_social_workers[0], new_koj_nosocial_workers[0]])
    useEffect(()=>{
        if(new_koj_workers[1] && new_koj_social_workers[1] && new_koj_nosocial_workers[1]){
            let myInt = (Number(new_koj_workers[1]) + Number(new_koj_social_workers[1])) + Number(new_koj_nosocial_workers[1])
            setNewWorkers([new_workers[0], myInt])}
        else{
            setNewWorkers([new_workers[0], ''])
        }
    }, [new_koj_workers[1], new_koj_social_workers[1], new_koj_nosocial_workers[1]])

    useEffect(()=>{
        if(learners_highly && learners_ind && learners_general && learners_special_secondary && special_educational && learners_state && students_preschool){
            let myInt = Number(learners_highly) + Number(learners_ind) + Number(learners_general) + Number(learners_special_secondary) + Number(special_educational) + Number(learners_state) + Number(students_preschool)
            setAllLearners(myInt)
        }
        else{
            setAllLearners('')
        }
    }, [learners_highly, learners_ind, learners_general, learners_special_secondary, special_educational, learners_state, students_preschool])

    useEffect(()=>{
        if(uzkoj_yangyol[0] && uzkoj_kosonsoy[0] && uzkoj_other[0] && uzkoj_outside_centers[0] && uzkoj_outside_home[0] && prophylactics_outside[0] && children_camps[0]){
            let myInt = Number(uzkoj_yangyol[0]) + Number(uzkoj_kosonsoy[0]) + Number(uzkoj_other[0]) + Number(uzkoj_outside_centers[0]) + Number(uzkoj_outside_home[0]) + Number(prophylactics_outside[0]) + Number(children_camps[0])
            setHolidSocialAll([myInt, to_holiday_social_all[1]])}
        else{
            setHolidSocialAll(['', to_holiday_social_all[1]])
        }
    }, [uzkoj_yangyol[0], uzkoj_kosonsoy[0], uzkoj_other[0], uzkoj_outside_centers[0], uzkoj_outside_home[0], prophylactics_outside[0], children_camps[0]])

    useEffect(()=>{
        if(uzkoj_yangyol[1] && uzkoj_kosonsoy[1] && uzkoj_other[1] && uzkoj_outside_centers[1] && uzkoj_outside_home[1] && prophylactics_outside[1] && children_camps[1]){
            let myInt = Number(uzkoj_yangyol[1]) + Number(uzkoj_kosonsoy[1]) + Number(uzkoj_other[1]) + Number(uzkoj_outside_centers[1]) + Number(uzkoj_outside_home[1]) + Number(prophylactics_outside[1]) + Number(children_camps[1])
            setHolidSocialAll([to_holiday_social_all[0], myInt])}
        else{
            setHolidSocialAll([to_holiday_social_all[0], ''])
        }
    }, [uzkoj_yangyol[1], uzkoj_kosonsoy[1], uzkoj_other[1], uzkoj_outside_centers[1], uzkoj_outside_home[1], prophylactics_outside[1], children_camps[1]])

    useEffect(()=>{
        if(uzkoj_yangyol_other[0] && uzkoj_kosonsoy_other[0] && uzkoj_other_other[0] && uzkoj_outside_centers_other[0] && uzkoj_outside_home_other[0] && prophylactics_outside_other[0] && children_camps_other[0]){
            let myInt = Number(uzkoj_yangyol_other[0]) + Number(uzkoj_kosonsoy_other[0]) + Number(uzkoj_other_other[0]) + Number(uzkoj_outside_centers_other[0]) + Number(uzkoj_outside_home_other[0]) + Number(prophylactics_outside_other[0]) + Number(children_camps_other[0])
            setHolidOtherAll([myInt, to_holiday_other_all[1]])}
        else{
            setHolidOtherAll(['', to_holiday_other_all[1]])
        }
    }, [uzkoj_yangyol_other[0], uzkoj_kosonsoy_other[0], uzkoj_other_other[0], uzkoj_outside_centers_other[0], uzkoj_outside_home_other[0], prophylactics_outside_other[0], children_camps_other[0]])

    useEffect(()=>{
        if(uzkoj_yangyol_other[1] && uzkoj_kosonsoy_other[1] && uzkoj_other_other[1] && uzkoj_outside_centers_other[1] && uzkoj_outside_home_other[1] && prophylactics_outside_other[1] && children_camps_other[1]){
            let myInt = Number(uzkoj_yangyol_other[1]) + Number(uzkoj_kosonsoy_other[1]) + Number(uzkoj_other_other[1]) + Number(uzkoj_outside_centers_other[1]) + Number(uzkoj_outside_home_other[1]) + Number(prophylactics_outside_other[1]) + Number(children_camps_other[1])
            setHolidOtherAll([to_holiday_other_all[0], myInt])}
        else{
            setHolidOtherAll([to_holiday_other_all[0], ''])
        }
    }, [uzkoj_yangyol_other[1], uzkoj_kosonsoy_other[1], uzkoj_other_other[1], uzkoj_outside_centers_other[1], uzkoj_outside_home_other[1], prophylactics_outside_other[1], children_camps_other[1]])

    useEffect(()=>{
        if(membership_fees[0] && amount_appropriations[0] && state_budget[0] && sponsorship_donation[0] && grant_funds[0]){
            let myInt = Number(membership_fees[0]) + Number(amount_appropriations[0]) + Number(state_budget[0]) + Number(sponsorship_donation[0]) + Number(grant_funds[0])
            setTotalAmount([myInt, total_amount[1]])}
        else{
            setTotalAmount(['', total_amount[1]])
        }
    }, [membership_fees[0], amount_appropriations[0], state_budget[0], sponsorship_donation[0], grant_funds[0]])

    useEffect(()=>{
        if(membership_fees[1] && amount_appropriations[1] && state_budget[1] && sponsorship_donation[1] && grant_funds[1]){
            let myInt = Number(membership_fees[1]) + Number(amount_appropriations[1]) + Number(state_budget[1]) + Number(sponsorship_donation[1]) + Number(grant_funds[1])
            setTotalAmount([total_amount[0], myInt])}
        else{
            setTotalAmount([total_amount[0], ''])
        }
    }, [membership_fees[1], amount_appropriations[1], state_budget[1], sponsorship_donation[1], grant_funds[1]])

    useEffect(()=>{
        if(financial_assistance[0] && inkind_help[0]){
            let myInt = Number(financial_assistance[0]) + Number(inkind_help[0])
            setPhysicalAssistance([myInt, physical_assistance[1]])}
        else{
            setPhysicalAssistance(['', physical_assistance[1]])
        }
    }, [financial_assistance[0], inkind_help[0]])
    useEffect(()=>{
        if(financial_assistance[1] && inkind_help[1]){
            let myInt = Number(financial_assistance[1]) + Number(inkind_help[1])
            setPhysicalAssistance([physical_assistance[0], myInt])}
        else{
            setPhysicalAssistance([physical_assistance[0], ''])
        }
    }, [financial_assistance[1], inkind_help[1]])

    function CancelReport(){
        setIsStatus(3)
        setOpen(true)
    }

    const navigate = useNavigate()
    const toBack = () => {
        navigate(-1);
    }

    useEffect(() => {
        if (props.message === true) {
            toast.success(t('save_success'), {theme: 'colored'})
            navigate('/stats/')
        } else if (props.message === 'error') {
            toast.error(t('save_error'), {theme: 'colored'})
            props.messageReset()
        }
    }, [props.message])

    return <div>
        <div className={'page addStat'}>
            <Header/>
            <Dashboard/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}>
                <Toolbar/>
                <div className={'main'}>
                        <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={toBack}>
                            {t('back')}
                        </Button>
                    <h2 className={'page-title text-center'}>{t('type_report')}</h2>
                    {open ? <CancelModalStat editSaveStats={props.editSaveStats} data={data} open={open} setOpen={setOpen} setComment={setComment}/> : ''}
                    <form className={'add-form add-stat'} onSubmit={saveFormData}>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>1. {t('report_about')}</h5>
                        </div>
                        <div>
                            <p><span>{t('type')}:</span>
                                <Box sx={{minWidth: 200}}>
                                    <FormControl fullWidth>
                                        <InputLabel error={hasError} required
                                                    id="demo-simple-select-label">{t('type')}</InputLabel>
                                        <Select
                                            error={hasError}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            disabled={is_main} value={report}
                                            disabled={is_main}
                                            label={t('type')}
                                            onChange={(e) => {
                                                setReport(e.target.value)
                                            }}
                                        >
                                            <MenuItem disabled={is_main} value={1}>{t('type_report')}</MenuItem>
                                            <MenuItem disabled={is_main} value={2}>{t('type_reply_report')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box></p>
                            <p><span>{t('report_year')}:</span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        minDate={new Date("2022-01-01")}
                                        views={['year']}
                                        label={t('report_year')}
                                        disabled={is_main}
                                        disabled={is_main} value={year}
                                        onChange={(newValue) => {
                                            setYear(newValue);
                                        }}
                                        renderInput={(params) => <TextField required {...params} helperText={null}/>}
                                    />
                                </LocalizationProvider>
                            </p>
                            <p><span>{t('report_month')}:</span>
                                <Box sx={{minWidth: 200}}>
                                    <FormControl fullWidth>
                                        <InputLabel error={hasError1} required
                                                    id="demo-simple-select-label">{t('report_month')}</InputLabel>
                                        <Select
                                            error={hasError1}
                                            disabled={is_main}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            disabled={is_main} value={period}
                                            label={t('report_month')}
                                            onChange={(e) => {
                                                setPeriod(e.target.value)
                                            }}
                                        >
                                            <MenuItem disabled={is_main} value={1}>{t('quarter')}</MenuItem>
                                            <MenuItem disabled={is_main} value={2}>{t('semi_annual')}</MenuItem>
                                            <MenuItem disabled={is_main} value={3}>{t('nine_month')}</MenuItem>
                                            <MenuItem disabled={is_main} value={4}>{t('yearly')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box></p>
                        </div>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>2. {t('about_organization')}</h5>
                        </div>
                        <div>
                            <p>
                                <span>{t('address_organization')}:</span>
                                <TextField disabled={is_main} value={address} disabled={is_main} onChange={(e) => setAddress(e.target.value)} required
                                           id="outlined-basic" label={t('address_organization')} variant="outlined"/>
                            </p>
                        </div>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>2.1. {t('unique_numbers')}</h5>
                        </div>
                        <div>
                            <p>
                                <span>{t('ktut')}:</span>
                                <TextField disabled={is_main} value={ktut} disabled={is_main} onChange={(e) => setKtut(e.target.value)} required
                                           id="outlined-basic" label={t('ktut')} variant="outlined"/>
                            </p>
                            <p>
                                <span>{t('mhobt')}:</span>
                                <TextField disabled={is_main} value={mhobt} disabled={is_main} onChange={(e) => setMhobt(e.target.value)} required
                                           id="outlined-basic" label={t('mhobt')} variant="outlined"/>
                            </p>
                            <p>
                                <span>{t('xxtut')}:</span>
                                <TextField disabled={is_main} value={xxtut} disabled={is_main} onChange={(e) => setXxtut(e.target.value)} required
                                           id="outlined-basic" label={t('xxtut')} variant="outlined"/>
                            </p>
                        </div>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>3. {t('stats_koj_title')}</h5>
                        </div>
                        <table className={'table table-bordered'}>
                            <thead>
                            <tr>
                                <th colSpan={4}>{t('koj_subject_title')}</th>
                            </tr>
                            <tr>
                                <th>№</th>
                                <th>{t('indicators')}</th>
                                <th>{t('in_quarter')}</th>
                                <th>{t('in_yearly')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>{t('beginning_of_year')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                           onInput={F => F.target.setCustomValidity('')} required min="0" disabled={is_main} value={begin_year[0]}
                                           onChange={(e) => setBeginYear([e.target.value, begin_year[1]])}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                           onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={begin_year[1]}
                                           onChange={(e) => setBeginYear([begin_year[0], e.target.value])}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>{t('new_of_year')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={new_year[0]}
                                            onChange={(e) => setNewYear([e.target.value, new_year[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={new_year[1]}
                                            onChange={(e) => setNewYear([new_year[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>{t('dissimals')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={dissimals[0]}
                                            onChange={(e) => setDissimals([e.target.value, dissimals[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={dissimals[1]}
                                            onChange={(e) => setDissimals([dissimals[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>{t('end_reporting_period')} (№.1 + №.2 - №.3)</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={end_reporting[0]}
                                            disabled
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={end_reporting[1]}
                                            disabled
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <th>{t('by_disability')}</th>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>{t('group1')} ({t('all_sum')}: {t('A')} + {t('B')})</td>
                                <td>
                                    <input type="number" id="number" required min="0" disabled={is_main} value={group1all[0]} disabled/>
                                </td>
                                <td>
                                    <input type="number" id="number" required min="0" disabled={is_main} value={group1all[1]} disabled/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('A')}</td>
                                <td>{t('group1men')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                           onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group1men[0]}
                                           onChange={(e) => setGroup1Men([e.target.value, group1men[1]])}
                                           onKeyDown={(evt) => evt.key === 'e' || evt.key==='-' && evt.preventDefault()}/></td>
                                <td>
                                    <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                           onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group1men[1]}
                                           onChange={(e) => setGroup1Men([group1men[0], e.target.value])}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('B')}</td>
                                <td>{t('group1women')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                           onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group1women[0]}
                                           onChange={(e) => setGroup1Women([e.target.value, group1women[1]])}
                                           onKeyDown={(evt) => evt.key === 'e' || evt.key==='-' && evt.preventDefault()}/></td>
                                <td>
                                    <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                           onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group1women[1]}
                                           onChange={(e) => setGroup1Women([group1women[0], e.target.value])}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>{t('group2')} ({t('all_sum')}: {t('A')} + {t('B')})</td>
                                <td>
                                    <input type="number" id="number" required min={0} disabled={is_main} value={group2all[0]} disabled/>
                                </td>
                                <td>
                                    <input type="number" id="number" required min={0} disabled={is_main} value={group2all[1]} disabled/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('A')}</td>
                                <td>{t('group2men')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                           onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group2men[0]}
                                           onChange={(e) => setGroup2Men([e.target.value, group2men[1]])}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group2men[1]}
                                            onChange={(e) => setGroup2Men([group2men[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('B')}</td>
                                <td>{t('group2women')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                           onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group2women[0]}
                                           onChange={(e) => setGroup2Women([e.target.value, group2women[1]])}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group2women[1]}
                                            onChange={(e) => setGroup2Women([group2women[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>{t('group3')} ({t('all_sum')}: {t('A')} + {t('B')})</td>
                                <td>
                                    <input type="number" id="number" required min={0} disabled={is_main} value={group3all[0]} disabled/>
                                </td>
                                <td>
                                    <input type="number" id="number" required min={0} disabled={is_main} value={group3all[1]} disabled/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('A')}</td>
                                <td>{t('group3men')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group3men[0]}
                                            onChange={(e) => setGroup3Men([e.target.value, group3men[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group3men[1]}
                                            onChange={(e) => setGroup3Men([group3men[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('B')}</td>
                                <td>{t('group3women')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')} required min="0" disabled={is_main} value={group3women[0]}
                                            onChange={(e) => setGroup3Women([e.target.value, group3women[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')} required min="0" disabled={is_main} value={group3women[1]}
                                            onChange={(e) => setGroup3Women([group3women[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>{t('persons_to16')} ({t('all_sum')}: {t('A')} + {t('B')})</td>
                                <td>
                                    <input type="number" id="number" required min={0} disabled={is_main} value={persons_to16all[0]} disabled/>
                                </td>
                                <td>
                                    <input type="number" id="number" required min={0} disabled={is_main} value={persons_to16all[1]} disabled/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('A')}</td>
                                <td>{t('persons_to16men')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={persons_to16men[0]}
                                            onChange={(e) => setPersonTo16Men([e.target.value, persons_to16men[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={persons_to16men[1]}
                                            onChange={(e) => setPersonTo16Men([persons_to16men[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('B')}</td>
                                <td>{t('persons_to16women')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={persons_to16women[0]}
                                            onChange={(e) => setPersonTo16Women([e.target.value, persons_to16women[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={persons_to16women[1]}
                                            onChange={(e) => setPersonTo16Women([persons_to16women[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            {by_disability1_error[0] || by_disability2_error[0] ?
                                <tr className={'error-tr alert alert-danger'}>
                                    <td></td>
                                    <th>{t('save_error')} {t('end_reporting_not_true')}</th>
                                    <td>{by_disability1_error[0] ? end_reporting[0]+' ≠ '+by_disability1_error[1] : ''}</td>
                                    <td>{by_disability2_error[0] ? end_reporting[1]+' ≠ '+by_disability2_error[1] : ''}</td>
                                </tr>
                                : ''}
                            <tr>
                                <td></td>
                                <th>{t('age_periods')}</th>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>17</td>
                                <td>{t('to16')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={to18[0]}
                                            onChange={(e) => setTo18([e.target.value, to18[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={to18[1]}
                                            onChange={(e) => setTo18([to18[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>{t('from16to30')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={from18to30[0]}
                                            onChange={(e) => setFrom18To30([e.target.value, from18to30[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={from18to30[1]}
                                            onChange={(e) => setFrom18To30([from18to30[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>{t('from30to50')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={from30to50[0]}
                                            onChange={(e) => setFrom30To50([e.target.value, from30to50[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={from30to50[1]}
                                            onChange={(e) => setFrom30To50([from30to50[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>13</td>
                                <td>{t('from50to60')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={from50to60[0]}
                                            onChange={(e) => setFrom50To60([e.target.value, from50to60[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={from50to60[1]}
                                            onChange={(e) => setFrom50To60([from50to60[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>14</td>
                                <td>{t('from60to75')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={from60to75[0]}
                                            onChange={(e) => setFrom60To75([e.target.value, from60to75[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={from60to75[1]}
                                            onChange={(e) => setFrom60To75([from60to75[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>15</td>
                                <td>{t('from75')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={from75[0]}
                                            onChange={(e) => setFrom75([e.target.value, from75[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={from75[1]}
                                            onChange={(e) => setFrom75([from75[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            {by_age1_error[0] || by_age2_error[0] ?
                                <tr className={'error-tr alert alert-danger'}>
                                    <td></td>
                                    <th>{t('save_error')} {t('end_reporting_not_true')}</th>
                                    <td>{by_age1_error[0] ? end_reporting[0]+' ≠ '+by_age1_error[1] : ''}</td>
                                    <td>{by_age2_error[0] ? end_reporting[1]+' ≠ '+by_age2_error[1] : ''}</td>
                                </tr>
                                : ''}
                            <tr>
                                <td></td>
                                <th>{t('by_nationality')}</th>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>16</td>
                                <td>{t('uzbek')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzbek[0]}
                                            onChange={(e) => setUzbek([e.target.value, uzbek[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzbek[1]}
                                            onChange={(e) => setUzbek([uzbek[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>17</td>
                                <td>{t('other_nationalities')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={other_nationalities[0]}
                                            onChange={(e) => setOtherNation([e.target.value, other_nationalities[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={other_nationalities[1]}
                                            onChange={(e) => setOtherNation([other_nationalities[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            {by_nationality1_error[0] || by_nationality2_error[0] ?
                                <tr className={'error-tr alert alert-danger'}>
                                    <td></td>
                                    <th>{t('save_error')} {t('end_reporting_not_true')}</th>
                                    <td>{by_nationality1_error[0] ? end_reporting[0]+' ≠ '+by_nationality1_error[1] : ''}</td>
                                    <td>{by_nationality2_error[0] ? end_reporting[1]+' ≠ '+by_nationality2_error[1] : ''}</td>
                                </tr>
                                : ''}
                            <tr>
                                <td></td>
                                <th>{t('by_education')}</th>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>18</td>
                                <td>{t('highly')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={highly[0]}
                                            onChange={(e) => setHighly([e.target.value, highly[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={highly[1]}
                                            onChange={(e) => setHighly([highly[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>19</td>
                                <td>{t('secondary_special')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={secondary_special[0]}
                                            onChange={(e) => setSecondarySpecial([e.target.value, secondary_special[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={secondary_special[1]}
                                            onChange={(e) => setSecondarySpecial([secondary_special[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td>{t('secondary')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={secondary[0]}
                                            onChange={(e) => setSecondary([e.target.value, secondary[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={secondary[1]}
                                            onChange={(e) => setSecondary([secondary[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>21</td>
                                <td>{t('first_education')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={first_education[0]}
                                            onChange={(e) => setFirstEducation([e.target.value, first_education[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={first_education[1]}
                                            onChange={(e) => setFirstEducation([first_education[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>22</td>
                                <td>{t('no_education')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={no_education[0]}
                                            onChange={(e) => setNoEducation([e.target.value, no_education[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={no_education[1]}
                                            onChange={(e) => setNoEducation([no_education[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            {by_education1_error[0] || by_education2_error[0] ?
                                <tr className={'error-tr alert alert-danger'}>
                                    <td></td>
                                    <th>{t('save_error')} {t('end_reporting_not_true')}</th>
                                    <td>{by_education1_error[0] ? end_reporting[0]+' ≠ '+by_education1_error[1] : ''}</td>
                                    <td>{by_education2_error[0] ? end_reporting[1]+' ≠ '+by_education2_error[1] : ''}</td>
                                </tr>
                                : ''}
                            <tr>
                                <td></td>
                                <th>{t('addition')}</th>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>23</td>
                                <td>{t('know_brail')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={know_brail[0]}
                                            onChange={(e) => setKnowBrail([e.target.value, know_brail[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={know_brail[1]}
                                            onChange={(e) => setKnowBrail([know_brail[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>24</td>
                                <td>{t('know_computer')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={know_computer[0]}
                                            onChange={(e) => setKnowComputer([e.target.value, know_computer[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={know_computer[1]}
                                            onChange={(e) => setKnowComputer([know_computer[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            </tbody>
                            <thead>
                            <tr><hr/></tr>
                            <tr>
                                <th colSpan={4}>{t('societ_subject_title')}</th>
                            </tr>
                            <tr>
                                <th>№</th>
                                <th>{t('indicators')}</th>
                                <th>{t('in_quarter')}</th>
                                <th>{t('in_yearly')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>{t('beginning_of_year_social')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={begin_year_social[0]}
                                            onChange={(e) => setBeginYearSocial([e.target.value, begin_year_social[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={begin_year_social[1]}
                                            onChange={(e) => setBeginYearSocial([begin_year_social[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>{t('new_of_year_social')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={new_year_social[0]}
                                            onChange={(e) => setNewYearSocial([e.target.value, new_year_social[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={new_year_social[1]}
                                            onChange={(e) => setNewYearSocial([new_year_social[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>{t('dissimals_social')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={dissimals_social[0]}
                                            onChange={(e) => setDissimalsSocial([e.target.value, dissimals_social[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={dissimals_social[1]}
                                            onChange={(e) => setDissimalsSocial([dissimals_social[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>{t('end_reporting_period_social')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={end_reporting_social[0]}
                                            disabled
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={end_reporting_social[1]}
                                            disabled
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <th>{t('by_disability')}</th>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>{t('group1')} ({t('all_sum')}: {t('A')} + {t('B')})</td>
                                <td>
                                    <input  type="number" id="number" required min="0" disabled disabled={is_main} value={group1_socialall[0]}/>
                                </td>
                                <td>
                                    <input  type="number" id="number" required min="0" disabled disabled={is_main} value={group1_socialall[1]}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('A')}</td>
                                <td>{t('group1men')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group1_socialmen[0]}
                                            onChange={(e) => setGroup1SocialMen([e.target.value, group1_socialmen[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group1_socialmen[1]}
                                            onChange={(e) => setGroup1SocialMen([group1_socialmen[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('B')}</td>
                                <td>{t('group1women')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group1_socialwomen[0]}
                                            onChange={(e) => setGroup1SocialWomen([e.target.value, group1_socialwomen[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group1_socialwomen[1]}
                                            onChange={(e) => setGroup1SocialWomen([group1_socialwomen[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>{t('group2')} ({t('all_sum')}: {t('A')} + {t('B')})</td>
                                <td>
                                    <input  type="number" id="number" required min="0" disabled disabled={is_main} value={group2_socialall[0]}/>
                                </td>
                                <td>
                                    <input  type="number" id="number" required min="0" disabled disabled={is_main} value={group2_socialall[1]}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('A')}</td>
                                <td>{t('group2men')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group2_socialmen[0]}
                                            onChange={(e) => setGroup2SocialMen([e.target.value, group2_socialmen[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group2_socialmen[1]}
                                            onChange={(e) => setGroup2SocialMen([group2_socialmen[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('B')}</td>
                                <td>{t('group2women')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group2_socialwomen[0]}
                                            onChange={(e) => setGroup2SocialWomen([e.target.value, group2_socialwomen[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group2_socialwomen[1]}
                                            onChange={(e) => setGroup2SocialWomen([group2_socialwomen[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>{t('group3')} ({t('all_sum')}: {t('A')} + {t('B')})</td>
                                <td>
                                    <input  type="number" id="number" required min="0" disabled disabled={is_main} value={group3_socialall[0]}/>
                                </td>
                                <td>
                                    <input  type="number" id="number" required min="0" disabled disabled={is_main} value={group3_socialall[1]}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('A')}</td>
                                <td>{t('group3men')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group3_socialmen[0]}
                                            onChange={(e) => setGroup3SocialMen([e.target.value, group3_socialmen[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group3_socialmen[1]}
                                            onChange={(e) => setGroup3SocialMen([group3_socialmen[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('B')}</td>
                                <td>{t('group3women')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group3_socialwomen[0]}
                                            onChange={(e) => setGroup3SocialWomen([e.target.value, group3_socialwomen[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group3_socialwomen[1]}
                                            onChange={(e) => setGroup3SocialWomen([group3_socialwomen[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>{t('healthy_members')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={healthy_members[0]}
                                            onChange={(e) => setHealthyMembers([e.target.value, healthy_members[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={healthy_members[1]}
                                            onChange={(e) => setHealthyMembers([healthy_members[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>{t('omember_disabilities')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={omember_disabilities[0]}
                                            onChange={(e) => setOMemberDisabilities([e.target.value, omember_disabilities[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={omember_disabilities[1]}
                                            onChange={(e) => setOMemberDisabilities([omember_disabilities[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            {by_sdisability1_error[0] || by_sdisability2_error[0] ?
                                <tr className={'error-tr alert alert-danger'}>
                                    <td></td>
                                    <th>{t('save_error')} {t('end_reporting_not_true')}</th>
                                    <td>{by_sdisability1_error[0] ? end_reporting_social[0]+' ≠ '+by_sdisability1_error[1] : ''}</td>
                                    <td>{by_sdisability2_error[0] ? end_reporting_social[1]+' ≠ '+by_sdisability2_error[1] : ''}</td>
                                </tr>
                                : ''}
                            <tr>
                                <td></td>
                                <th>{t('age_periods')}</th>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>{t('from18to30')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={from18to30_social[0]}
                                            onChange={(e) => setFrom18To30Social([e.target.value, from18to30_social[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={from18to30_social[1]}
                                            onChange={(e) => setFrom18To30Social([from18to30_social[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>{t('from30to50')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={from30to50_social[0]}
                                            onChange={(e) => setFrom30To50Social([e.target.value, from30to50_social[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={from30to50_social[1]}
                                            onChange={(e) => setFrom30To50Social([from30to50_social[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>{t('from50to60')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={from50to60_social[0]}
                                            onChange={(e) => setFrom50To60Social([e.target.value, from50to60_social[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={from50to60_social[1]}
                                            onChange={(e) => setFrom50To60Social([from50to60_social[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>13</td>
                                <td>{t('from60to75')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={from60to75_social[0]}
                                            onChange={(e) => setFrom60To75Social([e.target.value, from60to75_social[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={from60to75_social[1]}
                                            onChange={(e) => setFrom60To75Social([from60to75_social[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>14</td>
                                <td>{t('from75')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={from75_social[0]}
                                            onChange={(e) => setFrom75Social([e.target.value, from75_social[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={from75_social[1]}
                                            onChange={(e) => setFrom75Social([from75_social[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            {by_sage1_error[0] || by_sage2_error[0] ?
                                <tr className={'error-tr alert alert-danger'}>
                                    <td></td>
                                    <th>{t('save_error')} {t('end_reporting_not_true')}</th>
                                    <td>{by_sage1_error[0] ? end_reporting_social[0]+' ≠ '+by_sage1_error[1] : ''}</td>
                                    <td>{by_sage2_error[0] ? end_reporting_social[1]+' ≠ '+by_sage2_error[1] : ''}</td>
                                </tr>
                                : ''}
                            <tr>
                                <td></td>
                                <th>{t('by_nationality')}</th>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>15</td>
                                <td>{t('uzbek')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzbek_social[0]}
                                            onChange={(e) => setUzbekSocial([e.target.value, uzbek_social[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzbek_social[1]}
                                            onChange={(e) => setUzbekSocial([uzbek_social[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>16</td>
                                <td>{t('other_nationalities')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={other_nationalities_social[0]}
                                            onChange={(e) => setOtherNationSocial([e.target.value, other_nationalities_social[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={other_nationalities_social[1]}
                                            onChange={(e) => setOtherNationSocial([other_nationalities_social[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            {by_snationality1_error[0] || by_snationality2_error[0] ?
                                <tr className={'error-tr alert alert-danger'}>
                                    <td></td>
                                    <th>{t('save_error')} {t('end_reporting_not_true')}</th>
                                    <td>{by_snationality1_error[0] ? end_reporting_social[0]+' ≠ '+by_snationality1_error[1] : ''}</td>
                                    <td>{by_snationality2_error[0] ? end_reporting_social[1]+' ≠ '+by_snationality2_error[1] : ''}</td>
                                </tr>
                                : ''}
                            <tr>
                                <td></td>
                                <th>{t('by_education')}</th>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>17</td>
                                <td>{t('highly')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={highly_social[0]}
                                            onChange={(e) => setHighlySocial([e.target.value, highly_social[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={highly_social[1]}
                                            onChange={(e) => setHighlySocial([highly_social[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>18</td>
                                <td>{t('secondary_special')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={secondary_special_social[0]}
                                            onChange={(e) => setSecondarySpecialSocial([e.target.value, secondary_special_social[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={secondary_special_social[1]}
                                            onChange={(e) => setSecondarySpecialSocial([secondary_special_social[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>19</td>
                                <td>{t('secondary')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={secondary_social[0]}
                                            onChange={(e) => setSecondarySocial([e.target.value, secondary_social[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={secondary_social[1]}
                                            onChange={(e) => setSecondarySocial([secondary_social[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td>{t('first_education')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={first_education_social[0]}
                                            onChange={(e) => setFirstEducationSocial([e.target.value, first_education_social[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={first_education_social[1]}
                                            onChange={(e) => setFirstEducationSocial([first_education_social[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>21</td>
                                <td>{t('no_education')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={no_education_social[0]}
                                            onChange={(e) => setNoEducationSocial([e.target.value, no_education_social[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={no_education_social[1]}
                                            onChange={(e) => setNoEducationSocial([no_education_social[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            {by_seducation1_error[0] || by_seducation2_error[0] ?
                                <tr className={'error-tr alert alert-danger'}>
                                    <td></td>
                                    <th>{t('save_error')} {t('end_reporting_not_true')}</th>
                                    <td>{by_seducation1_error[0] ? end_reporting_social[0]+' ≠ '+by_seducation1_error[1] : ''}</td>
                                    <td>{by_seducation2_error[0] ? end_reporting_social[1]+' ≠ '+by_seducation2_error[1] : ''}</td>
                                </tr>
                                : ''}
                            <tr>
                                <td></td>
                                <th>{t('addition')}</th>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>22</td>
                                <td>{t('know_brail')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={know_brail_social[0]}
                                            onChange={(e) => setKnowBrailSocial([e.target.value, know_brail_social[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={know_brail_social[1]}
                                            onChange={(e) => setKnowBrailSocial([know_brail_social[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>23</td>
                                <td>{t('know_computer')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={know_computer_social[0]}
                                            onChange={(e) => setKnowComputerSocial([e.target.value, know_computer_social[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={know_computer_social[1]}
                                            onChange={(e) => setKnowComputerSocial([know_computer_social[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>4. {t('at_work')}</h5>
                        </div>
                        <table className={'table table-bordered'}>
                            <thead>
                            <tr>
                                <th>№</th>
                                <th>{t('indicators')}</th>
                                <th>{t('in_quarter')}</th>
                                <th>{t('in_yearly')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>{t('blind_workers')} ({t('all_sum')}: {t('A')} + {t('B')} + {t('V')})</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={blind_workers[0]}
                                            disabled
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={blind_workers[1]}
                                            disabled
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('A')}</td>
                                <td>{t('group1')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group1_workers[0]}
                                            onChange={(e) => setGroup1Workers([e.target.value, group1_workers[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group1_workers[1]}
                                            onChange={(e) => setGroup1Workers([group1_workers[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('B')}</td>
                                <td>{t('group2')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group2_workers[0]}
                                            onChange={(e) => setGroup2Workers([e.target.value, group2_workers[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group2_workers[1]}
                                            onChange={(e) => setGroup2Workers([group2_workers[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('V')}</td>
                                <td>{t('group3')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group3_workers[0]}
                                            onChange={(e) => setGroup3Workers([e.target.value, group3_workers[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={group3_workers[1]}
                                            onChange={(e) => setGroup3Workers([group3_workers[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>{t('koj_workers')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={koj_workers[0]}
                                            onChange={(e) => setKojWorkers([e.target.value, koj_workers[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={koj_workers[1]}
                                            onChange={(e) => setKojWorkers([koj_workers[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>{t('koj_workers_social')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={koj_workers_social[0]}
                                            onChange={(e) => setKojWorkersSocial([e.target.value, koj_workers_social[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={koj_workers_social[1]}
                                            onChange={(e) => setKojWorkersSocial([koj_workers_social[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>{t('koj_workers_nosocial')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={koj_workers_nosocial[0]}
                                            disabled
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={koj_workers_nosocial[1]}
                                            disabled
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('A')}</td>
                                <td>{t('field_of_culture')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={field_of_culture[0]}
                                            onChange={(e) => setFieldCulture([e.target.value, field_of_culture[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={field_of_culture[1]}
                                            onChange={(e) => setFieldCulture([field_of_culture[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('B')}</td>
                                <td>{t('public_education')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={public_education[0]}
                                            onChange={(e) => setPublicEducation([e.target.value, public_education[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={public_education[1]}
                                            onChange={(e) => setPublicEducation([public_education[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('V')}</td>
                                <td>{t('higher_education')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={higher_education[0]}
                                            onChange={(e) => setHigherEducation([e.target.value, higher_education[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={higher_education[1]}
                                            onChange={(e) => setHigherEducation([higher_education[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('C')}</td>
                                <td>{t('special_education')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={special_education[0]}
                                            onChange={(e) => setSpecialEducation([e.target.value, special_education[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={special_education[1]}
                                            onChange={(e) => setSpecialEducation([special_education[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('D')}</td>
                                <td>{t('other_areas')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={other_areas[0]}
                                            onChange={(e) => setOtherAreas([e.target.value, other_areas[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={other_areas[1]}
                                            onChange={(e) => setOtherAreas([other_areas[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>

                            <tr>
                                <td>5</td>
                                <td>{t('community_system')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={community_system[0]}
                                            disabled
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={community_system[1]}
                                            disabled
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('A')}</td>
                                <td>{t('other_disabilities')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={other_disabilities[0]}
                                            onChange={(e) => setOtherDisabilities([e.target.value, other_disabilities[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={other_disabilities[1]}
                                            onChange={(e) => setOtherDisabilities([other_disabilities[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('B')}</td>
                                <td>{t('healthy_emps')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={healthy_emps[0]}
                                            onChange={(e) => setHealthyEmps([e.target.value, healthy_emps[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={healthy_emps[1]}
                                            onChange={(e) => setHealthyEmps([healthy_emps[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            </tbody>
                            <thead>
                            <tr><hr/></tr>
                            <tr>
                                <th>№</th>
                                <th>{t('indicators')}</th>
                                <th>{t('in_quarter')}</th>
                                <th>{t('in_yearly')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>{t('new_workers')} ({t('all_sum')}: {t('A')} + {t('B')} + {t('V')})</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={new_workers[0]}
                                            disabled
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={new_workers[1]}
                                            disabled
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('A')}</td>
                                <td>{t('new_koj_workers')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={new_koj_workers[0]}
                                            onChange={(e) => setNewKojWorkers([e.target.value, new_koj_workers[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={new_koj_workers[1]}
                                            onChange={(e) => setNewKojWorkers([new_koj_workers[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('B')}</td>
                                <td>{t('new_koj_social_workers')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={new_koj_social_workers[0]}
                                            onChange={(e) => setNewKojSocialWorkers([e.target.value, new_koj_social_workers[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={new_koj_social_workers[1]}
                                            onChange={(e) => setNewKojSocialWorkers([new_koj_social_workers[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('V')}</td>
                                <td>{t('new_koj_nosocial_workers')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={new_koj_nosocial_workers[0]}
                                            disabled
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={new_koj_nosocial_workers[1]}
                                            disabled
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>

                            <tr>
                                <td></td>
                                <td>{t('field_of_culture')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={field_of_culture_new[0]}
                                            onChange={(e) => setFieldCultureNew([e.target.value, field_of_culture_new[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={field_of_culture_new[1]}
                                            onChange={(e) => setFieldCultureNew([field_of_culture_new[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>{t('public_education')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={public_education_new[0]}
                                            onChange={(e) => setPublicEducationNew([e.target.value, public_education_new[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={public_education_new[1]}
                                            onChange={(e) => setPublicEducationNew([public_education_new[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>{t('higher_education')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={higher_education_new[0]}
                                            onChange={(e) => setHigherEducationNew([e.target.value, higher_education_new[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={higher_education_new[1]}
                                            onChange={(e) => setHigherEducationNew([higher_education_new[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>{t('special_education')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={special_education_new[0]}
                                            onChange={(e) => setSpecialEducationNew([e.target.value, special_education_new[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={special_education_new[1]}
                                            onChange={(e) => setSpecialEducationNew([special_education_new[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>{t('other_areas')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={other_areas_new[0]}
                                            onChange={(e) => setOtherAreasNew([e.target.value, other_areas_new[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={other_areas_new[1]}
                                            onChange={(e) => setOtherAreasNew([other_areas_new[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>

                            </tbody>
                        </table>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>5. {t('learners')}</h5>
                        </div>
                        <table className={'table table-bordered'}>
                            <thead>
                            <tr>
                                <th>№</th>
                                <th>{t('indicators')}</th>
                                <th>{t('number')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>{t('all_learners')} ({t('all_sum')}: {t('A')} + {t('B')} + {t('V')} + {t('G')} + {t('D')} + {t('E')} + {t('F')})</td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={all_learners}
                                            disabled
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('A')}</td>
                                <td>{t('learners_highly')}</td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={learners_highly}
                                            onChange={(e) => setLearnersHighly(e.target.value)}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('B')}</td>
                                <td>{t('learners_special_secondary')}</td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={learners_special_secondary}
                                            onChange={(e) => setSpecialSecondary(e.target.value)}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('V')}</td>
                                <td>{t('special_educational')}</td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={special_educational}
                                            onChange={(e) => setSpecialEducational(e.target.value)}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('G')}</td>
                                <td>{t('learners_state')}</td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={learners_state}
                                            onChange={(e) => setAllState(e.target.value)}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('D')}</td>
                                <td>{t('learners_general')}</td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={learners_general}
                                            onChange={(e) => setAllGeneral(e.target.value)}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('E')}</td>
                                <td>{t('learners_ind')}</td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={learners_ind}
                                            onChange={(e) => setAllInd(e.target.value)}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('F')}</td>
                                <td>{t('students_preschool')}</td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={students_preschool}
                                            onChange={(e) => setStudentsPreschool(e.target.value)}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>6. {t('facilities')}</h5>
                        </div>
                        <table className={'table table-bordered'}>
                            <thead>
                            <tr>
                                <th colSpan={4}>{t('facilities_social')}</th>
                            </tr>
                            <tr>
                                <th>№</th>
                                <th>{t('indicators')}</th>
                                <th>{t('in_quarter')}</th>
                                <th>{t('in_yearly')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>{t('uzkoj_yangyol')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzkoj_yangyol[0]}
                                            onChange={(e) => setUzKojYangyol([e.target.value, uzkoj_yangyol[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzkoj_yangyol[1]}
                                            onChange={(e) => setUzKojYangyol([uzkoj_yangyol[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>{t('uzkoj_kosonsoy')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzkoj_kosonsoy[0]}
                                            onChange={(e) => setUzKojKosonsoy([e.target.value, uzkoj_kosonsoy[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzkoj_kosonsoy[1]}
                                            onChange={(e) => setUzKojKosonsoy([uzkoj_kosonsoy[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>{t('uzkoj_other')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzkoj_other[0]}
                                            onChange={(e) => setUzKojOther([e.target.value, uzkoj_other[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzkoj_other[1]}
                                            onChange={(e) => setUzKojOther([uzkoj_other[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>{t('uzkoj_outside_centers')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzkoj_outside_centers[0]}
                                            onChange={(e) => setUzKojOutsideCenters([e.target.value, uzkoj_outside_centers[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzkoj_outside_centers[1]}
                                            onChange={(e) => setUzKojOutsideCenters([uzkoj_outside_centers[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>{t('uzkoj_outside_home')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzkoj_outside_home[0]}
                                            onChange={(e) => setUzKojOutsideHome([e.target.value, uzkoj_outside_home[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzkoj_outside_home[1]}
                                            onChange={(e) => setUzKojOutsideHome([uzkoj_outside_home[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>{t('prophylactics_outside')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={prophylactics_outside[0]}
                                            onChange={(e) => setProphylacticsOutside([e.target.value, prophylactics_outside[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={prophylactics_outside[1]}
                                            onChange={(e) => setProphylacticsOutside([prophylactics_outside[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>{t('children_camps')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={children_camps[0]}
                                            onChange={(e) => setChildrenCamps([e.target.value, children_camps[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={children_camps[1]}
                                            onChange={(e) => setChildrenCamps([children_camps[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <th>8</th>
                                <th>{t('all_sum')}</th>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={to_holiday_social_all[0]}
                                            disabled onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={to_holiday_social_all[1]}
                                            disabled onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            </tbody>
                            <thead>
                            <tr><hr/></tr>
                            <tr>
                                <th colSpan={4}>{t('facilities_other')}</th>
                            </tr>
                            <tr>
                                <th>№</th>
                                <th>{t('indicators')}</th>
                                <th>{t('in_quarter')}</th>
                                <th>{t('in_yearly')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>{t('uzkoj_yangyol')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzkoj_yangyol_other[0]}
                                            onChange={(e) => setUzKojYangyolOther([e.target.value, uzkoj_yangyol_other[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzkoj_yangyol_other[1]}
                                            onChange={(e) => setUzKojYangyolOther([uzkoj_yangyol_other[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>{t('uzkoj_kosonsoy')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzkoj_kosonsoy_other[0]}
                                            onChange={(e) => setUzKojKosonsoyOther([e.target.value, uzkoj_kosonsoy_other[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzkoj_kosonsoy_other[1]}
                                            onChange={(e) => setUzKojKosonsoyOther([uzkoj_kosonsoy_other[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>{t('uzkoj_other')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzkoj_other_other[0]}
                                            onChange={(e) => setUzKojOtherOther([e.target.value, uzkoj_other_other[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzkoj_other_other[1]}
                                            onChange={(e) => setUzKojOtherOther([uzkoj_other_other[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>{t('uzkoj_outside_centers')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzkoj_outside_centers_other[0]}
                                            onChange={(e) => setUzKojOutsideCentersOther([e.target.value, uzkoj_outside_centers_other[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzkoj_outside_centers_other[1]}
                                            onChange={(e) => setUzKojOutsideCentersOther([uzkoj_outside_centers_other[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>{t('uzkoj_outside_home')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzkoj_outside_home_other[0]}
                                            onChange={(e) => setUzKojOutsideHomeOther([e.target.value, uzkoj_outside_home_other[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={uzkoj_outside_home_other[1]}
                                            onChange={(e) => setUzKojOutsideHomeOther([uzkoj_outside_home_other[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>{t('prophylactics_outside')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={prophylactics_outside_other[0]}
                                            onChange={(e) => setProphylacticsOutsideOther([e.target.value, prophylactics_outside_other[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={prophylactics_outside_other[1]}
                                            onChange={(e) => setProphylacticsOutsideOther([prophylactics_outside_other[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>{t('children_camps')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={children_camps_other[0]}
                                            onChange={(e) => setChildrenCampsOther([e.target.value, children_camps_other[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={children_camps_other[1]}
                                            onChange={(e) => setChildrenCampsOther([children_camps_other[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <th>8</th>
                                <th>{t('all_sum')}</th>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={to_holiday_other_all[0]}
                                            disabled onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={to_holiday_other_all[1]}
                                            disabled onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>7. {t('material_support')}</h5>
                        </div>
                        <table className={'table table-bordered'}>
                            <thead>
                            <tr>
                                <th>№</th>
                                <th>{t('indicators')}</th>
                                <th>{t('in_quarter')}</th>
                                <th>{t('in_yearly')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>{t('total_amount')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={total_amount[0]}
                                            disabled
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={total_amount[1]}
                                            disabled
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('A')}</td>
                                <td>{t('membership_fees')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={membership_fees[0]}
                                            onChange={(e) => setMembershipFees([e.target.value, membership_fees[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={membership_fees[1]}
                                            onChange={(e) => setMembershipFees([membership_fees[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('B')}</td>
                                <td>{t('amount_appropriations')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={amount_appropriations[0]}
                                            onChange={(e) => setAmountAppropriations([e.target.value, amount_appropriations[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={amount_appropriations[1]}
                                            onChange={(e) => setAmountAppropriations([amount_appropriations[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('V')}</td>
                                <td>{t('state_budget')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={state_budget[0]}
                                            onChange={(e) => setStateBudget([e.target.value, state_budget[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={state_budget[1]}
                                            onChange={(e) => setStateBudget([state_budget[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('C')}</td>
                                <td>{t('sponsorship_donation')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={sponsorship_donation[0]}
                                            onChange={(e) => setSponsorshipDonation([e.target.value, sponsorship_donation[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={sponsorship_donation[1]}
                                            onChange={(e) => setSponsorshipDonation([sponsorship_donation[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('D')}</td>
                                <td>{t('grant_funds')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={grant_funds[0]}
                                            onChange={(e) => setGrantFunds([e.target.value, grant_funds[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={grant_funds[1]}
                                            onChange={(e) => setGrantFunds([grant_funds[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>{t('sponsorships')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={sponsorships[0]}
                                            onChange={(e) => setSponsors([e.target.value, sponsorships[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={sponsorships[1]}
                                            onChange={(e) => setSponsors([sponsorships[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>{t('physical_assistance')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={physical_assistance[0]}
                                            disabled
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={physical_assistance[1]}
                                            disabled
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('A')}</td>
                                <td>{t('financial_assistance')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={financial_assistance[0]}
                                            onChange={(e) => setFinancialAssistance([e.target.value, financial_assistance[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={financial_assistance[1]}
                                            onChange={(e) => setFinancialAssistance([financial_assistance[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('B')}</td>
                                <td>{t('financial_support')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={financial_support[0]}
                                            onChange={(e) => setFinancialSupport([e.target.value, financial_support[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={financial_support[1]}
                                            onChange={(e) => setFinancialSupport([financial_support[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('V')}</td>
                                <td>{t('inkind_help')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={inkind_help[0]}
                                            onChange={(e) => setInkendHelp([e.target.value, inkind_help[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={inkind_help[1]}
                                            onChange={(e) => setInkendHelp([inkind_help[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('C')}</td>
                                <td>{t('material_aid')}</td>
                                <td><input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={material_aid[0]}
                                            onChange={(e) => setMaterialAid([e.target.value, material_aid[1]])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                <td>
                                    <input  type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                            onInput={F => F.target.setCustomValidity('')}  required min="0" disabled={is_main} value={material_aid[1]}
                                            onChange={(e) => setMaterialAid([material_aid[0], e.target.value])}
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>

                            </tbody>
                        </table>
                        {!is_main ?
                            <div className="button-group">
                                <Button type={'submit'} style={{width: '25%'}} variant="contained"
                                        fullWidth color={'info'} onClick={() => setIsSend(false)}>
                                    {t('save')}
                                </Button>
                                <Button type={'submit'} style={{marginLeft: '20px', width: '25%'}} variant="contained"
                                        fullWidth color={'success'} onClick={() => setIsSend(true)}>
                                    {t('send')}
                                </Button>
                            </div> :
                            <div className="button-group">
                                <Button type={'submit'} style={{width: '25%'}} variant="contained"
                                        fullWidth color={'error'} onClick={CancelReport}>
                                    {t('to_canceled')}
                                </Button>
                                <Button type={'submit'} style={{marginLeft: '20px', width: '25%'}} variant="contained"
                                        fullWidth color={'success'} onClick={() => setIsStatus(4)}>
                                    {t('to_accepted')}
                                </Button>
                            </div>
                        }
                    </form>
                </div>
                <Copyright/>
            </Box>
        </div>
    </div>
}

export default connect(props => props.stats, {getStat, editSaveStats, getAllVerifyResponse, messageReset})(EditStat)