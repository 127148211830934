import Header from "../blocks/header";
import Dashboard from "../blocks/dashboard";
import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Copyright from "../blocks/copyright";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import GroupsIcon from '@mui/icons-material/Groups';
import EventIcon from '@mui/icons-material/Event';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {Link, useNavigate, useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";

function Main() {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const params = useParams()
    const [regionId, setRegionId] = useState(false)
    useEffect(()=>{
        setRegionId(params.id)
    }, [params.id])

    const toBack = () => {
        navigate(-1);
    }

    return (
        <div className="App">
            <div className={'home page'}>
                <Header/>
                <Dashboard/>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}>
                    <Toolbar/>
                    <div className={'main'}>
                        <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={toBack}>
                            {t('back')}
                        </Button>
                        <div className="row">
                            <div className="col-lg-3">
                                <Link to={regionId ? '/documents/'+regionId : '/documents/'}>
                                    <div className="card">
                                        <div className="card-header">
                                            <FilePresentIcon className={'icon-card'}/>
                                        </div>
                                        <div className="card-body">
                                            <h3 className={'text-center mb-0 text-uppercase'}>{t('required_documents')}</h3>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-3">
                                <Link to={regionId ? '/problems/'+regionId : '/problems/'}>
                                    <div className="card">
                                        <div className="card-header">
                                            <SyncProblemIcon className={'icon-card'}/>
                                        </div>
                                        <div className="card-body">
                                            <h3 className={'text-center mb-0 text-uppercase'}>{t('problems')}</h3>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-3">
                                <Link to={regionId ? '/workers/'+regionId : '/workers/'}>
                                    <div className="card">
                                        <div className="card-header">
                                            <FolderSharedIcon className={'icon-card'}/>
                                        </div>
                                        <div className="card-body">
                                            <h3 className={'text-center mb-0 text-uppercase'}>{t('list_employees')}</h3>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-3">
                                <Link to={regionId ? '/members/'+regionId : '/members/'}>
                                    <div className="card">
                                        <div className="card-header">
                                            <GroupsIcon className={'icon-card'}/>
                                        </div>
                                        <div className="card-body">
                                            <h3 className={'text-center mb-0 text-uppercase'}>{t('members')}</h3>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-3">
                                <Link to={regionId ? '/stats/'+regionId : '/stats/'}>
                                    <div className="card">
                                        <div className="card-header">
                                            <QueryStatsIcon className={'icon-card'}/>
                                        </div>
                                        <div className="card-body">
                                            <h3 className={'text-center mb-0 text-uppercase'}>{t('stats')}</h3>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-3">
                                <Link to={regionId ? '/events/'+regionId : '/events/'}>
                                    <div className="card">
                                        <div className="card-header">
                                            <EventIcon className={'icon-card'}/>
                                        </div>
                                        <div className="card-body">
                                            <h3 className={'text-center mb-0 text-uppercase'}>{t('events')}</h3>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <Copyright/>
                </Box>
            </div>
        </div>
    );
}

export default Main;
