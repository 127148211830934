import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'stats',
    initialState: {stats: [], message: false, stats_single: false},
    reducers:{
        getAllStat: (state,action)=>{
            state.stats = action.payload
        },
        getOneStat: (state,action)=>{
            state.stats_single = action.payload
        },
        saveStat: (state,action)=>{
            state.stats.unshift(action.payload)
            state.message = true
        },
        editStat: (state,action)=>{
            state.stats_single = action.payload
            state.stats = state.stats.map(item=>item.id === action.payload.id ? action.payload : item)
            state.message = true
        },
        deleteToStat: (state,action)=>{
            state.stats = state.stats.filter(item=>item.id !== action.payload.id)
            state.message = 'deleted'
        },
        messageReset: (state,action)=>{
            state.message = false
        },
        messageFail: (state,action)=>{
            state.message = 'error'
        }
    }
})

export const getStats = () => apiCall({
    url: 'stats/',
    method: 'GET',
    onSuccess: slice.actions.getAllStat.type
})

export const statSave = (data) => apiCall({
    url: 'stats/',
    method: 'POST',
    onSuccess: slice.actions.saveStat.type,
    onFail: slice.actions.messageFail.type,
    data,
})

export const getStat = (id) => apiCall({
    url: 'stats/'+id,
    method: 'GET',
    onSuccess: slice.actions.getOneStat.type,
})

export const editSaveStats = (data) => apiCall({
    url: 'stats/'+data.id+'/',
    method: 'PUT',
    onSuccess: slice.actions.editStat.type,
    onFail: slice.actions.messageFail.type,
    data,
})

export const deleteStats = (data) => apiCall({
    url: 'stats/'+data.id+'/',
    method: 'DELETE',
    onSuccess: slice.actions.deleteToStat.type,
    onFail: slice.actions.messageFail.type,
})

export default slice.reducer
export const {messageReset, getAllStat} = slice.actions
