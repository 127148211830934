import {useEffect, useState} from "react";
import Header from "../blocks/header";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import Box from "@mui/material/Box";
import {connect} from "react-redux";
import {getAllVerifyResponse} from "../store/logIn";
import Dashboard from "../blocks/dashboard";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import Copyright from "../blocks/copyright";
import {useTranslation} from "react-i18next";
import {DatePicker} from "@mui/x-date-pickers";
import {useNavigate, useParams} from "react-router-dom";
import {getEvent} from "../store/events";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";


function ViewEvent(props) {

    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const toBack = () => {
        navigate(-1);
    }
    const [isSend, setIsSend] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [hasError1, setHasError1] = useState(false);
    const [report, setReport] = useState('');
    const [year, setYear] = useState(new Date("2022-01-01"));
    const [period, setPeriod] = useState('');
    const [ktut, setKtut] = useState('');
    const [mhobt, setMhobt] = useState('');
    const [xxtut, setXxtut] = useState('');
    const [address, setAddress] = useState('');


    const [f_theme_nights, setThemeNights] = useState(['', '', '']);
    const [l_theme_nights, setlThemeNights] = useState(['', '', '']);
    const [f_interviews, setInterviews] = useState(['', '', '']);
    const [l_interviews, setLInterviews] = useState(['', '', '']);
    const [f_meetings, setMeetings] = useState(['', '', '']);
    const [l_meetings, setLMeetings] = useState(['', '', '']);
    const [f_selections, setSelections] = useState(['', '', '']);
    const [l_selections, setLSelections] = useState(['', '', '']);
    const [f_trips, setTrips] = useState(['', '', '']);
    const [l_trips, setLTrips] = useState(['', '', '']);
    const [f_concerts, setConcerts] = useState(['', '', '']);
    const [l_concerts, setLConcerts] = useState(['', '', '']);
    const [f_other_concerts, setOtherConcerts] = useState(['', '', '']);
    const [l_other_concerts, setLOtherConcerts] = useState(['', '', '']);
    const [f_festive_events, setFestiveEvents] = useState(['', '', '']);
    const [l_festive_events, setLFestiveEvents] = useState(['', '', '']);
    const [f_competitions, setCompetitions] = useState(['', '', '']);
    const [l_competitions, setLCompetitions] = useState(['', '', '']);

    const [f_lectures, setFLectures] = useState(['', '', '']);
    const [l_lectures, setLLectures] = useState(['', '', '']);
    const [f_literary_evenings, setFLiteraryEvenings] = useState(['', '', '']);
    const [l_literary_evenings, setLLiteraryEvenings] = useState(['', '', '']);
    const [f_reading_conference, setFReadingConference] = useState(['', '', '']);
    const [l_reading_conference, setLReadingConference] = useState(['', '', '']);

    const [rec_centers, setRecCenters] = useState('');
    const [special_libs, setSpecialLibs] = useState('');
    const [libs_users, setLibsUsers] = useState('');
    const [all_circles, setAllCircles] = useState('');
    const [circles_parts, setCirclesParts] = useState('');
    const [art_circles, setArtCircles] = useState('');
    const [art_parts, setArtParts] = useState('');
    const [sport_circles, setSportCircles] = useState('');
    const [sport_parts, setSportParts] = useState('');
    const [other_circles, setotherCircles] = useState('');
    const [other_parts, setOtherParts] = useState('');
    const [uzkoj_plenum, setUzkojPlenum] = useState(['', '']);
    const [l_uzkoj_plenum, setLUzkojPlenum] = useState(['', '']);
    const [regional_meets, setRegionalMeets] = useState(['', '']);
    const [l_regional_meets, setLRegionalMeets] = useState(['', '']);
    const [society_meets, setSocietyMeets] = useState(['', '']);
    const [l_society_meets, setLSocietyMeets] = useState(['', '']);
    const [ntk_meets, setNtkMeets] = useState(['', '']);
    const [l_ntk_meets, setLNtkMeets] = useState(['', '']);
    const [ntk_s_meets, setNtkSMeets] = useState(['', '']);
    const [l_ntk_s_meets, setLNtkSMeets] = useState(['', '']);
    const [collect_fees, setCollectFees] = useState(['', '']);
    const [l_collect_fees, setLCollectFees] = useState(['', '']);
    const [seminars, setSeminars] = useState(['', '']);
    const [l_seminars, setLSeminars] = useState(['', '']);
    const [identification, setIdentification] = useState(['', '']);
    const [l_identification, setLIdentification] = useState(['', '']);
    const [involve, setInvolve] = useState(['', '']);
    const [l_involve, setLInvolve] = useState(['', '']);
    const [examination, setExamination] = useState(['', '']);
    const [l_examination, setLExamination] = useState(['', '']);
    const [referrals, setReferrals] = useState(['', '']);
    const [l_referrals, setLReferrals] = useState(['', '']);
    const [enterprises, setEnterprises] = useState(['', '']);
    const [l_enterprises, setLEnterprises] = useState(['', '']);
    const [o_enterprises, setOEnterprises] = useState(['', '']);
    const [lo_enterprises, setLOEnterprises] = useState(['', '']);
    const [provision, setProvision] = useState(['', '']);
    const [l_provision, setLProvision] = useState(['', '']);
    const [lvision, setLvision] = useState(['', '']);
    const [l_lvision, setLLvision] = useState(['', '']);
    const [vocational, setVocational] = useState(['', '']);
    const [l_vocational, setLVocational] = useState(['', '']);
    const [bliteracy, setBliteracy] = useState(['', '']);
    const [l_bliteracy, setLBliteracy] = useState(['', '']);
    const [cliteracy, setCliteracy] = useState(['', '']);
    const [l_cliteracy, setLCliteracy] = useState(['', '']);
    const [number_volunteers, setNumberVolunteers] = useState(['', '']);
    const [l_number_volunteers, setLNumberVolunteers] = useState(['', '']);
    const [fassistance, setFassistance] = useState(['', '']);
    const [l_fassistance, setLFassistance] = useState(['', '']);
    const [p_support, setPsupport] = useState(['', '']);
    const [l_p_support, setLPsupport] = useState(['', '']);
    const [l_assistance, setLassistance] = useState(['', '']);
    const [l_l_assistance, setLLassistance] = useState(['', '']);
    const [benefits, setBenefits] = useState(['', '']);
    const [l_benefits, setLBenefits] = useState(['', '']);
    const [uzkoj_mbk, setUzkojMBK] = useState(['', '']);
    const [l_uzkoj_mbk, setLUzkojMBK] = useState(['', '']);
    const [event_provisions, setEventProvisions] = useState([{title: '', provision1: '',
        provision2: '', l_provision1: '', l_provision2: ''}]);

    const params = useParams()

    function AddProvision(){
        var data = {title: '', provision1: '', provision2: '', l_provision1: '', l_provision2: ''}
        return setEventProvisions([...event_provisions, data])
    }

    function RemoveProvision(){
        let my_event_provisions = [...event_provisions]
        my_event_provisions.pop()
        return setEventProvisions([...my_event_provisions])
    }

    function changeTitle(itemIndex, val) {
        setEventProvisions(prevState => prevState.map((item,index) => index === itemIndex ?
            {...item, title: val}
            : item))
    }

    function changeProvision1(itemIndex, val) {
        setEventProvisions(prevState => prevState.map((item,index) => index === itemIndex ?
            {...item, provision1: Number(val)}
            : item))
    }

    function changeProvision2(itemIndex, val) {
        setEventProvisions(prevState => prevState.map((item,index) => index === itemIndex ?
            {...item, provision2: Number(val)}
            : item))
    }

    function changeLProvision1(itemIndex, val) {
        setEventProvisions(prevState => prevState.map((item,index) => index === itemIndex ?
            {...item, l_provision1: Number(val)}
            : item))
    }

    function changeLProvision2(itemIndex, val) {
        setEventProvisions(prevState => prevState.map((item,index) => index === itemIndex ?
            {...item, l_provision2: Number(val)}
            : item))
    }

    useEffect(()=>{
        props.getEvent(params.id)
    }, [])
    
    useEffect(()=>{
        setReport(props.events_single.type)
        setYear(new Date(props.events_single.year))
        setPeriod(props.events_single.month)
        setIsSend(props.events_single.is_send)
        setKtut(props.events_single.ktut)
        setMhobt(props.events_single.mhobt)
        setXxtut(props.events_single.xxtut)
        setAddress(props.events_single.address)
        setEventProvisions(props.events_single.event_provisions && props.events_single.event_provisions.length > 0
            ? props.events_single.event_provisions :[{title: '', provision1: '',
                provision2: '', l_provision1: '', l_provision2: ''}])
        setThemeNights([props.events_single.f_theme_nights1,props.events_single.f_theme_nights2,props.events_single.f_theme_nights3])
        setlThemeNights([props.events_single.l_theme_nights1,props.events_single.l_theme_nights2,props.events_single.l_theme_nights3])
        setInterviews([props.events_single.f_interviews1,props.events_single.f_interviews2,props.events_single.f_interviews3])
        setLInterviews([props.events_single.l_interviews1,props.events_single.l_interviews2,props.events_single.l_interviews3])
        setMeetings([props.events_single.f_meetings1,props.events_single.f_meetings2,props.events_single.f_meetings3])
        setLMeetings([props.events_single.l_meetings1,props.events_single.l_meetings2,props.events_single.l_meetings3])
        setSelections([props.events_single.f_selections1,props.events_single.f_selections2,props.events_single.f_selections3])
        setLSelections([props.events_single.l_selections1,props.events_single.l_selections2,props.events_single.l_selections3])
        setTrips([props.events_single.f_trips1,props.events_single.f_trips2,props.events_single.f_trips3])
        setLTrips([props.events_single.l_trips1,props.events_single.l_trips2,props.events_single.l_trips3])
        setConcerts([props.events_single.f_concerts1,props.events_single.f_concerts2,props.events_single.f_concerts3])
        setLConcerts([props.events_single.l_concerts1,props.events_single.l_concerts2,props.events_single.l_concerts3])
        setOtherConcerts([props.events_single.f_other_concerts1,props.events_single.f_other_concerts2,props.events_single.f_other_concerts3])
        setLOtherConcerts([props.events_single.l_other_concerts1,props.events_single.l_other_concerts2,props.events_single.l_other_concerts3])
        setFestiveEvents([props.events_single.f_festive_events1,props.events_single.f_festive_events2,props.events_single.f_festive_events3])
        setLFestiveEvents([props.events_single.l_festive_events1,props.events_single.l_festive_events2,props.events_single.l_festive_events3])
        setCompetitions([props.events_single.f_competitions1,props.events_single.f_competitions2,props.events_single.f_competitions3])
        setLCompetitions([props.events_single.l_competitions1,props.events_single.l_competitions2,props.events_single.l_competitions3])

        setFLectures([props.events_single.f_lectures1,props.events_single.f_lectures2,props.events_single.f_lectures3])
        setLLectures([props.events_single.l_lectures1,props.events_single.l_lectures2,props.events_single.l_lectures3])
        setFLiteraryEvenings([props.events_single.f_literary_evenings1,props.events_single.f_literary_evenings2,props.events_single.f_literary_evenings3])
        setLLiteraryEvenings([props.events_single.l_literary_evenings1,props.events_single.l_literary_evenings2,props.events_single.l_literary_evenings3])
        setFReadingConference([props.events_single.f_reading_conference1,props.events_single.f_reading_conference2,props.events_single.f_reading_conference3])
        setLReadingConference([props.events_single.l_reading_conference1,props.events_single.l_reading_conference2,props.events_single.l_reading_conference3])

        setNumberVolunteers([props.events_single.number_volunteers1,props.events_single.number_volunteers2])
        setLNumberVolunteers([props.events_single.l_number_volunteers1,props.events_single.l_number_volunteers2])

        setRecCenters(props.events_single.rec_centers)
        setSpecialLibs(props.events_single.special_libs)
        setLibsUsers(props.events_single.libs_users)
        setAllCircles(props.events_single.all_circles)
        setCirclesParts(props.events_single.circles_parts)
        setArtCircles(props.events_single.art_circles)
        setArtParts(props.events_single.art_parts)
        setSportCircles(props.events_single.sport_circles)
        setSportParts(props.events_single.sport_parts)
        setotherCircles(props.events_single.other_circles)
        setOtherParts(props.events_single.other_parts)

        setUzkojPlenum([props.events_single.uzkoj_plenum1,props.events_single.uzkoj_plenum2])
        setLUzkojPlenum([props.events_single.l_uzkoj_plenum1,props.events_single.l_uzkoj_plenum1])
        setRegionalMeets([props.events_single.regional_meets1,props.events_single.regional_meets2])
        setLRegionalMeets([props.events_single.l_regional_meets1,props.events_single.l_regional_meets2])
        setSocietyMeets([props.events_single.society_meets1,props.events_single.society_meets2])
        setLSocietyMeets([props.events_single.l_society_meets1,props.events_single.l_society_meets2])
        setUzkojMBK([props.events_single.uzkoj_mbk1,props.events_single.uzkoj_mbk2])
        setLUzkojMBK([props.events_single.l_uzkoj_mbk1,props.events_single.l_uzkoj_mbk2])
        setNtkMeets([props.events_single.ntk_meets1,props.events_single.ntk_meets2])
        setLNtkMeets([props.events_single.l_ntk_meets1,props.events_single.l_ntk_meets2])
        setNtkSMeets([props.events_single.ntk_s_meets1,props.events_single.ntk_s_meets2])
        setLNtkSMeets([props.events_single.l_ntk_s_meets1,props.events_single.l_ntk_s_meets2])
        setCollectFees([props.events_single.collect_fees1,props.events_single.collect_fees2])
        setLCollectFees([props.events_single.l_collect_fees1,props.events_single.l_collect_fees2])
        setSeminars([props.events_single.seminars1,props.events_single.seminars2])
        setLSeminars([props.events_single.l_seminars1,props.events_single.l_seminars2])
        setIdentification([props.events_single.identification1,props.events_single.identification2])
        setLIdentification([props.events_single.l_identification1,props.events_single.l_identification2])
        setInvolve([props.events_single.involve1,props.events_single.involve2])
        setLInvolve([props.events_single.l_involve1,props.events_single.l_involve2])
        setExamination([props.events_single.examination1,props.events_single.examination2])
        setLExamination([props.events_single.l_examination1,props.events_single.l_examination2])
        setReferrals([props.events_single.referrals1,props.events_single.referrals2])
        setLReferrals([props.events_single.l_referrals1,props.events_single.l_referrals2])
        setEnterprises([props.events_single.enterprises1,props.events_single.enterprises2])
        setLEnterprises([props.events_single.l_enterprises1,props.events_single.l_enterprises2])
        setOEnterprises([props.events_single.o_enterprises1,props.events_single.o_enterprises2])
        setLOEnterprises([props.events_single.lo_enterprises1,props.events_single.lo_enterprises2])
        setProvision([props.events_single.provision1,props.events_single.provision2])
        setLProvision([props.events_single.l_provision1,props.events_single.l_provision2])
        setLvision([props.events_single.lvision1,props.events_single.lvision2])
        setLLvision([props.events_single.l_lvision1,props.events_single.l_lvision2])
        setVocational([props.events_single.vocational1,props.events_single.vocational2])
        setLVocational([props.events_single.l_vocational1,props.events_single.l_vocational2])
        setBliteracy([props.events_single.bliteracy1,props.events_single.bliteracy2])
        setLBliteracy([props.events_single.l_bliteracy1,props.events_single.l_bliteracy2])
        setCliteracy([props.events_single.cliteracy1,props.events_single.cliteracy2])
        setLCliteracy([props.events_single.l_cliteracy1,props.events_single.l_cliteracy2])
        setFassistance([props.events_single.fassistance1,props.events_single.fassistance2])
        setLFassistance([props.events_single.l_fassistance1,props.events_single.l_fassistance2])
        setPsupport([props.events_single.p_support1,props.events_single.p_support2])
        setLPsupport([props.events_single.l_p_support1,props.events_single.l_p_support2])
        setLassistance([props.events_single.l_assistance1,props.events_single.l_assistance2])
        setLLassistance([props.events_single.l_l_assistance1,props.events_single.l_l_assistance2])
        setBenefits([props.events_single.benefits1,props.events_single.benefits2])
        setLBenefits([props.events_single.l_benefits1,props.events_single.l_benefits2])
    }, [props.events_single])

    return <div>
        <div className={'page addStat addEvent'}>
            <Header/>
            <Dashboard/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}>
                <Toolbar/>
                <div className={'main'}>
                        <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={toBack}>
                            {t('back')}
                        </Button>
                    <h2 className={'page-title text-center'}>{t('type_report')}</h2>
                    {props.events_single && props.events_single.comment ?
                        <div className="error-comment alert alert-danger" role="alert">
                            <h4 className="alert-heading">{t('comment')}</h4>
                            <hr/>
                            <p>{props.events_single.comment}</p>
                        </div> : ''
                    }
                    <form className={'add-form add-stat'}>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>1. {t('report_about')}</h5>
                        </div>
                        <div>
                            <p><span>{t('type')}:</span>
                                <Box sx={{minWidth: 200}}>
                                    <FormControl fullWidth>
                                        <InputLabel error={hasError} required
                                                    id="demo-simple-select-label">{t('type')}</InputLabel>
                                        <Select
                                            error={hasError}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={report}
                                            label={t('type')}
                                            disabled onChange={(e) => {
                                            setReport(e.target.value)
                                        }}
                                        >
                                            <MenuItem value={1}>{t('type_report')}</MenuItem>
                                            <MenuItem value={2}>{t('type_reply_report')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box></p>
                            <p><span>{t('report_year')}:</span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        minDate={new Date("2022-01-01")}
                                        views={['year']}
                                        label={t('report_year')}
                                        value={year}
                                        disabled onChange={(newValue) => {
                                        setYear(newValue);
                                    }}
                                        renderInput={(params) => <TextField required {...params} helperText={null}/>}
                                    />
                                </LocalizationProvider>
                            </p>
                            <p><span>{t('report_month')}:</span>
                                <Box sx={{minWidth: 200}}>
                                    <FormControl fullWidth>
                                        <InputLabel error={hasError1} required
                                                    id="demo-simple-select-label">{t('report_month')}</InputLabel>
                                        <Select
                                            error={hasError1}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={period}
                                            label={t('report_month')}
                                            disabled onChange={(e) => {
                                            setPeriod(e.target.value)
                                        }}
                                        >
                                            <MenuItem value={1}>{t('quarter')}</MenuItem>
                                            <MenuItem value={2}>{t('semi_annual')}</MenuItem>
                                            <MenuItem value={3}>{t('nine_month')}</MenuItem>
                                            <MenuItem value={4}>{t('yearly')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box></p>
                        </div>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>2. {t('about_organization')}</h5>
                        </div>
                        <div>
                            <p>
                                <span>{t('address_organization')}:</span>
                                <TextField value={address} disabled onChange={(e) => setAddress(e.target.value)} required
                                           id="outlined-basic" label={t('address_organization')} variant="outlined"/>
                            </p>
                        </div>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>2.1. {t('unique_numbers')}</h5>
                        </div>
                        <div>
                            <p>
                                <span>{t('ktut')}:</span>
                                <TextField value={ktut} disabled onChange={(e) => setKtut(e.target.value)} required
                                           id="outlined-basic" label={t('ktut')} variant="outlined"/>
                            </p>
                            <p>
                                <span>{t('mhobt')}:</span>
                                <TextField value={mhobt} disabled onChange={(e) => setMhobt(e.target.value)} required
                                           id="outlined-basic" label={t('mhobt')} variant="outlined"/>
                            </p>
                            <p>
                                <span>{t('xxtut')}:</span>
                                <TextField value={xxtut} disabled onChange={(e) => setXxtut(e.target.value)} required
                                           id="outlined-basic" label={t('xxtut')} variant="outlined"/>
                            </p>
                        </div>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>3. {t('agg_data')}</h5>
                        </div>
                        <table className={'table table-bordered'}>
                            <thead>
                            <tr>
                                <th rowSpan={2}>№</th>
                                <th rowSpan={2}>{t('title_events')}</th>
                                <th>{t('in_quarter')}</th>
                                <th>{t('in_yearly')}</th>
                            </tr>
                            <tr>
                                <th className={'th-head'}>
                                    <th>{t('plan')}</th>
                                    <th>{t('practice')}</th>
                                    <th>{t('parts')}</th>
                                </th>
                                <th className={'th-head'}>
                                    <th>{t('plan')}</th>
                                    <th>{t('practice')}</th>
                                    <th>{t('parts')}</th>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>{t('theme_nights')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_theme_nights[0]}
                                               disabled onChange={(e) => setThemeNights([e.target.value, f_theme_nights[1], f_theme_nights[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_theme_nights[1]}
                                               disabled onChange={(e) => setThemeNights([f_theme_nights[0], e.target.value, f_theme_nights[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_theme_nights[2]}
                                               disabled onChange={(e) => setThemeNights([f_theme_nights[0], f_theme_nights[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_theme_nights[0]}
                                               disabled onChange={(e) => setlThemeNights([e.target.value, l_theme_nights[1], l_theme_nights[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_theme_nights[1]}
                                               disabled onChange={(e) => setlThemeNights([l_theme_nights[0], e.target.value, l_theme_nights[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_theme_nights[2]}
                                               disabled onChange={(e) => setlThemeNights([l_theme_nights[0], l_theme_nights[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>{t('interviews')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_interviews[0]}
                                               disabled onChange={(e) => setInterviews([e.target.value, f_interviews[1], f_interviews[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_interviews[1]}
                                               disabled onChange={(e) => setInterviews([f_interviews[0], e.target.value, f_interviews[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_interviews[2]}
                                               disabled onChange={(e) => setInterviews([f_interviews[0], f_interviews[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_interviews[0]}
                                               disabled onChange={(e) => setLInterviews([e.target.value, l_interviews[1], l_interviews[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_interviews[1]}
                                               disabled onChange={(e) => setLInterviews([l_interviews[0], e.target.value, l_interviews[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_interviews[2]}
                                               disabled onChange={(e) => setLInterviews([l_interviews[0], l_interviews[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>{t('meetings')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_meetings[0]}
                                               disabled onChange={(e) => setMeetings([e.target.value, f_meetings[1], f_meetings[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_meetings[1]}
                                               disabled onChange={(e) => setMeetings([f_meetings[0], e.target.value, f_meetings[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_meetings[2]}
                                               disabled onChange={(e) => setMeetings([f_meetings[0], f_meetings[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_meetings[0]}
                                               disabled onChange={(e) => setLMeetings([e.target.value, l_meetings[1], l_meetings[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_meetings[1]}
                                               disabled onChange={(e) => setLMeetings([l_meetings[0], e.target.value, l_meetings[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_meetings[2]}
                                               disabled onChange={(e) => setLMeetings([l_meetings[0], l_meetings[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>{t('selections')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_selections[0]}
                                               disabled onChange={(e) => setSelections([e.target.value, f_selections[1], f_selections[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_selections[1]}
                                               disabled onChange={(e) => setSelections([f_selections[0], e.target.value, f_selections[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_selections[2]}
                                               disabled onChange={(e) => setSelections([f_selections[0], f_selections[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_selections[0]}
                                               disabled onChange={(e) => setLSelections([e.target.value, l_selections[1], l_selections[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_selections[1]}
                                               disabled onChange={(e) => setLSelections([l_selections[0], e.target.value, l_selections[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_selections[2]}
                                               disabled onChange={(e) => setLSelections([l_selections[0], l_selections[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>{t('trips')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_trips[0]}
                                               disabled onChange={(e) => setTrips([e.target.value, f_trips[1], f_trips[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_trips[1]}
                                               disabled onChange={(e) => setTrips([f_trips[0], e.target.value, f_trips[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_trips[2]}
                                               disabled onChange={(e) => setTrips([f_trips[0], f_trips[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_trips[0]}
                                               disabled onChange={(e) => setLTrips([e.target.value, l_trips[1], l_trips[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_trips[1]}
                                               disabled onChange={(e) => setLTrips([l_trips[0], e.target.value, l_trips[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_trips[2]}
                                               disabled onChange={(e) => setLTrips([l_trips[0], l_trips[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>{t('concerts')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_concerts[0]}
                                               disabled onChange={(e) => setConcerts([e.target.value, f_concerts[1], f_concerts[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_concerts[1]}
                                               disabled onChange={(e) => setConcerts([f_concerts[0], e.target.value, f_concerts[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_concerts[2]}
                                               disabled onChange={(e) => setConcerts([f_concerts[0], f_concerts[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_concerts[0]}
                                               disabled onChange={(e) => setLConcerts([e.target.value, l_concerts[1], l_concerts[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_concerts[1]}
                                               disabled onChange={(e) => setLConcerts([l_concerts[0], e.target.value, l_concerts[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_concerts[2]}
                                               disabled onChange={(e) => setLConcerts([l_concerts[0], l_concerts[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>{t('other_concerts')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_other_concerts[0]}
                                               disabled onChange={(e) => setOtherConcerts([e.target.value, f_other_concerts[1], f_other_concerts[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_other_concerts[1]}
                                               disabled onChange={(e) => setOtherConcerts([f_other_concerts[0], e.target.value, f_other_concerts[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_other_concerts[2]}
                                               disabled onChange={(e) => setOtherConcerts([f_other_concerts[0], f_other_concerts[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_other_concerts[0]}
                                               disabled onChange={(e) => setLOtherConcerts([e.target.value, l_other_concerts[1], l_other_concerts[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_other_concerts[1]}
                                               disabled onChange={(e) => setLOtherConcerts([l_other_concerts[0], e.target.value, l_other_concerts[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_other_concerts[2]}
                                               disabled onChange={(e) => setLOtherConcerts([l_other_concerts[0], l_other_concerts[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>{t('festive_events')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_festive_events[0]}
                                               disabled onChange={(e) => setFestiveEvents([e.target.value, f_festive_events[1], f_festive_events[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_festive_events[1]}
                                               disabled onChange={(e) => setFestiveEvents([f_festive_events[0], e.target.value, f_festive_events[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_festive_events[2]}
                                               disabled onChange={(e) => setFestiveEvents([f_festive_events[0], f_festive_events[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_festive_events[0]}
                                               disabled onChange={(e) => setLFestiveEvents([e.target.value, l_festive_events[1], l_festive_events[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_festive_events[1]}
                                               disabled onChange={(e) => setLFestiveEvents([l_festive_events[0], e.target.value, l_festive_events[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_festive_events[2]}
                                               disabled onChange={(e) => setLFestiveEvents([l_festive_events[0], l_festive_events[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>{t('competitions')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_competitions[0]}
                                               disabled onChange={(e) => setCompetitions([e.target.value, f_competitions[1], f_competitions[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_competitions[1]}
                                               disabled onChange={(e) => setCompetitions([f_competitions[0], e.target.value, f_competitions[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_competitions[2]}
                                               disabled onChange={(e) => setCompetitions([f_competitions[0], f_competitions[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_competitions[0]}
                                               disabled onChange={(e) => setLCompetitions([e.target.value, l_competitions[1], l_competitions[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_competitions[1]}
                                               disabled onChange={(e) => setLCompetitions([l_competitions[0], e.target.value, l_competitions[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_competitions[2]}
                                               disabled onChange={(e) => setLCompetitions([l_competitions[0], l_competitions[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>{t('lectures')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_lectures[0]}
                                               disabled onChange={(e) => setFLectures([e.target.value, f_lectures[1], f_lectures[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_lectures[1]}
                                               disabled onChange={(e) => setFLectures([f_lectures[0], e.target.value, f_lectures[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_lectures[2]}
                                               disabled onChange={(e) => setFLectures([f_lectures[0], f_lectures[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_lectures[0]}
                                               disabled onChange={(e) => setLLectures([e.target.value, l_lectures[1], l_lectures[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_lectures[1]}
                                               disabled onChange={(e) => setLLectures([l_lectures[0], e.target.value, l_lectures[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_lectures[2]}
                                               disabled onChange={(e) => setLLectures([l_lectures[0], l_lectures[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>{t('literary_evenings')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_literary_evenings[0]}
                                               disabled onChange={(e) => setFLiteraryEvenings([e.target.value, f_literary_evenings[1], f_literary_evenings[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_literary_evenings[1]}
                                               disabled onChange={(e) => setFLiteraryEvenings([f_literary_evenings[0], e.target.value, f_literary_evenings[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_literary_evenings[2]}
                                               disabled onChange={(e) => setFLiteraryEvenings([f_literary_evenings[0], f_literary_evenings[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_literary_evenings[0]}
                                               disabled onChange={(e) => setLLiteraryEvenings([e.target.value, l_literary_evenings[1], l_literary_evenings[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_literary_evenings[1]}
                                               disabled onChange={(e) => setLLiteraryEvenings([l_literary_evenings[0], e.target.value, l_literary_evenings[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_literary_evenings[2]}
                                               disabled onChange={(e) => setLLiteraryEvenings([l_literary_evenings[0], l_literary_evenings[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>{t('reading_conference')}</td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_reading_conference[0]}
                                               disabled onChange={(e) => setFReadingConference([e.target.value, f_reading_conference[1], f_reading_conference[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_reading_conference[1]}
                                               disabled onChange={(e) => setFReadingConference([f_reading_conference[0], e.target.value, f_reading_conference[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={f_reading_conference[2]}
                                               disabled onChange={(e) => setFReadingConference([f_reading_conference[0], f_reading_conference[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_reading_conference[0]}
                                               disabled onChange={(e) => setLReadingConference([e.target.value, l_reading_conference[1], l_reading_conference[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_reading_conference[1]}
                                               disabled onChange={(e) => setLReadingConference([l_reading_conference[0], e.target.value, l_reading_conference[2]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_reading_conference[2]}
                                               disabled onChange={(e) => setLReadingConference([l_reading_conference[0], l_reading_conference[1], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>4. {t('circles')}</h5>
                        </div>
                        <table className={'table table-bordered'}>
                            <thead>
                            <tr>
                                <th>№</th>
                                <th>{t('type_insts')}</th>
                                <th>{t('number')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>{t('rec_centers')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                           onInput={F => F.target.setCustomValidity('')}  required min="0" value={rec_centers}
                                           disabled onChange={(e) => setRecCenters(e.target.value)}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>{t('special_libs')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                           onInput={F => F.target.setCustomValidity('')}  required min="0" value={special_libs}
                                           disabled onChange={(e) => setSpecialLibs(e.target.value)}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <td>{t('libs_users')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                           onInput={F => F.target.setCustomValidity('')}  required min="0" value={libs_users}
                                           disabled onChange={(e) => setLibsUsers(e.target.value)}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>{t('all_circles')} ({t('all')})</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                           onInput={F => F.target.setCustomValidity('')}  required min="0" value={all_circles}
                                           disabled
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <td>{t('circles_parts')} ({t('all')})</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                           onInput={F => F.target.setCustomValidity('')}  required min="0" value={circles_parts}
                                           disabled
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <th>{t('including')}</th>
                                <td/>
                            </tr>
                            <tr>
                                <td>{t('A')}</td>
                                <td>{t('art_circles')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                           onInput={F => F.target.setCustomValidity('')}  required min="0" value={art_circles}
                                           disabled onChange={(e) => setArtCircles(e.target.value)}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <td>{t('circles_parts')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                           onInput={F => F.target.setCustomValidity('')}  required min="0" value={art_parts}
                                           disabled onChange={(e) => setArtParts(e.target.value)}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('B')}</td>
                                <td>{t('sport_circles')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                           onInput={F => F.target.setCustomValidity('')}  required min="0" value={sport_circles}
                                           disabled onChange={(e) => setSportCircles(e.target.value)}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <td>{t('circles_parts')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                           onInput={F => F.target.setCustomValidity('')}  required min="0" value={sport_parts}
                                           disabled onChange={(e) => setSportParts(e.target.value)}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('V')}</td>
                                <td>{t('other_circles')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                           onInput={F => F.target.setCustomValidity('')}  required min="0" value={other_circles}
                                           disabled onChange={(e) => setotherCircles(e.target.value)}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <td>{t('circles_parts')}</td>
                                <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                           onInput={F => F.target.setCustomValidity('')}  required min="0" value={other_parts}
                                           disabled onChange={(e) => setOtherParts(e.target.value)}
                                           onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>5. {t('org_work')}</h5>
                        </div>
                        <table className={'table table-bordered org_work'}>
                            <thead>
                            <tr>
                                <th rowSpan={2}>№</th>
                                <th rowSpan={2}>{t('title_events')}</th>
                                <th>{t('in_quarter')}</th>
                                <th>{t('in_yearly')}</th>
                            </tr>
                            <tr>
                                <th className={'th-head-secondary'}>
                                    <th>{t('plan')}</th>
                                    <th>{t('practice')}</th>
                                </th>
                                <th className={'th-head-secondary'}>
                                    <th>{t('plan')}</th>
                                    <th>{t('practice')}</th>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>{t('uzkoj_plenum')}</td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={uzkoj_plenum[0]}
                                               disabled onChange={(e) => setUzkojPlenum([e.target.value, uzkoj_plenum[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={uzkoj_plenum[1]}
                                               disabled onChange={(e) => setUzkojPlenum([uzkoj_plenum[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_uzkoj_plenum[0]}
                                               disabled onChange={(e) => setLUzkojPlenum([e.target.value, l_uzkoj_plenum[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_uzkoj_plenum[1]}
                                               disabled onChange={(e) => setLUzkojPlenum([l_uzkoj_plenum[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>{t('regional_meets')}</td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={regional_meets[0]}
                                               disabled onChange={(e) => setRegionalMeets([e.target.value, regional_meets[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={regional_meets[1]}
                                               disabled onChange={(e) => setRegionalMeets([regional_meets[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_regional_meets[0]}
                                               disabled onChange={(e) => setLRegionalMeets([e.target.value, l_regional_meets[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_regional_meets[1]}
                                               disabled onChange={(e) => setLRegionalMeets([l_regional_meets[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>{t('society_meets')}</td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={society_meets[0]}
                                               disabled onChange={(e) => setSocietyMeets([e.target.value, society_meets[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={society_meets[1]}
                                               disabled onChange={(e) => setSocietyMeets([society_meets[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_society_meets[0]}
                                               disabled onChange={(e) => setLSocietyMeets([e.target.value, l_society_meets[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_society_meets[1]}
                                               disabled onChange={(e) => setLSocietyMeets([l_society_meets[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>{t('uzkoj_mbk')}</td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={uzkoj_mbk[0]}
                                               disabled onChange={(e) => setUzkojMBK([e.target.value, uzkoj_mbk[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={uzkoj_mbk[1]}
                                               disabled onChange={(e) => setUzkojMBK([uzkoj_mbk[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_uzkoj_mbk[0]}
                                               disabled onChange={(e) => setLUzkojMBK([e.target.value, l_uzkoj_mbk[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_uzkoj_mbk[1]}
                                               disabled onChange={(e) => setLUzkojMBK([l_uzkoj_mbk[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>{t('ntk_meets')}</td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={ntk_meets[0]}
                                               disabled onChange={(e) => setNtkMeets([e.target.value, ntk_meets[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={ntk_meets[1]}
                                               disabled onChange={(e) => setNtkMeets([ntk_meets[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_ntk_meets[0]}
                                               disabled onChange={(e) => setLNtkMeets([e.target.value, l_ntk_meets[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_ntk_meets[1]}
                                               disabled onChange={(e) => setLNtkMeets([l_ntk_meets[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>{t('ntk_s_meets')}</td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={ntk_s_meets[0]}
                                               disabled onChange={(e) => setNtkSMeets([e.target.value, ntk_s_meets[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={ntk_s_meets[1]}
                                               disabled onChange={(e) => setNtkSMeets([ntk_s_meets[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_ntk_s_meets[0]}
                                               disabled onChange={(e) => setLNtkSMeets([e.target.value, l_ntk_s_meets[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_ntk_s_meets[1]}
                                               disabled onChange={(e) => setLNtkSMeets([l_ntk_s_meets[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>{t('collect_fees')}</td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={collect_fees[0]}
                                               disabled onChange={(e) => setCollectFees([e.target.value, collect_fees[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={collect_fees[1]}
                                               disabled onChange={(e) => setCollectFees([collect_fees[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_collect_fees[0]}
                                               disabled onChange={(e) => setLCollectFees([e.target.value, l_collect_fees[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_collect_fees[1]}
                                               disabled onChange={(e) => setLCollectFees([l_collect_fees[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>{t('seminars')}</td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={seminars[0]}
                                               disabled onChange={(e) => setSeminars([e.target.value, seminars[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={seminars[1]}
                                               disabled onChange={(e) => setSeminars([seminars[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_seminars[0]}
                                               disabled onChange={(e) => setLSeminars([e.target.value, l_seminars[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_seminars[1]}
                                               disabled onChange={(e) => setLSeminars([l_seminars[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>6. {t('rehabilitation')}</h5>
                        </div>
                        <table className={'table table-bordered org_work'}>
                            <thead>
                            <tr>
                                <th rowSpan={2}>№</th>
                                <th rowSpan={2}>{t('title_events')}</th>
                                <th>{t('in_quarter')}</th>
                                <th>{t('in_yearly')}</th>
                            </tr>
                            <tr>
                                <th className={'th-head-secondary'}>
                                    <th>{t('plan')}</th>
                                    <th>{t('practice')}</th>
                                </th>
                                <th className={'th-head-secondary'}>
                                    <th>{t('plan')}</th>
                                    <th>{t('practice')}</th>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>{t('identification')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={identification[0]}
                                               disabled onChange={(e) => setIdentification([e.target.value, identification[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={identification[1]}
                                               disabled onChange={(e) => setIdentification([identification[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_identification[0]}
                                               disabled onChange={(e) => setLIdentification([e.target.value, l_identification[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_identification[1]}
                                               disabled onChange={(e) => setLIdentification([l_identification[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>{t('involve')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={involve[0]}
                                               disabled onChange={(e) => setInvolve([e.target.value, involve[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={involve[1]}
                                               disabled onChange={(e) => setInvolve([involve[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_involve[0]}
                                               disabled onChange={(e) => setLInvolve([e.target.value, l_involve[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_involve[1]}
                                               disabled onChange={(e) => setLInvolve([l_involve[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>{t('examination')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={examination[0]}
                                               disabled onChange={(e) => setExamination([e.target.value, examination[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={examination[1]}
                                               disabled onChange={(e) => setExamination([examination[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_examination[0]}
                                               disabled onChange={(e) => setLExamination([e.target.value, l_examination[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_examination[1]}
                                               disabled onChange={(e) => setLExamination([l_examination[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>{t('referrals')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={referrals[0]}
                                               disabled onChange={(e) => setReferrals([e.target.value, referrals[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={referrals[1]}
                                               disabled onChange={(e) => setReferrals([referrals[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_referrals[0]}
                                               disabled onChange={(e) => setLReferrals([e.target.value, l_referrals[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_referrals[1]}
                                               disabled onChange={(e) => setLReferrals([l_referrals[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>{t('enterprises')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={enterprises[0]}
                                               disabled onChange={(e) => setEnterprises([e.target.value, enterprises[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={enterprises[1]}
                                               disabled onChange={(e) => setEnterprises([enterprises[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_enterprises[0]}
                                               disabled onChange={(e) => setLEnterprises([e.target.value, l_enterprises[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_enterprises[1]}
                                               disabled onChange={(e) => setLEnterprises([l_enterprises[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>{t('o_enterprises')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={o_enterprises[0]}
                                               disabled onChange={(e) => setOEnterprises([e.target.value, o_enterprises[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={o_enterprises[1]}
                                               disabled onChange={(e) => setOEnterprises([o_enterprises[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={lo_enterprises[0]}
                                               disabled onChange={(e) => setLOEnterprises([e.target.value, lo_enterprises[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={lo_enterprises[1]}
                                               disabled onChange={(e) => setLOEnterprises([lo_enterprises[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>{t('provision')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               required min="0" value={provision[0]} disabled/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               required min="0" value={provision[1]} disabled/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               required min="0" disabled value={l_provision[0]}/>
                                    </td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               required min="0" value={l_provision[1]} disabled/>
                                    </td>
                                </td>
                            </tr>
                            {event_provisions ? event_provisions.map((item, index)=>
                                <tr key={index}>
                                    <td></td>
                                    <td>
                                        <input type="text" placeholder={t('provision_title')} onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')} required value={item.title}
                                               disabled onChange={(e) => changeTitle(index, e.target.value)}/>
                                    </td>
                                    <td className={'td-head-secondary'}>
                                        <td>
                                            <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                                   onInput={F => F.target.setCustomValidity('')}  required min="0" value={item.provision1}
                                                   disabled onChange={(e) => changeProvision1(index, e.target.value)}
                                                   onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                        </td>
                                        <td>
                                            <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                                   onInput={F => F.target.setCustomValidity('')}  required min="0" value={item.provision2}
                                                   disabled onChange={(e) => changeProvision2(index, e.target.value)}
                                                   onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                        </td>
                                    </td>
                                    <td className={'td-head-secondary'}>
                                        <td>
                                            <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                                   onInput={F => F.target.setCustomValidity('')} required min="0" value={item.l_provision1}
                                                   disabled onChange={(e) => changeLProvision1(index, e.target.value)}
                                                   onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                        </td>
                                        <td>
                                            <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                                   onInput={F => F.target.setCustomValidity('')} required min="0" value={item.l_provision2}
                                                   disabled onChange={(e) => changeLProvision2(index, e.target.value)}
                                                   onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                        </td>
                                    </td>
                                </tr>): ''}
                            <tr>
                                <td>8</td>
                                <td>{t('l_vision')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={lvision[0]}
                                               disabled onChange={(e) => setLvision([e.target.value, lvision[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={lvision[1]}
                                               disabled onChange={(e) => setLvision([lvision[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_lvision[0]}
                                               disabled onChange={(e) => setLLvision([e.target.value, l_lvision[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_lvision[1]}
                                               disabled onChange={(e) => setLLvision([l_lvision[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>{t('vocational')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={vocational[0]}
                                               disabled onChange={(e) => setVocational([e.target.value, vocational[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={vocational[1]}
                                               disabled onChange={(e) => setVocational([vocational[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_vocational[0]}
                                               disabled onChange={(e) => setLVocational([e.target.value, l_vocational[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_vocational[1]}
                                               disabled onChange={(e) => setLVocational([l_vocational[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>{t('b_literacy')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={bliteracy[0]}
                                               disabled onChange={(e) => setBliteracy([e.target.value, bliteracy[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={bliteracy[1]}
                                               disabled onChange={(e) => setBliteracy([bliteracy[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_bliteracy[0]}
                                               disabled onChange={(e) => setLBliteracy([e.target.value, l_bliteracy[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_bliteracy[1]}
                                               disabled onChange={(e) => setLBliteracy([l_bliteracy[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>{t('c_literacy')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={cliteracy[0]}
                                               disabled onChange={(e) => setCliteracy([e.target.value, cliteracy[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={cliteracy[1]}
                                               disabled onChange={(e) => setCliteracy([cliteracy[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_cliteracy[0]}
                                               disabled onChange={(e) => setLCliteracy([e.target.value, l_cliteracy[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_cliteracy[1]}
                                               disabled onChange={(e) => setLCliteracy([l_cliteracy[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>{t('number_volunteers')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={number_volunteers[0]}
                                               disabled onChange={(e) => setNumberVolunteers([e.target.value, number_volunteers[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={number_volunteers[1]}
                                               disabled onChange={(e) => setNumberVolunteers([number_volunteers[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_number_volunteers[0]}
                                               disabled onChange={(e) => setLNumberVolunteers([e.target.value, l_number_volunteers[1]])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/></td>
                                    <td>
                                        <input type="number" id="number" onInvalid={F => F.target.setCustomValidity(t('input_error'))}
                                               onInput={F => F.target.setCustomValidity('')}  required min="0" value={l_number_volunteers[1]}
                                               disabled onChange={(e) => setLNumberVolunteers([l_number_volunteers[0], e.target.value])}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}/>
                                    </td>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div className={'mt-4 d-block'}>
                            <h5 className={'d-block text-uppercase'}>7. {t('measures')}</h5>
                        </div>
                        <table className={'table table-bordered org_work'}>
                            <thead>
                            <tr>
                                <th rowSpan={2}>№</th>
                                <th rowSpan={2}>{t('title_events')}</th>
                                <th>{t('in_quarter')}</th>
                                <th>{t('in_yearly')}</th>
                            </tr>
                            <tr>
                                <th className={'th-head-secondary'}>
                                    <th>{t('plan')}</th>
                                    <th>{t('practice')}</th>
                                </th>
                                <th className={'th-head-secondary'}>
                                    <th>{t('plan')}</th>
                                    <th>{t('practice')}</th>
                                </th>
                            </tr>
                            </thead>
                            <tbody className={'last-info-tbody'}>
                            <tr>
                                <td>1</td>
                                <td style={{whiteSpace: 'pre-wrap'}}>{t('f_assistance')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="text" value={fassistance[0]}
                                               disabled onChange={(e) => setFassistance([e.target.value, fassistance[1]])}/>
                                    </td>
                                    <td>
                                        <input type="text" value={fassistance[1]}
                                               disabled onChange={(e) => setFassistance([fassistance[0], e.target.value])}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="text" value={l_fassistance[0]}
                                               disabled onChange={(e) => setLFassistance([e.target.value, l_fassistance[1]])}/>
                                    </td>
                                    <td>
                                        <input type="text" value={l_fassistance[1]}
                                               disabled onChange={(e) => setLFassistance([l_fassistance[0], e.target.value])}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td style={{whiteSpace: 'pre-wrap'}}>{t('p_support')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="text" value={p_support[0]}
                                               disabled onChange={(e) => setPsupport([e.target.value, p_support[1]])}/>
                                    </td>
                                    <td>
                                        <input type="text" value={p_support[1]}
                                               disabled onChange={(e) => setPsupport([p_support[0], e.target.value])}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="text" value={l_p_support[0]}
                                               disabled onChange={(e) => setLPsupport([e.target.value, l_p_support[1]])}/>
                                    </td>
                                    <td>
                                        <input type="text" value={l_p_support[1]}
                                               disabled onChange={(e) => setLPsupport([l_p_support[0], e.target.value])}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td style={{whiteSpace: 'pre-wrap'}}>{t('l_assistance')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="text" value={l_assistance[0]}
                                               disabled onChange={(e) => setLassistance([e.target.value, l_assistance[1]])}/>
                                    </td>
                                    <td>
                                        <input type="text" value={l_assistance[1]}
                                               disabled onChange={(e) => setLassistance([l_assistance[0], e.target.value])}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="text" value={l_l_assistance[0]}
                                               disabled onChange={(e) => setLLassistance([e.target.value, l_l_assistance[1]])}/>
                                    </td>
                                    <td>
                                        <input type="text" value={l_l_assistance[1]}
                                               disabled onChange={(e) => setLLassistance([l_l_assistance[0], e.target.value])}/>
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td style={{whiteSpace: 'pre-wrap'}}>{t('benefits')}</td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="text" value={benefits[0]}
                                               disabled onChange={(e) => setBenefits([e.target.value, benefits[1]])}/>
                                    </td>
                                    <td>
                                        <input type="text" value={benefits[1]}
                                               disabled onChange={(e) => setBenefits([benefits[0], e.target.value])}/>
                                    </td>
                                </td>
                                <td className={'td-head-secondary'}>
                                    <td><input type="text" value={l_benefits[0]}
                                               disabled onChange={(e) => setLBenefits([e.target.value, l_benefits[1]])}/>
                                    </td>
                                    <td>
                                        <input type="text" value={l_benefits[1]}
                                               disabled onChange={(e) => setLBenefits([l_benefits[0], e.target.value])}/>
                                    </td>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
                <Copyright/>
            </Box>
        </div>
    </div>
}

export default connect(props => props.events, {getAllVerifyResponse, getEvent})(ViewEvent)