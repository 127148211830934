import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'events',
    initialState: {events: [], message: false, events_single: false},
    reducers:{
        getAllEvent: (state,action)=>{
            state.events = action.payload
        },
        getOneEvent: (state,action)=>{
            state.events_single = action.payload
        },
        saveEvent: (state,action)=>{
            state.events.unshift(action.payload)
            state.message = true
        },
        editEvent: (state,action)=>{
            state.events_single = action.payload
            state.events = state.events.map(item=>item.id === action.payload.id ? action.payload : item)
            state.message = true
        },
        deleteToEvent: (state,action)=>{
            state.events = state.events.filter(item=>item.id !== action.payload.id)
            state.message = 'deleted'
        },
        messageReset: (state,action)=>{
            state.message = false
        },
        messageFail: (state,action)=>{
            state.message = 'error'
        }
    }
})

export const getEvents = () => apiCall({
    url: 'events/',
    method: 'GET',
    onSuccess: slice.actions.getAllEvent.type
})

export const eventSave = (data) => apiCall({
    url: 'events/',
    method: 'POST',
    onSuccess: slice.actions.saveEvent.type,
    onFail: slice.actions.messageFail.type,
    data,
})

export const getEvent = (id) => apiCall({
    url: 'events/'+id,
    method: 'GET',
    onSuccess: slice.actions.getOneEvent.type,
})

export const editSaveEvents = (data) => apiCall({
    url: 'events/'+data.id+'/',
    method: 'PUT',
    onSuccess: slice.actions.editEvent.type,
    onFail: slice.actions.messageFail.type,
    data,
})

export const deleteEvents = (data) => apiCall({
    url: 'events/'+data.id+'/',
    method: 'DELETE',
    onSuccess: slice.actions.deleteToEvent.type,
    onFail: slice.actions.messageFail.type,
})

export default slice.reducer
export const {messageReset, getAllEvent} = slice.actions
